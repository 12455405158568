import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function MyQuestionOnsiteRemote({ handleSelectedButtonOnsiteRemote, savedSelectedButton }) {

    const { t } = useTranslation();
    const [selectedButton, setSelectedButton] = useState(savedSelectedButton);

    const handleButtonClick = (value) => {
        setSelectedButton(value);
        handleSelectedButtonOnsiteRemote(value);
    };

    const buttonStyle = {
        width: '50%',
        height: '50px',
    };


    useEffect(() => {
        handleSelectedButtonOnsiteRemote(selectedButton);
    }, [selectedButton]);

    return (
        <Container style={{ marginBottom: '80px' }}>
            <Row className="mb-5">
                <Col>
                    <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.QuestionOnsiteRemote.title")} <span style={{ color: 'red' }}>*</span></h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'Remote' ? 'btn-primary active' : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('Remote')}
                    >
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.QuestionOnsiteRemote.remote")}
                    </button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'Onsite' ? 'btn-primary active' : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('Onsite')}
                    >
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.QuestionOnsiteRemote.onsite")}
                    </button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'It does not matter'
                            ? 'btn-primary active'
                            : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('It does not matter')}
                    >
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.QuestionOnsiteRemote.noPreference")}
                    </button>
                </Col>
            </Row>
        </Container>
    );
}

export default MyQuestionOnsiteRemote;