import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import { useTranslation } from 'react-i18next'


const Footer = () => {

    const { t } = useTranslation();

    return (
        <footer className="main-footer">
            <Container>
                <Row className="justify-content-between">
                    <Col md={10} className="footer-links">
                        <div className="footer-link">
                            <Link to="/AboutUs">
                                <p className="sub-footer">{t("footer.aboutUs")}</p>
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/WhoDoWeHelp">
                                <p className="sub-footer">{t("footer.whoWeHelp")}</p>
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/WhereWeProvideService">
                                <p className="sub-footer">{t("footer.countriesOfReach")}</p>
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/PageUnderConstruction">
                                <p className="sub-footer">{t("footer.faqs")}</p>
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/Terms/TermsOfUse">
                                <p className="sub-footer">{t("footer.termsOfService")}</p>
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/Terms/PrivacyNotice">
                                <p className="sub-footer">{t("footer.privacyNotice")}</p>
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/Terms/CookiesNotice">
                                <p className="sub-footer">{t("footer.cookiesNotice")}</p>
                            </Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/ContactUs">
                                <p className="sub-footer">{t("footer.contactUs")}</p>
                            </Link>
                        </div>
                    </Col>
                    <Col md={2} className="mt-3 d-flex justify-content-end align-items-center">
                        <Link to="https://www.linkedin.com/company/lexreach" target="_blank">
                            <img
                                style={{ width: '25px', height: '25px', marginRight: '10px' }}
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FLogos%2FLinkedin.png?alt=media&token=e8b9c4b3-1e93-460f-86d2-23fb5edb3639"
                                alt="Linkedin"
                            />
                        </Link>
                        <Link to="https://twitter.com/" target="_blank">
                            <img
                                style={{ width: '25px', height: '25px', marginRight: '10px' }}
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FLogos%2FTwitter.png?alt=media&token=7aeda474-f9df-47c7-8392-68fd5013f1ed"
                                alt="Twitter"
                            />
                        </Link>
                    </Col>
                </Row>

                <hr />
                <Row className="align-items-center">
                    <Col className="text-md-start mb-3">
                        <p className="sub-footer">&copy;{new Date().getFullYear()} {t("footer.rights")}</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
