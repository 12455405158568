import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Row, Form } from 'react-bootstrap';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import AppFirebase from '../../App.js';
import Select from 'react-select'; // Import the Select component from react-select
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

const ProvideAServiceInformation = (userProfile) => {

    const { t } = useTranslation();

    const [modalKey, setModalKey] = useState(0);

    const user = useContext(UserContext);

    const db = getFirestore(AppFirebase);

    const isLawyerOrParalegal = userProfile.userProfile === "Lawyer" || userProfile.userProfile === "Paralegal";
    

    // Static Data
    const [optionsYearsExp, setOptionsYearsExp] = useState([]);
    const [optionsBudget, setOptionsBudget] = useState([]);
    const [optionsOnSiteRemote, setOptionsOnSiteRemote] = useState([]);
    const [optionsWorkStart, setOptionsWorkStart] = useState([]);
    const [optionsWorkTime, setOptionsWorkTime] = useState([]);
    const [optionsWorkHoursWeek, setOptionsWorkHoursWeek] = useState([]);
    const [optionsIndustries, setOptionsIndustries] = useState([]);
    const [optionsPracticeAreas, setOptionsPracticeAreas] = useState([]);
    const [optionsNumEmployees, setOptionsNumEmployees] = useState([]);
    const [optionsHourlyRate, setOptionsHourlyRate] = useState([]);


    // Value Data
    const [workPlace, setWorkPlace] = useState("");
    const [industries, setIndustries] = useState([]);
    const [practiceAreas, setPracticeAreas] = useState([]);
    const [budget, setBudget] = useState("");
    const [experience, setExperience] = useState("");
    const [workStart, setWorkStart] = useState("");
    const [workDuration, setWorkDuration] = useState("");
    const [workHours, setWorkHours] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");

    const [companySize, setCompanySize] = useState("");
    const [hourlyRate, setHourlyRate] = useState("");
    const [provideAServiceApplication, setProvideAServiceApplication] = useState('');
    // Display Data
    const [workPlaceDisplay, setWorkPlaceDisplay] = useState("");
    const [industriesDisplay, setIndustriesDisplay] = useState("");
    const [practiceAreasDisplay, setPracticeAreasDisplay] = useState("");
    //const [budgetDisplay, setBudgetDisplay] = useState("");
    const [experienceDisplay, setExperienceDisplay] = useState("");
    const [workStartDisplay, setWorkStartDisplay] = useState("");
    const [workDurationDisplay, setWorkDurationDisplay] = useState("");
    const [workHoursDisplay, setWorkHoursDisplay] = useState("");
    const [additionalInfoDisplay, setAdditionalInfoDisplay] = useState("");
    const [companySizeDisplay, setCompanySizeDisplay] = useState("");
    const [hourlyRateDisplay, setHourlyRateDisplay] = useState("");

    const [showModal, setShowModal] = useState(false);


    const handleEditRequiredInfo = () => {
        if (provideAServiceApplication === false) {
            alert(t("pages.accountInformation.provideAServiceInformation.alerts.alert1"));
        } else {
            setShowModal(true);
        }
        
       
    };

    const handleCloseModal = () => {
        setShowModal(false);
        fetchRequiredInfo();
    };

    const handleSaveRequiredInfo = async () => {
        try {
            const userRef = doc(db, 'userInfo', user.uid);
            if (isLawyerOrParalegal) {
                const updates = {
                    workPlace: workPlace,
                    yearsExp: experience,
                    //minBudget: budget,
                    hourlyRate: hourlyRate,
                    industries: industries,
                    practiceAreas: practiceAreas,
                    workStart: workStart,
                    workDuration: workDuration,
                    maxHoursPerWeek: workHours,
                    additionalInfo: additionalInfo
                };

                await updateDoc(userRef, updates);
                handleCloseModal();
            } else {
                const updates = {
                    workPlace: workPlace,
                    companySize: companySize,
                    industries: industries,
                    practiceAreas: practiceAreas,
                    additionalInfo: additionalInfo
                };

                await updateDoc(userRef, updates);
                handleCloseModal();
            }

        } catch (error) {
            console.error('Error updating required information:', error);
        }
    };



    const modalRequiredInfo = () => {

        return (


            <Modal key={modalKey} show={showModal} onHide={handleCloseModal}>
                <Modal.Header style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeigh:'75px' }}>
                    <Modal.Title>{t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.title")}</Modal.Title>
                </Modal.Header>
                {isLawyerOrParalegal ? (
                    <Modal.Body>
                        <div>
                            <div className="mb-3">
                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.workPlace")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsOnSiteRemote}
                                            defaultValue={optionsOnSiteRemote.find(option => option.value === workPlace)}
                                            onChange={(selectedOption) => {
                                                setWorkPlace(selectedOption.value);
                                            }}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.yearsExperience")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsYearsExp}
                                            defaultValue={optionsYearsExp.find(option => option.value === experience)}
                                            onChange={(selectedOption) => setExperience(selectedOption.value)}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.hourlyRate")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsHourlyRate}
                                            defaultValue={optionsHourlyRate.find(option => option.value === hourlyRate)}
                                            onChange={(selectedOption) => setHourlyRate(selectedOption.value)}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.industries")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsIndustries}
                                            defaultValue={optionsIndustries.filter(option => industries.includes(option.value))}
                                            onChange={(selectedOption) => {
                                                let selectedValues = selectedOption.map(option => option.value);
                                                setIndustries(selectedValues)
                                            }}
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.practiceAreas")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsPracticeAreas}
                                            defaultValue={optionsPracticeAreas.filter(option => practiceAreas.includes(option.value))}
                                            onChange={(selectedOption) => {
                                                let selectedValues = selectedOption.map(option => option.value);
                                                setPracticeAreas(selectedValues);
                                            }}
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.workStart")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsWorkStart}
                                            defaultValue={optionsWorkStart.find(option => option.value === workStart)}
                                            onChange={(selectedOption) => setWorkStart(selectedOption.value)}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.workDuration")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsWorkTime}
                                            defaultValue={optionsWorkTime.find(option => option.value === workDuration)}
                                            onChange={(selectedOption) => setWorkDuration(selectedOption.value)}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        Hours per week :
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsWorkHoursWeek}
                                            defaultValue={optionsWorkHoursWeek.find(option => option.value === workHours)}
                                            onChange={(selectedOption) => setWorkHours(selectedOption.value)}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.additionalInformation")}
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={additionalInfo}
                                            onChange={(e) => { setAdditionalInfo(e.target.value); }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        <div>
                            <div className="mb-3">
                                <Row className="mb-3">
                                    <Col>
                                            {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.workPlace")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsOnSiteRemote}
                                            defaultValue={optionsOnSiteRemote.find(option => option.value === workPlace)}
                                            onChange={(selectedOption) => {
                                                setWorkPlace(selectedOption.value);
                                            }}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                            {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.companySize")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsNumEmployees}
                                            defaultValue={optionsNumEmployees.find(option => option.value === companySize)}
                                            onChange={(selectedOption) => setCompanySize(selectedOption.value)}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                            {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.industries")}
                                    </Col>
                                    {<Col>
                                        <Select
                                            options={optionsIndustries}
                                            defaultValue={optionsIndustries.filter(option => industries.includes(option.value))}
                                            onChange={(selectedOption) => {
                                                //console.log(selectedOption);
                                                let selectedValues = selectedOption.map(option => option.value);
                                                //console.log("This is my value", selectedValues)
                                                setIndustries(selectedValues)

                                            }}
                                            isMulti
                                        />
                                    </Col>}
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                            {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.practiceAreas")}
                                    </Col>
                                    <Col>
                                        <Select
                                            options={optionsPracticeAreas}
                                            defaultValue={optionsPracticeAreas.filter(option => practiceAreas.includes(option.value))}
                                            onChange={(selectedOption) => {
                                                let selectedValues = selectedOption.map(option => option.value);
                                                setPracticeAreas(selectedValues)
                                            }}
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                            {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.additionalInformation")}
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={additionalInfo}
                                            onChange={(e) => { setAdditionalInfo(e.target.value); }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.closeButton")}
                    </Button>
                    <Button variant="primary" onClick={handleSaveRequiredInfo}>
                        {t("pages.accountInformation.provideAServiceInformation.modalRequiredInfo.saveButton")}
                    </Button>
                </Modal.Footer>
            </Modal>


        );
    };


    function retrieveLabelByValue(dictionaryArray, value) {
        for (const dictionary of dictionaryArray) {
            if (dictionary.value === value) {
                return dictionary.label;
            }
        }
        return null; // Return null if value is not found
    };

    const fetchStaticData = async () => {
        try {
            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);
            
            setOptionsYearsExp(docSnap.data().optionsYearsExp);
            setOptionsBudget(docSnap.data().optionsBudget);
            setOptionsOnSiteRemote(docSnap.data().optionsOnSiteRemote);
            setOptionsWorkStart(docSnap.data().optionsWorkStart);
            setOptionsWorkTime(docSnap.data().optionsDuration.slice(0, -1));
            setOptionsWorkHoursWeek(docSnap.data().optionsWorkHoursWeek.slice(0, -1));
            setOptionsIndustries(docSnap.data().optionsIndustries);
            setOptionsPracticeAreas(docSnap.data().optionsPracticeAreas);
            setOptionsNumEmployees(docSnap.data().optionsNumEmployees);
            setOptionsHourlyRate(userProfile.userProfile === "Lawyer" ? docSnap.data().optionsHourlyRateLawyer : docSnap.data().optionsHourlyRateParalegal);

        } catch (error) {
            console.log(error.message);
        }

    };

    useEffect(() => {
        // Fetch static data when userProfile is available
        if (userProfile.userProfile) {
            fetchStaticData();
        }
    }, [userProfile.userProfile]); // Run the effect whenever userProfile.userProfile changes

    const fetchRequiredInfo = async () => {
        try {

            if (!user) {
                //console.log("User is null or undefined in ProvideAServiceInformation");
                return;
            }

            const userRef = doc(db, 'userInfo', user.uid);
            const userSnapshot = await getDoc(userRef);
            setProvideAServiceApplication(userSnapshot.data().provideAServiceApplication); 
           
            if (isLawyerOrParalegal) {
                setWorkPlace(userSnapshot.data().workPlace);
                setExperience(userSnapshot.data().yearsExp);
                setHourlyRate(userSnapshot.data().hourlyRate);
                setWorkStart(userSnapshot.data().workStart);
                setWorkDuration(userSnapshot.data().workDuration);
                setWorkHours(userSnapshot.data().maxHoursPerWeek);
                setIndustries(userSnapshot.data().industries);
                setPracticeAreas(userSnapshot.data().practiceAreas);
                setAdditionalInfo(userSnapshot.data().additionalInfo);

                setWorkPlaceDisplay(userSnapshot.data().workPlace);
                setExperienceDisplay(retrieveLabelByValue(optionsYearsExp, userSnapshot.data().yearsExp));
                setHourlyRateDisplay(retrieveLabelByValue(optionsHourlyRate, userSnapshot.data().hourlyRate));
                setWorkStartDisplay(retrieveLabelByValue(optionsWorkStart, userSnapshot.data().workStart));
                setWorkDurationDisplay(retrieveLabelByValue(optionsWorkTime, userSnapshot.data().workDuration));
                setWorkHoursDisplay(retrieveLabelByValue(optionsWorkHoursWeek, userSnapshot.data().maxHoursPerWeek));
                setIndustriesDisplay(userSnapshot.data().industries.join(', '));
                setPracticeAreasDisplay(userSnapshot.data().practiceAreas.join(', '));
                setAdditionalInfoDisplay(userSnapshot.data().additionalInfo);

            }
            else {
                setWorkPlace(userSnapshot.data().workPlace);
                setCompanySize(userSnapshot.data().companySize);
                //setHourlyRate(userSnapshot.data().hourlyRate);
                setWorkStart(userSnapshot.data().workStart);
                setWorkDuration(userSnapshot.data().workDuration);
                setWorkHours(userSnapshot.data().maxHoursPerWeek);

                setIndustries(userSnapshot.data().industries);

                setPracticeAreas(userSnapshot.data().practiceAreas);

                setAdditionalInfo(userSnapshot.data().additionalInfo);

                setWorkPlaceDisplay(userSnapshot.data().workPlace);
                setCompanySizeDisplay(retrieveLabelByValue(optionsNumEmployees, userSnapshot.data().companySize));
                setIndustriesDisplay(userSnapshot.data().industries.join(', '));
                setPracticeAreasDisplay(userSnapshot.data().practiceAreas.join(', '));
                setAdditionalInfoDisplay(userSnapshot.data().additionalInfo);
            }

        } catch (error) {
            console.error('Error fetching required information:', error);
        }
    };

    useEffect(() => {
        fetchStaticData();

        fetchRequiredInfo();

    }, [user]);

    useEffect(() => {
        fetchRequiredInfo();
        //console.log("test")
    }, [optionsYearsExp]);

    return user ? (
        <>

            <Card className="user-profile" style={{ padding: '0px', minHeight: '100%' }}>
                <Card.Header className="text-start h2" style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }} >

                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={10} className="mt-2 mb-2">
                            {t("pages.accountInformation.provideAServiceInformation.title")}
                        </Col>
                        <Col md={2} className=" d-flex align-items-center justify-content-center mt-2 mb-2">
                            <Button variant="outline-light" style={{ width: '100%' }} onClick={handleEditRequiredInfo}>{t("pages.accountInformation.provideAServiceInformation.editButton")}</Button>
                        </Col>
                    </Row>

                </Card.Header>

                <Card.Body className='text-start' style={{ height: '100%' }}>
                    <div >
                        <Card.Body>
                            {isLawyerOrParalegal ? (
                                <div>

                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.practiceAreas")}</Col>
                                        <Col>{workPlaceDisplay}</Col>
                                    </Row>


                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.yearsExperience")}</Col>
                                        <Col>{experienceDisplay}</Col>
                                    </Row>


                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.hourlyRate")}</Col>
                                        <Col>{hourlyRateDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.industries")}</Col>
                                        <Col>{industriesDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.practiceAreas")}</Col>
                                        <Col>{practiceAreasDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.workStart")}</Col>
                                        <Col>{workStartDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.workDuration")}</Col>
                                        <Col>{workDurationDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.hoursWeek")}</Col>
                                        <Col>{workHoursDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>{t("pages.accountInformation.provideAServiceInformation.additionalInformation")}</Col>
                                        <Col>{additionalInfoDisplay}</Col>
                                    </Row>

                                </div>
                            ) : (
                                <div>

                                    <Row className="mb-3">
                                            <Col>{t("pages.accountInformation.provideAServiceInformation.workPlace")}</Col>
                                        <Col>{workPlaceDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                            <Col>{t("pages.accountInformation.provideAServiceInformation.companySize")}</Col>
                                        <Col>{companySizeDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                            <Col>{t("pages.accountInformation.provideAServiceInformation.industries")}</Col>
                                        <Col>{industriesDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                            <Col>{t("pages.accountInformation.provideAServiceInformation.practiceAreas")}</Col>
                                        <Col>{practiceAreasDisplay}</Col>
                                    </Row>

                                    <Row className="mb-3">
                                            <Col>{t("pages.accountInformation.provideAServiceInformation.additionalInformation")}</Col>
                                        <Col>{additionalInfoDisplay}</Col>
                                    </Row>

                                </div>
                            )}
                        </Card.Body>

                    </div>

                </Card.Body>
            </Card>
            {modalRequiredInfo()}
        </>

    ) : (
        <Card className="d-flex align-items-center justify-content-center p-5">
            <Spinner animation="grow" variant="primary" size="lg" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Card.Text className="mt-3">Loading...</Card.Text>
        </Card>
    );
};

export default ProvideAServiceInformation;
