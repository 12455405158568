import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import AppFirebase from '../../App.js';
import { getFirestore, doc, getDoc } from "firebase/firestore";


function MyQuestionRemotePlaceOfWork({ handleSelectedRemotePlaceOfWork, savedOptions }) {

    const db = getFirestore(AppFirebase);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);

            setOptions(docSnap.data().optionsCountry);

        };

        fetchData();
    }, []);


    const animatedComponents = makeAnimated();
    const [checked, setChecked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(savedOptions);


    const handleChange = (selected) => {
        setSelectedOptions(selected);
        handleSelectedRemotePlaceOfWork(selected);
    };

    const handleCheck = (value) => {
        setChecked(value.currentTarget.checked);
    };

    useEffect(() => {
        if ((selectedOptions.length === options.length) && (selectedOptions.length!==0)){
            setChecked(true);
            //console.log("it is being set to true");
            //console.log(selectedOptions);
        }
        handleSelectedRemotePlaceOfWork(selectedOptions);
    }, [selectedOptions]);

    
    useEffect(() => {

        if (checked) {
            setSelectedOptions(options);
        }
    }, [checked]);
    
    return (
        <Container style={{marginBottom:"100px"} }>
            <Row className="mb-5">
                <Col>
                    <h1>From which countries can the remote legal support be provided?</h1>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col></Col>
                <Col sm={10}>
                    <Select
                        closeMenuOnSelect={!checked}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        onChange={handleChange}
                        value={selectedOptions}
                        isDisabled={checked}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                minHeight: '75px', // Adjust the height as needed
                            }),
                        }}
                    />
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col sm={4}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Any Country"
                        checked={checked}
                        onChange={handleCheck}
                    />
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
}

export default MyQuestionRemotePlaceOfWork;
