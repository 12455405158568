import ChatBot from 'react-chatbotify';
import { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";
import AppFirebase from '../FirebaseConfig.js';
const Chatbot = () => {

	const functions = getFunctions();
	const sendTrackingMail = httpsCallable(functions, 'sendTrackingMail');
	let docData = {};
	const db = getFirestore(AppFirebase);

	const [form, setForm] = useState({});

	const settings = {
		header: {
			//avatar: "https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2F81011.jpg?alt=media&token=b2faf0c6-c6f2-45ca-9720-510a1a2eeae4",
			title: "Lexreach Support",
			showAvatar: false
		}
	}
	const formStyle = {
		marginTop: 10,
		marginLeft: 20,
		border: "1px solid #491d8d",
		padding: 10,
		borderRadius: 5,
		maxWidth: 300
	};

	const styles = {
		// Header
		headerStyle: {
			background: '#35cce6',
			fontSize: "20px",  // Increase the font size
			fontWeight: "bold", // Make the text bold
			textAlign: "center" // Center the text
		},
		// Message bubbles
		botBubbleStyle: {
			fontSize: "10",
			backgroundColor: "#35cce6"
		},
		// Chat window
		chatWindowStyle: {
			backgroundColor: '#EBF1F5',
		},
		//
		sendButtonStyle: {
			backgroundColor: "#35cce6"
		},
		// Button on the app.js see on home screen
		chatButtonStyle: {
			background: `#35cce6 url("https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FLogos%2FSend.png?alt=media&token=d5afa702-076f-49b6-b942-eb92a28b2daf") no-repeat center center`,
			backgroundSize: 'contain', // Make sure the image fits within the button
			borderRadius: '50%', // If you want a round button
			border: 'none', // Remove default border
			cursor: 'pointer', // Cursor change on hover
			width: "55px",
			height: "55px"
		},
		tooltipStyle: {
			display: "none"
		},
		footerStyle: {
			display:"none"
		},
	};

	// Function to validate email
	const validateEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};


	const flow = {
		start: {
			message: "Hi, how can I assist you today?",
			options: ["Get legal support", "Provide legal support", "Other"],
			chatDisabled: true,
			function: (params) => setForm({ ...form, helpType: params.userInput }),
			path: (params) => {
				switch (params.userInput) {
					case "Get legal support":
						return "get_legal_support_path1";
					case "Provide legal support":
						return "provide_legal_support_path";
					case "Other":
						return "other_path";
					default:
						return "default_path";
				}
			}
		},

		get_legal_support_path1: {
			message: "Are you representing yourself individually, or are you affiliated with a startup or corporation?",
			options: ["Individual", "Startup", "Corporation"],
			chatDisabled: true,
			function: (params) => setForm({ ...form, customerType: params.userInput }),
			path: "get_legal_support_path2"
		},
		get_legal_support_path2: {
			message: "Fantastic! How can we assist you legally? Are you seeking representation from a law firm, a lawyer, or support from a paralegal?",
			options: ["Law Firm", "Lawyer", "Paralegal"],
			chatDisabled: true,
			function: (params) => setForm({ ...form, legalHelpType: params.userInput }),
			path: "get_legal_support_path3"
		},
		get_legal_support_path3: {
			message: "Would you like to receive legal assistance on-site or remotely?",
			options: ["Remote", "On-Site", "No Preference"],
			chatDisabled: true,
			function: (params) => setForm({ ...form, workPlace: params.userInput }),
			path: "get_legal_support_path4"
		},
		get_legal_support_path4: {

			message: "Please provide your email so we can contact you as soon as possible.",
			function: async (params) => {
					const email = params.userInput;

					// Validate the email address
					if (!validateEmail(email)) {
						await params.injectMessage("Please enter a valid email address.");
						params.goToPath("get_legal_support_path4")

					} else {
						// Update form with email
						setForm({ ...form, email: email });

						// Save the data to Firestore
						const contactRef = doc(db, 'contactMessages', "contact_chatbot_" + email);
						const docData = {
							mail: email,
							form: form
						};
						await setDoc(contactRef, docData, { merge: true });

						// Send tracking email
						await sendTrackingMail({ mail: email, form: form });
						params.goToPath("get_legal_support_path5")
					}
			},
		},
		get_legal_support_path5: {
			message: "Thank you! Your information has been passed to a Lexreach representative, who will contact you soon. In the meantime, feel free to explore our 'Secure Legal Support' page.",
			options: ["Go to page!", "Not now..."],
			chatDisabled: true,
			function: (params) => {
				if (params.userInput === "Go to page!") {
					window.location.href = "/FindALawyer"; // Navigate to the specific page
				} else {
					return "get_legal_support_path6"; // Continue to the next step
				}
			},
			path: "get_legal_support_path6"
		},
		get_legal_support_path6: {
			message: "Thank you for your interest. We will get back to you shortly!",
			component: (
				<div style={formStyle}>
					<p>Searching for: {form.helpType}</p>
					<p>Customer Type: {form.customerType}</p>
					<p>Legal Help Needed: {form.legalHelpType}</p>
					<p>Preferred Work Location: {form.workPlace}</p>
					<p>Email: {form.email}</p>
				</div>
			),
			options: ["New Application"],
			chatDisabled: true,
			path: "start"
		},

		provide_legal_support_path: {
			message: "Are you practicing law independently as an individual lawyer/paralegal, or are you affiliated with a law firm?",
			options: ["I am an independent lawyer/paralegal", "I'm affiliated with a law firm"],
			chatDisabled: true,
			function: (params) => setForm({ ...form, affiliation: params.userInput }),
			path: "provide_legal_support_path1"
		},
		provide_legal_support_path1: {
			message: "Please provide your email so we can contact you as soon as possible.",
			function: async (params) => {
				
				const email = params.userInput;
				if (!validateEmail(email)) {
					params.injectMessage("Please enter a valid email address.");
					params.goToPath("provide_legal_support_path1")
				}
				else { 
					setForm({ ...form, email });
					const contactRef = doc(db, 'contactMessages', "contact_chatbot_" + params.userInput);
					docData["mail"] = params.userInput;
					docData["form"] = form;
					await setDoc(contactRef, docData, { merge: true });

					await sendTrackingMail({ mail: params.userInput, form: form });
					params.goToPath("provide_legal_support_path2")
				}
			},
		},
		provide_legal_support_path2: {
			message: "Thank you! Your information has been passed to a Lexreach representative, who will contact you soon. In the meantime, feel free to explore our 'Provide Legal Service' page.",
			options: ["Go to page!", "Not now..."],
			chatDisabled: true,
			function: (params) => {
				if (params.userInput === "Go to page!") {
					window.location.href = "/ProvideAService"; // Navigate to the specific page
				} else {
					return "provide_legal_support_path3"; // Continue to the next step
					
				}
			},
		},
		provide_legal_support_path3: {
			message: "Thank you for your interest. We will get back to you shortly!",
			component: (
				<div style={formStyle}>
					<p>Searching for: {form.helpType}</p>
					<p>Affiliation: {form.affiliation}</p>
					<p>Email: {form.email}</p>
				</div>
			),
			options: ["New Application"],
			chatDisabled: true,
			path: "start"
		},
		other_path: {
			message: "Hmm... Can you please provide your email so we can contact you as soon as possible ?",
			function: async (params) => {
				const email = params.userInput;

				// Validate the email address
				if (!validateEmail(email)) {
					await params.injectMessage("Please enter a valid email address.");
					params.goToPath("other_path")

				} else {
					// Update form with email
					setForm({ ...form, email: email });

					params.goToPath("other_path1")
				}
			},
			other_path1: {
				message: "Thank you. Can you now please explain what are you searching for ?",
				function: async (params) => {

					const explanation = params.userInput;
					setForm({ ...form, explanation });
					const contactRef = doc(db, 'contactMessages', "contact_chatbot_" + params.userInput);
					docData["mail"] = params.userInput;
					docData["form"] = form;
					await setDoc(contactRef, docData, { merge: true });

					await sendTrackingMail({ mail: params.userInput, form: form });
					params.goToPath("other_path2")
				}
			},
			other_path2: {
				message: "Thank you! Your information has been passed to a Lexreach representative, who will contact you soon. In the meantime, feel free to explore our 'About Us' page.",
				options: ["Go to page!", "Not now..."],
				chatDisabled: true,
				function: (params) => {
					if (params.userInput === "Go to page!") {
						window.location.href = "/AboutUs"; // Navigate to the specific page
					} else {
						return "other_path3"; // Continue to the next step

					}
				}
			},
			other_path3: {
				message: "Thank you for your interest. We will get back to you shortly!",
				component: (
					<div style={formStyle}>
						<p>Searching for: {form.helpType}</p>
						<p>Explanation : {form.explanation}</p>
						<p>Email: {form.email}</p>
					</div>
				),
				options: ["New Application"],
				chatDisabled: true,
				path: "start"
			},
		}
	};

	return (
		<ChatBot styles={styles} settings={settings} flow={flow} />
	);
};

export default Chatbot;
