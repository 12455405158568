import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Modal, Button, Spinner } from 'react-bootstrap';
import MyQuestionProfile from './QuestionProfile.js';
import MyNotFoundPage from '../../Error/NotFoundPage/NotFound.js';
import FormLawyer from './QuestionsLawyer.js';
import { useNavigate } from "react-router-dom";
import FormLawFirm from './QuestionsLawFirm.js';
import AppFirebase from '../../App.js';
import { getFirestore, doc, setDoc, getDoc, serverTimestamp, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { UserContext } from '../../App';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation } from 'react-i18next';

const MyProvideAServiceApply = () => {

    const { t } = useTranslation();


    const functions = getFunctions();
    const sendPASASMail = httpsCallable(functions, 'sendPASASMail');

    const [firstApplicationDone, setFirstApplicationDone] = useState(false);
    const fetchFirstApplicationDone = async () => {
        const userDocRef = doc(db, "userInfo", user.uid);

        try {
            // console.log("fetchfirst started")
            const docSnapshot = await getDoc(userDocRef);

            if (docSnapshot.exists() && docSnapshot.data().firstApplicationDone !== undefined) {
                //console.log("if statement  started")
                setFirstApplicationDone(docSnapshot.data().firstApplicationDone);
            }
            //console.log("fetchfirst endend", firstApplicationDone )
        } catch (error) {
            console.error("Error fetching firstApplicationDone:", error);
        }
    };



    const user = useContext(UserContext);
    const db = getFirestore(AppFirebase);
    const storage = getStorage();

    let location = useLocation();

    const [show, setShow] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(location.state ? location.state.key : '');

    const [personalInformationObject, setPersonalInformationObject] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        billingEmailAddress: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [requiredInformationObject, setRequiredInformationObject] = useState({
        workPlace: '',
        yearsExp: "",
        minBudget: "",
        hourlyRate: "",
        industries: [],
        practiceAreas: [],
        workStart: "",
        workDuration: "",
        maxHoursPerWeek: "",
        additionalInfo: ''
    });

    const [personalInformationObjectIni, setPersonalInformationObjectIni] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        billingEmailAddress: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [requiredInformationObjectIni, setRequiredInformationObjectIni] = useState({
        workPlace: '',
        yearsExp: "",
        minBudget: "",
        hourlyRate: "",
        industries: [],
        practiceAreas: [],
        workStart: "",
        workDuration: "",
        maxHoursPerWeek: "",
        additionalInfo: ''
    });

    const [resumeWithCredentials, setResumeWithCredentials] = useState(null);
    const [copyLawLicense, setCopyLawLicense] = useState(null);
    const [copyBarAdmission, setCopyBarAdmission] = useState(null);

    const [resumeWithCredentialsMetadata, setResumeWithCredentialsMetadata] = useState(null);
    const [copyLawLicenseMetadata, setcopyLawLicenseMetadata] = useState(null);
    const [copyBarAdmissionMetadata, setCopyBarAdmissionMetadata] = useState(null);

    const [incorporationCertificateMetadata, setIncorporationCertificateMetadata] = useState(null);
    const [vatCertificateMetadata, setvatCertificateMetadata] = useState(null);
    const [capabilityStatementMetadata, setCapabilityStatementMetadata] = useState(null);

    const [lawFirmInformationObject, setLawFirmInformationObject] = useState({
        lastName: '',
        emailAddress: '',
        billingEmailAddress: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [lawFirmRequiredInformationObject, setLawFirmRequiredInformationObject] = useState({
        workPlace: '',
        companySize: 0,
        industries: [],
        practiceAreas: [],
        minBudget: 0,
        hourlyRate: 0,
        additionalInfo: '',
        workStart: 0,
        workDuration: 0,
        maxHoursPerWeek: 0,
    });

    const [lawFirmInformationObjectIni, setLawFirmInformationObjectIni] = useState({
        lastName: '',
        emailAddress: '',
        billingEmailAddress: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [lawFirmRequiredInformationObjectIni, setLawFirmRequiredInformationObjectIni] = useState({
        workPlace: '',
        companySize: 0,
        industries: [],
        practiceAreas: [],
        hourlyRate: 0,
        additionalInfo: '',
        minBudget: 0,
        workStart: 0,
        workDuration: 0,
        maxHoursPerWeek: 0,

    });

    const [incorporationCertificate, setIncorporationCertificate] = useState(null);
    const [vatCertificate, setVatCertificate] = useState(null);
    const [capabilityStatement, setCapabilityStatement] = useState(null);

    const [skipped, setSkipped] = useState(new Set());
    const [activeStep, setActiveStep] = useState(selectedProfile === 'Lawyer' ? 0 : 1);

    const navigate = useNavigate();

    useEffect(() => { fetchFirstApplicationDone() }, [])

    useEffect(() => {
        // Update the state values when certain dependencies change
        handleSelectedProfile(selectedProfile);


        handlePersonalInformationObject(personalInformationObject);
        handleRequiredInformationObject(requiredInformationObject);
        handleResumeWithCredentials(resumeWithCredentials);
        handleResumeWithCredentialsMetadata(resumeWithCredentialsMetadata);
        handleCopyLawLicense(copyLawLicense);
        handleCopyLawLicenseMetadata(copyLawLicenseMetadata);
        handleCopyBarAdmission(copyBarAdmission);
        handleCopyBarAdmissionMetadata(copyBarAdmissionMetadata);

        handleLawFirmInformationObject(lawFirmInformationObject);
        handleLawFirmRequiredInformationObject(lawFirmRequiredInformationObject);
        handleIncorporationCertificate(incorporationCertificate);
        handleIncorporationCertificateMetadata(incorporationCertificateMetadata);
        handleVatCertificate(vatCertificate);
        handleVatCertificateMetadata(vatCertificateMetadata);
        handleCapabilityStatement(capabilityStatement);
        handleCapabilityStatementMetadata(capabilityStatementMetadata);

    }, [selectedProfile, personalInformationObject, requiredInformationObject, resumeWithCredentials, resumeWithCredentialsMetadata, copyLawLicense, copyLawLicenseMetadata, copyBarAdmission, copyBarAdmissionMetadata, lawFirmInformationObject, lawFirmRequiredInformationObject, incorporationCertificate, incorporationCertificateMetadata, vatCertificate, vatCertificateMetadata, capabilityStatement, capabilityStatementMetadata]);

    const handleSelectedProfile = (value) => {
        setSelectedProfile(value);
    };

    const handlePersonalInformationObject = (value) => {
        setPersonalInformationObject(value);
    }

    const handleRequiredInformationObject = (value) => {
        setRequiredInformationObject(value);
    }

    const handleResumeWithCredentials = (value) => {
        setResumeWithCredentials(value);
    }

    const handleCopyLawLicense = (value) => {
        setCopyLawLicense(value);
        //console.log(copyLawLicense);
    }

    const handleCopyBarAdmission = (value) => {
        setCopyBarAdmission(value);
        //console.log(copyBarAdmission);
    }

    const handleResumeWithCredentialsMetadata = (value) => {
        setResumeWithCredentialsMetadata(value);
    }

    const handleCopyLawLicenseMetadata = (value) => {
        setcopyLawLicenseMetadata(value);
        //console.log(copyLawLicense);
    }

    const handleCopyBarAdmissionMetadata = (value) => {
        setCopyBarAdmissionMetadata(value);
        //console.log(copyBarAdmission);
    }

    const listQuestionsLawyer = [
        { name: 'QuestionProfile', progressValue: 0 },
        { name: 'FormLawyer', progressValue: 0 }
    ];

    const listQuestionsLawFirm = [
        { name: 'QuestionProfile', progressValue: 0 },
        { name: 'FormLawFirm', progressValue: 0 }
    ];

    const handleLawFirmInformationObject = (value) => {
        setLawFirmInformationObject(value);
        //console.log(lawFirmInformationObject);
    };

    const handleLawFirmRequiredInformationObject = (value) => {
        setLawFirmRequiredInformationObject(value);
        //console.log(lawFirmRequiredInformationObject);
    };

    const handleIncorporationCertificate = (value) => {
        setIncorporationCertificate(value);
        //console.log(incorporationCertificate);
    };

    const handleVatCertificate = (value) => {
        setVatCertificate(value);
        //console.log(vatCertificate);
    };

    const handleVatCertificateMetadata = (value) => {
        setvatCertificateMetadata(value);
        //console.log(vatCertificate);
    };

    const handleCapabilityStatement = (value) => {
        setCapabilityStatement(value);
    }

    const handleCapabilityStatementMetadata = (value) => {
        setCapabilityStatementMetadata(value);
    }

    const handleIncorporationCertificateMetadata = (value) => {
        setIncorporationCertificateMetadata(value);
        //console.log(incorporationCertificate);
    };


    const [validated, setValidated] = useState(false);

    const handleValidatedFormLawFirm = (value) => {
        setValidated(value)
    }


    const handleClose = () => {
        setShow(false);
        setShowValidation(false);
    };
    const handleShow = () => setShow(true);

    const handleConfirm = () => {
        setShow(false);
        navigate("/ProvideAService");
    };




    const isNextButtonDisabled = () => {
        switch (activeStep) {
            case 0:
                return selectedProfile === '';
            default:
                return false;
        }
    };

    //To render different components
    const renderSwitch = (param) => {
        switch (param) {
            case 'QuestionProfile':
                return < MyQuestionProfile handleSelectedProfile={handleSelectedProfile} />;
            case 'FormLawFirm':
                return <FormLawFirm
                    handleLawFirmInformationObject={handleLawFirmInformationObject}
                    handleLawFirmRequiredInformationObject={handleLawFirmRequiredInformationObject}
                    handleIncorporationCertificate={handleIncorporationCertificate}
                    handleVatCertificate={handleVatCertificate}
                    handleCapabilityStatement={handleCapabilityStatement}
                    handleCapabilityStatementMetadata={handleCapabilityStatementMetadata}
                    handleIncorporationCertificateMetadata={handleIncorporationCertificateMetadata}
                    handleVatCertificateMetadata={handleVatCertificateMetadata}
                />;
            case 'FormLawyer':
                return <FormLawyer
                    selectedProfile={selectedProfile}
                    handlePersonalInformationObject={handlePersonalInformationObject}
                    handleRequiredInformationObject={handleRequiredInformationObject}
                    handleResumeWithCredentials={handleResumeWithCredentials}
                    handleCopyLawLicense={handleCopyLawLicense}
                    handleCopyBarAdmission={handleCopyBarAdmission}
                    handleResumeWithCredentialsMetadata={handleResumeWithCredentialsMetadata}
                    handleCopyLawLicenseMetadata={handleCopyLawLicenseMetadata}
                    handleCopyBarAdmissionMetadata={handleCopyBarAdmissionMetadata}
                />;
            default:
                return <MyNotFoundPage />;
        }
    };

    //Checks if the step is optional or nor
    const isStepOptional = (step) => {
        return step === 1;
    };

    //Checks if step is contained within the set() skipped
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    //To next
    const handleNext = () => {
        if (activeStep < 1) {
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        } else {
            setShowValidation(true);
        }

    };

    // Validation function to check if any field is empty
    // Function to check if an object is in its initial state
    const isObjectInInitialState = (currentObject, initialObject) => {
        if (selectedProfile === "Lawyer" || selectedProfile === "Paralegal") {
            for (const key in initialObject) {
                // Skip certain keys

                if (
                    key === "state" ||
                    key === "zip" ||
                    key === "billingEmailAddress" ||
                    key === "addressLine2" ||
                    key === "phoneNumber" ||
                    key === "additionalInfo" ||
                    key === "hourlyRate" ||
                    key === "minBudget"
                ) {
                    continue;
                }

                if (
                    Array.isArray(currentObject[key]) &&
                    Array.isArray(initialObject[key])
                ) {
                    // Check if the array lengths are both 0
                    if (currentObject[key].length === 0) {
                        console.log("Array : ", key)
                        return true; // Array length is different from the initial state
                    }
                }

                if (currentObject[key] === initialObject[key]) {
                    console.log("fireld : ", key)
                    return true; // Property is different from the initial state
                }
            }
        } else {
            for (const key in initialObject) {
                if (

                    key === "state" ||
                    key === "zip" ||
                    key === "phoneNumber" ||
                    key === "billingEmailAddress" ||
                    key === "addressLine2" ||
                    key === "additionalInfo" ||
                    key === "hourlyRate" ||
                    key === "minBudget" ||
                    key === "workStart" ||
                    key === "workDuration" ||
                    key === "maxHoursPerWeek"

                ) {
                    continue;
                }

                if (
                    Array.isArray(currentObject[key]) &&
                    Array.isArray(initialObject[key])
                ) {
                    // Check if the array lengths are both 0
                    if (currentObject[key].length === 0) {

                        return true; // Array length is different from the initial state
                    }
                }

                if (currentObject[key] === initialObject[key]) {
                    console.log(key)
                    return true;
                }
            }
        }
        return false;
    };


    const [loading, setLoading] = useState(false);

    const handleSend = async () => {

        try {
            setLoading(true);

            if (selectedProfile === "Lawyer" || selectedProfile === "Paralegal") {
                console.log(personalInformationObject)
                console.log(personalInformationObjectIni)
                console.log(isObjectInInitialState(personalInformationObject, personalInformationObjectIni))

                console.log(requiredInformationObject)
                console.log(requiredInformationObjectIni)
                console.log(isObjectInInitialState(requiredInformationObject, requiredInformationObjectIni))

                if (isObjectInInitialState(personalInformationObject, personalInformationObjectIni) || isObjectInInitialState(requiredInformationObject, requiredInformationObjectIni) || resumeWithCredentials === null || copyLawLicense === null || copyBarAdmission === null) {
                    setShowValidation(false);
                    setLoading(false);
                    alert(t("pages.provideAServiceLoggedIn.provideAServiceApply.alerts.alert1"))
                }
                else {

                    const docData = {
                        //personal Information
                        profileLawyer: selectedProfile,
                        emailAddress: personalInformationObject.emailAddress,
                        billingEmailAddress: personalInformationObject.billingEmailAddress,
                        firstName: personalInformationObject.firstName,
                        lastName: personalInformationObject.lastName,
                        addressLine1: personalInformationObject.addressLine1,
                        addressLine2: personalInformationObject.addressLine2,
                        zip: personalInformationObject.zip,
                        state: personalInformationObject.state,
                        city: personalInformationObject.city,
                        country: personalInformationObject.country,
                        phoneNumber: personalInformationObject.phoneNumber,
                        //Required Information
                        workPlace: requiredInformationObject.workPlace,
                        industries: requiredInformationObject.industries,
                        practiceAreas: requiredInformationObject.practiceAreas,
                        yearsExp: requiredInformationObject.yearsExp,
                        minBudget: requiredInformationObject.minBudget,
                        hourlyRate: requiredInformationObject.hourlyRate,
                        maxHoursPerWeek: requiredInformationObject.maxHoursPerWeek,
                        workStart: requiredInformationObject.workStart,
                        workDuration: requiredInformationObject.workDuration,
                        additionalInfo: requiredInformationObject.additionalInfo,
                        //Finsihed
                        provideAServiceApplication: false
                    }
                    //console.log(docData);
                    const refResume = ref(storage, `users/${user.uid}/resumeWithCredentials`);
                    const refLawLicense = ref(storage, `users/${user.uid}/lawLicense`);
                    const refBarAdmission = ref(storage, `users/${user.uid}/barAdmission`);

                    if (resumeWithCredentials !== null) {
                        uploadBytes(refResume, resumeWithCredentials, resumeWithCredentialsMetadata).then((snapshotResume) => {
                            // console.log('Uploaded the resume file!');
                        });
                    }

                    if (!firstApplicationDone) {
                        //console.log("if statement started in handleSend law firm")
                        docData.firstApplicationDone = true;
                        //console.log("if statement endend in handleSend law firm")
                    }

                    if (copyLawLicense !== null) {
                        uploadBytes(refLawLicense, copyLawLicense, copyLawLicenseMetadata).then((snapshotLawLicense) => {
                            // console.log('Uploaded a blob or file!');
                        });
                    }
                    if (copyBarAdmission !== null) {
                        uploadBytes(refBarAdmission, copyBarAdmission, copyBarAdmissionMetadata).then((snapshotBarAdission) => {
                            // console.log('Uploaded a blob or file!');
                        });
                    }

                    await setDoc(doc(db, "userInfo", user.uid), docData);

                    const docDataCreation = {
                        queryCreationDate: serverTimestamp(),
                        finished: false
                    }
                    const appRef = doc(collection(db, "userInfo", user.uid, "provideAServiceQuery"));
                    //console.log("This is my pathRef : ", appRef)
                    await setDoc(appRef, docDataCreation);
                    //const appRef = await addDoc(collection(db, "userInfo", user.uid, "provideAServiceQuery"), docDataCreation);


                    const result = await sendPASASMail({ appId: appRef.id });
                    //console.log("Mail is sent", result);
                    setShowValidation(false);
                    setLoading(false);

                    navigate("/ProvideAServiceApply/Confirmation");
                }
            }

            else if (selectedProfile === "Law Firm") {

                if (isObjectInInitialState(lawFirmInformationObject, lawFirmInformationObjectIni) || isObjectInInitialState(lawFirmRequiredInformationObject, lawFirmRequiredInformationObjectIni) || incorporationCertificate === null || vatCertificate === null || capabilityStatement === null) {
                    setShowValidation(false);
                    setLoading(false);
                    alert(t("pages.provideAServiceLoggedIn.provideAServiceApply.alerts.alert1"));
                }
                else {
                    //console.log(lawFirmInformationObject);

                    const docData = {
                        //personal Information
                        profileLawyer: selectedProfile,
                        lastName: lawFirmInformationObject.lastName,
                        emailAddress: lawFirmInformationObject.emailAddress,
                        billingEmailAddress: lawFirmInformationObject.billingEmailAddress,
                        addressLine1: lawFirmInformationObject.addressLine1,
                        addressLine2: lawFirmInformationObject.addressLine2,
                        zip: lawFirmInformationObject.zip,
                        state: lawFirmInformationObject.state,
                        city: lawFirmInformationObject.city,
                        country: lawFirmInformationObject.country,
                        phoneNumber: lawFirmInformationObject.phoneNumber,

                        //Required Information
                        workPlace: lawFirmRequiredInformationObject.workPlace,
                        companySize: lawFirmRequiredInformationObject.companySize,
                        minBudget: lawFirmRequiredInformationObject.minBudget,
                        industries: lawFirmRequiredInformationObject.industries,
                        practiceAreas: lawFirmRequiredInformationObject.practiceAreas,
                        hourlyRate: lawFirmRequiredInformationObject.hourlyRate,
                        workStart: lawFirmRequiredInformationObject.workStart,
                        workDuration: lawFirmRequiredInformationObject.workDuration,
                        maxHoursPerWeek: lawFirmRequiredInformationObject.maxHoursPerWeek,
                        additionalInfo: lawFirmRequiredInformationObject.additionalInfo,
                        provideAServiceApplication: false
                    }

                    //console.log(docData);

                    const refIncorporation = ref(storage, `users/${user.uid}/incorporationCertificate`);
                    const refVat = ref(storage, `users/${user.uid}/vatCertificate`);
                    const refCapability = ref(storage, `users/${user.uid}/capabilityStatement`);

                    if (incorporationCertificate !== null) {
                        uploadBytes(refIncorporation, incorporationCertificate, incorporationCertificateMetadata).then((snapshotIncorporation) => {
                            //console.log('Uploaded the incorporation certificate file!');
                        });
                    }

                    if (!firstApplicationDone) {
                        //console.log("if statement started in handleSend lawywe")
                        docData.firstApplicationDone = true;
                        //console.log("if statement started in handleSend lawyerr")
                    }

                    if (vatCertificate !== null) {
                        uploadBytes(refVat, vatCertificate, vatCertificateMetadata).then((snapshotVat) => {
                            //console.log('Uploaded the vat certificate file!');
                        });
                    }

                    if (capabilityStatement !== null) {
                        uploadBytes(refCapability, capabilityStatement, capabilityStatementMetadata).then((snapshotVat) => {
                        });
                    }

                    await setDoc(doc(db, "userInfo", user.uid), docData);

                    const docDataCreation = {
                        queryCreationDate: serverTimestamp(),
                        finished: false
                    }

                    const appRef = doc(collection(db, "userInfo", user.uid, "provideAServiceQuery"));
                    //console.log("This is my pathRef : ", appRef)
                    await setDoc(appRef, docDataCreation);
                    //const appRef = await addDoc(collection(db, "userInfo", user.uid, "provideAServiceQuery"), docDataCreation);


                    const result = await sendPASASMail({ appId: appRef.id });
                    //console.log("Mail is sent", result);
                    setShowValidation(false);
                    setLoading(false);
                    navigate("/ProvideAServiceApply/Confirmation");

                }
            }
        } catch (error) {
            console.log('Error adding document:', error)
        }
    };



    return (
        <>

            <Modal show={showValidation} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title>{t("pages.provideAServiceLoggedIn.provideAServiceApply.confirmModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("pages.provideAServiceLoggedIn.provideAServiceApply.confirmModal.text1")}<br />
                        {t("pages.provideAServiceLoggedIn.provideAServiceApply.confirmModal.text2")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose} disabled={loading}>
                        {t("pages.provideAServiceLoggedIn.provideAServiceApply.confirmModal.buttonClose")}
                    </Button>
                    <Button variant="outline-primary" onClick={handleSend} disabled={loading}>
                        {loading ? <Spinner animation="border" variant="primary" /> : t("pages.provideAServiceLoggedIn.provideAServiceApply.confirmModal.buttonConfirm") }
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header style={{ backgroundColor: 'rgb(220, 53, 69)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title>{t("pages.provideAServiceLoggedIn.provideAServiceApply.cancelModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("pages.provideAServiceLoggedIn.provideAServiceApply.cancelModal.text1")} <br />
                        {t("pages.provideAServiceLoggedIn.provideAServiceApply.cancelModal.text2")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose} sx={{ mr: 1 }}>
                        {t("pages.provideAServiceLoggedIn.provideAServiceApply.cancelModal.buttonClose")}
                    </Button>
                    <Button variant="outline-danger" onClick={handleConfirm}>
                        {t("pages.provideAServiceLoggedIn.provideAServiceApply.cancelModal.buttonConfirm")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Container style={{ marginTop: "100px" }}>
                <Row className="mb-5">
                    <Col>
                        <h1>{t("pages.provideAServiceLoggedIn.provideAServiceApply.title")}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {selectedProfile === "Lawyer" || selectedProfile === "Paralegal"
                            ? renderSwitch(listQuestionsLawyer[activeStep].name)
                            : renderSwitch(listQuestionsLawFirm[activeStep].name)}

                    </Col>
                </Row>

                <Row className="mt-5 mb-5">
                    <Col>
                        <Button
                            variant="outline-danger"
                            size="lg"
                            onClick={handleShow}
                            sx={{ mr: 1 }}
                        >
                            {t("pages.provideAServiceLoggedIn.provideAServiceApply.cancelButton")}
                        </Button>{' '}
                        {(selectedProfile === "Lawyer" || selectedProfile === "Paralegal") ? (
                            <Button
                                variant="outline-secondary"
                                size="lg"
                                disabled={activeStep === 0}

                                onClick={() => handleBack()}
                                sx={{ mr: 1 }}
                            >
                                {t("pages.provideAServiceLoggedIn.provideAServiceApply.backButton")}
                            </Button>
                        ) : (
                            <span></span>
                        )}
                    </Col>
                    <Col>
                        <Button variant="outline-primary"
                            onClick={() => handleNext()}
                            disabled={isNextButtonDisabled()}
                            size="lg"
                        >
                            {activeStep === 2 - 1 ? t("pages.provideAServiceLoggedIn.provideAServiceApply.finishButton") : t("pages.provideAServiceLoggedIn.provideAServiceApply.nextButton") }
                        </Button>
                    </Col>
                </Row>

            </Container>
        </>
    );
};


export default MyProvideAServiceApply;

