import { Row, Col, Container, Button, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./StyleSheet.css";
import { useTranslation } from 'react-i18next';

const MyServiceSelectionButtons = () => {

    const { t } = useTranslation();

    return (

        <Container style={{ fontFamily: 'Lato' }}>

            <Row className="d-flex">

                <Col className="vertical-rule">
                    <Stack gap={3} className="d-flex align-items-center">
                        <div className="p-2">
                            <h2 style={{ fontSize: "25px", color: 'rgb(13, 110, 253)', fontWeight: 'bold' }}>
                                {t("pages.aboutUs.serviceSelectionButtons.service1.title1")}<br />
                                {t("pages.aboutUs.serviceSelectionButtons.service1.title2")}
                            </h2></div>
                        <div className="p-2" style={{ width: "70%", textAlign: 'center' }}>
                            <p style={{ color: 'grey', fontSize: "16px" }}>
                                {t("pages.aboutUs.serviceSelectionButtons.service1.text1")}
                            </p>
                        </div>
                    </Stack>
                </Col>

                <Col >
                    <Stack gap={3} className="d-flex align-items-center">
                        <div className="p-2">
                            <h2 style={{ fontSize: "25px", color: 'rgb(13, 110, 253)', fontWeight: 'bold' }}>
                                {t("pages.aboutUs.serviceSelectionButtons.service2.title1")}<br />
                                {t("pages.aboutUs.serviceSelectionButtons.service2.title2")}
                            </h2></div>
                        <div className="p-2" style={{ width: "70%", textAlign: 'center' }}>
                            <p style={{ color: 'grey', fontSize: "16px" }}>
                                {t("pages.aboutUs.serviceSelectionButtons.service1.text1")}
                            </p>
                        </div>
                    </Stack>
                </Col>
            </Row>


            <Row className="mt-3 mb-5" style={{ height: '100px' }} >
                <Col md={6} className="mt-3 mb-3">
                    <Link to="/FindALawyer">
                        <Button variant="outline-primary" size="lg"><p className="mb-2 mt-2" style={{ fontSize: '20px' }}> {t("pages.aboutUs.serviceSelectionButtons.service1.button")}</p></Button>
                    </Link>
                </Col>
                
                <Col md={6} className="mt-3 mb-3">
                    <Link to="/ProvideAService" >
                        <Button variant="outline-primary" size="lg"><p className="mb-2 mt-2" style={{ fontSize: '20px' }}> {t("pages.aboutUs.serviceSelectionButtons.service2.button")}</p></Button>
                    </Link>
                </Col>
            </Row>
        </Container>
        );
};

export default MyServiceSelectionButtons;