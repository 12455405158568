import { Route, Routes } from "react-router-dom";
import MyNotFoundPage from '../../Error/NotFoundPage/NotFound.js';
import MyProvideAServiceApply from './ProvideAServiceApply.js';
import MyConfirmationApplication from './ConfirmationApplication.js';

const MyProvideAServiceApplyRoutes = () => {
    return (
        <Routes>
            <Route index element={<MyProvideAServiceApply />} />
            <Route path="/Confirmation" element={<MyConfirmationApplication />} />
            <Route path="*" element={<MyNotFoundPage />} />
        </Routes>
    );
};

export default MyProvideAServiceApplyRoutes;