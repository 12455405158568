import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const MyQuestionProfile = ({ handleSelectedProfile }) => {

    const { t } = useTranslation();

    const [selectedButton, setSelectedButton] = useState("");

    const handleButtonClick = (value) => {
        setSelectedButton(value);
        handleSelectedProfile(value);
    };

    const buttonStyle = {
        width: '50%',
        height: '50px',
    };

    
    useEffect(() => {
        handleSelectedProfile(selectedButton);
    }, [selectedButton]);
   


    return (
        <Container>
            <Row className="mb-5">
                <Col>
                    <h2>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionProfile.legalCategory")} <span style={{ color: 'red' }}>*</span></h2>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'Lawyer' ? 'btn-primary active' : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('Lawyer')}
                    >
                        {t("pages.provideAServiceLoggedIn.provideAServiceApply.questionProfile.lawyer")}
                    </button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'Paralegal' ? 'btn-primary active' : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('Paralegal')}
                    >
                        {t("pages.provideAServiceLoggedIn.provideAServiceApply.questionProfile.paralegal")}
                    </button>
                </Col>
            </Row>


        </Container>
    );
};

export default MyQuestionProfile;
