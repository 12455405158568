import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import AppFirebase from '../../App.js';
import { getFirestore, doc, getDoc} from "firebase/firestore";
import { useTranslation } from 'react-i18next';

function MyBusinessAddress({ handleInputBusinessAddress, savedInput }) {
    const { t } = useTranslation();

    const db = getFirestore(AppFirebase);

    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsCity, setOptionsCity] = useState([]);
    const [docSnap, setDocSnap] = useState(null);

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");
            let docSnapTemp = await getDoc(docRef);
            setDocSnap(docSnapTemp);

            const sortedOptionsCountry = docSnapTemp.data().optionsCountry;

            // Sort the copied array alphabetically based on the label property
            sortedOptionsCountry.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            });

            // Update state with the sorted array
            setOptionsCountry(sortedOptionsCountry);

        };

        fetchData();

        //console.log(savedInput);
    }, []);


    const cityDisplay = (country, docSnap) => {
        //setOptionsCity([{ label: "", value: "" }]);
        if (docSnap !== null) {
            switch (country) {
                case "United Arab Emirates":
                    setOptionsCity(docSnap.data().optionsCityUAE);
                    break;
                case "Qatar":
                    setOptionsCity(docSnap.data().optionsCityQAT);
                    break;
                case "Lebanon":
                    setOptionsCity(docSnap.data().optionsCityLBN);
                    break;
                case "Saudi Arabia":
                    setOptionsCity(docSnap.data().optionsCityKSA);
                    break;
                case "Kuwait":
                    setOptionsCity(docSnap.data().optionsCityKWT);
                    break;
                case "Jordan":
                    setOptionsCity(docSnap.data().optionsCityJOR);
                    break;
                case "Oman":
                    setOptionsCity(docSnap.data().optionsCityOMN);
                    break;
                case "Bahrain":
                    setOptionsCity(docSnap.data().optionsCityBHR);
                    break;
                case "Other...":
                    setOptionsCity([{ label: "Other...", value: "Other..." }]);
                    break;
                default:
                    setOptionsCity([{ label: "", value: "" }]);
            }
        }
    };

    const [selectedOption, setSelectedOption] = useState(savedInput);

    const handleChange = (input, field) => {
        if (field === 'country') {
            const updatedObject = { ...selectedOption };
            updatedObject.city = { label: "", value: "" };
            updatedObject.country = input;
            setSelectedOption(updatedObject);
        } else if (field === 'zip') {
            const updatedObject = { ...selectedOption };
            updatedObject.zip = input.target.value;
            setSelectedOption(updatedObject);
        } else if (field === 'state') {
            const updatedObject = { ...selectedOption };
            updatedObject.state = input.target.value;
            setSelectedOption(updatedObject);
        } else if (field === 'city') {
            const updatedObject = { ...selectedOption };
            updatedObject.city = input;
            setSelectedOption(updatedObject);
        } else if (field === 'address1') {
            const updatedObject = { ...selectedOption };
            updatedObject.address1 = input.target.value;
            setSelectedOption(updatedObject);
        } else if (field === 'address2') {
            const updatedObject = { ...selectedOption };
            updatedObject.address2 = input.target.value;
            setSelectedOption(updatedObject);
        } else {
            //Nothing
        }
        handleInputBusinessAddress(selectedOption);
    }

    useEffect(() => {
        handleInputBusinessAddress(selectedOption);
    }, [selectedOption]);

    return (
        <Container style={{ width: '80%' }}>
            <Row className="mb-5">
                <Col>
                    <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.questionBusinessAddress.title")} <span style={{ color: 'red' }}>*</span></h1>
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col sm={10}>
                    <Row className="mb-3">
                        <Stack>
                            <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionBusinessAddress.country")} <span style={{ color: 'red' }}>*</span></h5>
                            <div className="flex-grow-1">
                                <Select
                                    defaultValue={selectedOption.country}
                                    onChange={(input) => {
                                        handleChange(input, 'country');
                                        
                                        cityDisplay(input.value, docSnap);
                                    }}
                                    options={optionsCountry}
                                />
                            </div>
                        </Stack>
                    </Row>
                    <Row className="mb-3">
                        <Stack>
                            <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionBusinessAddress.state")}</h5>
                            <div className="flex-grow-1">
                                <Form.Control
                                    type="text"
                                    onChange={(input) => handleChange(input, 'state')}
                                    defaultValue={selectedOption.state}
                                    maxLength={25}
                                />
                            </div>
                        </Stack>
                    </Row>
                    <Row className="mb-3">
                        <Stack>
                            <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionBusinessAddress.city")} <span style={{ color: 'red' }}>*</span></h5>
                            <div className="flex-grow-1">
                                <Select
                                    defaultValue={selectedOption.city}
                                    onChange={(input) => handleChange(input, 'city')}
                                    options={optionsCity}
                                    value={selectedOption.city}
                                    maxLength={25}
                                />
                            </div>
                        </Stack>
                    </Row>
                    <Row className="mb-3">
                        <Stack>
                            <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionBusinessAddress.zip")}</h5>
                            <div className="flex-grow-1">
                                <Form.Control
                                    type="text"
                                    onChange={(input) => handleChange(input, 'zip')}
                                    defaultValue={selectedOption.zip}
                                    maxLength={9}
                                />
                            </div>
                        </Stack>
                    </Row>
                    <Row className="mb-3">
                        <Stack>
                            <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionBusinessAddress.address1")} <span style={{ color: 'red' }}>*</span></h5>
                            <div className="flex-grow-1">
                                <Form.Control
                                    onChange={(input) => handleChange(input, 'address1')}
                                    type="text"
                                    defaultValue={selectedOption.address1}
                                    maxLength={40}
                                />
                            </div>
                        </Stack>
                    </Row>
                    <Row className="mb-3">
                        <Stack>
                            <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionBusinessAddress.address2")}</h5>
                            <div className="flex-grow-1">
                                <Form.Control
                                    type="text"
                                    defaultValue={selectedOption.address2}
                                    onChange={(input) => handleChange(input, 'address2')}
                                    maxLength={40}
                                />
                            </div>
                        </Stack>
                    </Row>
                </Col>
                <Col>
                </Col>
            </Row>
        </Container>
    );
};

export default MyBusinessAddress;