import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MyCountriesPaper from './CountriesPaper.js';
import { useTranslation } from 'react-i18next'


const MyWhereWeProvideService = () => {

    const { t } = useTranslation();

    return (
        <Container style={{ fontFamily: 'Lato' }}>
            <Row className="mt-5 mb-5">
                <Col >
                    <h1>{t("pages.whereWeProvideService.title")}</h1>
                </Col>
            </Row>
            <Row className="mt-5 mb-5">
                <Col>
                    <MyCountriesPaper />
                </Col>
            </Row>
        </Container>
    );
};

export default MyWhereWeProvideService;
