import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MyWhoWeAre from './WhoWeAre.js';
import MyOurVision from './OurVision.js';
import MyOurMission from './OurMission.js';
import MyServiceSelectionButtons from './ServiceSelectionButtons.js'
import "./StyleSheet.css";
import { useTranslation } from 'react-i18next';

const MyAboutUs = () => {

    const { t } = useTranslation();



    useEffect(() => {
        const handleScroll = () => {
            const scrollElements = document.querySelectorAll('.scroll-transition');
            const windowHeight = window.innerHeight;

            scrollElements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                if (elementTop < windowHeight * 0.9) {
                    element.classList.add('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once






    return (
        <>
            <Container fluid style={{ fontFamily: 'Lato' }}>
                <Row>
                    <Col>
                        <div className="mt-5 mb-5">
                            <h1 className="ms-5" style={{ textAlign: 'center', fontSize: '36px' }}>{t("pages.aboutUs.title")}</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="mt-5 mb-5">
                    <Col>
                        <MyServiceSelectionButtons />
                    </Col>
                </Row>
                <Row className="mt-5 mb-5 scroll-transition">
                    <Col>
                        <MyWhoWeAre />
                    </Col>
                </Row>

                <Row className="mt-5 mb-5  scroll-transition">
                    <Col>
                        <MyOurMission />
                    </Col>
                </Row>
                <Row className="mt-5 mb-5  scroll-transition">
                    <Col>
                        <MyOurVision />
                    </Col>
                </Row>

            </Container>
        </>

    );
};

export default MyAboutUs;
