import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Modal, Stack } from 'react-bootstrap';
import { useContext } from 'react';
import { UserContext } from '../App';
import { collection, addDoc, getFirestore, serverTimestamp } from "firebase/firestore"; 
import AppFirebase from '../App.js';
import { useTranslation } from 'react-i18next';

function MyContactUs() {

    const { t } = useTranslation();


    const user = useContext(UserContext);
    const db = getFirestore(AppFirebase); 

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    useEffect(() => {
        if (user !== null) {
            setName(user.displayName);
            setEmail(user.email)
        }
    },[user])

    const handleSubmit = async (e) => {
        
        e.preventDefault();

        try {
            // Assuming you are using asynchronous database operations (e.g., Firestore)
            if (user !== null) {
                const docRef = await addDoc(collection(db, "contactMessages"), {
                    userId: user.uid,
                    timestamp: serverTimestamp(),
                    name: name,
                    email: email,
                    message: message
                });

                setMessage('');
            } else {
                const docRef = await addDoc(collection(db, "contactMessages"), {
                    userId: "",
                    timestamp: serverTimestamp(),
                    name: name,
                    email: email,
                    message: message
                });
                setName('')
                setEmail('');
                setMessage('');
            }
            handleShowModal();

            
        } catch (error) {
            // Handle any errors that occurred during the database operation
            console.error("Error adding document: ", error);
        }
    };

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-success">{t("pages.contactUs.modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="lead">
                        {t("pages.contactUs.modal.text1")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        {t("pages.contactUs.modal.button")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Container className="mt-5 mb-5" style={{ fontFamily: 'Lato, sans-serif' }}>
                <h1 className="text-center mt-5 mb-5">{t("pages.contactUs.form.title")}</h1>
                <Stack gap={3}>
                    <h3>{t("pages.contactUs.form.text1")}</h3>
                </Stack>

            <Form onSubmit={handleSubmit} className="mt-5">
                <Form.Group className="mb-4">
                    
                    <Form.Control
                        type="text"
                        placeholder={t("pages.contactUs.form.placeholderName")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        readOnly={user ? true : false}
                        disabled={user ? true : false}
                        required
                    />
                </Form.Group>

                    <Form.Group className="mb-4">
                    
                    <Form.Control
                        type="email"
                            placeholder={t("pages.contactUs.form.placeholderEmail")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly={user ? true : false}
                        disabled={user ? true : false}
                        required
                    />
                </Form.Group>

                    <Form.Group className="mb-4">
                    
                    <Form.Control
                        as="textarea"
                        rows={5}
                            placeholder={t("pages.contactUs.form.placeholderMessage")}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="outline-primary" type="submit" size="lg" className="mt-3">
                        {t("pages.contactUs.form.button")}
                </Button>
            </Form>
            </Container>
        </>
        );
};

export default MyContactUs;

