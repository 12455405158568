import React, { useState, useEffect, useContext } from 'react';
import { Container, Stack, Row, Col, Form, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AppFirebase from '../../App.js';
import { getStorage, ref, getMetadata, listAll } from "firebase/storage";
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { UserContext } from '../../App';
import PhoneInput from "react-phone-input-2";
import { useTranslation } from 'react-i18next';

//Check all info are filled before enabling next button
//extract all info in json file and send it to recap file
function FormLawFirm({ handleLawFirmInformationObject, handleLawFirmRequiredInformationObject, handleIncorporationCertificate, handleIncorporationCertificateMetadata, handleVatCertificate, handleVatCertificateMetadata, handleCapabilityStatement, handleCapabilityStatementMetadata, handleValidatedFormLawFirm }) {

    const { t } = useTranslation();

    const [incorporationCertificateMetadata, setIncorporationCertificateMetadata] = useState(null);
    const [vatCertificateMetadata, setVatCertificateMetadata] = useState(null);
    const [capabilityStatementMetadata, setCapabilityStatementMetadata] = useState(null);

    const [alreadyExists, setAlreadyExists] = useState(false); // Initialize it as false

    const [validated, setValidated] = useState(false);

    const user = useContext(UserContext);
    const db = getFirestore(AppFirebase);
    const storage = getStorage();

    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsCity, setOptionsCity] = useState([]);
    const [optionsState, setOptionsState] = useState([]);
    const [optionsNumEmployees, setOptionsNumEmployees] = useState([]);
    const [optionsBudget, setOptionsBudget] = useState([]);
    const [optionsIndustries, setOptionsIndustries] = useState([]);
    const [optionsPracticeAreas, setOptionsPracticeAreas] = useState([]);
    const [optionsOnSiteRemote, setOptionsOnSiteRemote] = useState([]);
    const [optionsHourlyRate, setOptionsHourlyRate] = useState([]);
    const [optionsWorkStart, setOptionsWorkStart] = useState([]);
    const [optionsWorkTime, setOptionsWorkTime] = useState([]);
    const [optionsWorkHoursWeek, setOptionsWorkHoursWeek] = useState([]);
    const [docSnapStatic, setDocSnapStatic] = useState([]);



    const cityDisplay = (country, docSnap) => {
        //setOptionsCity([{ label: "", value: "" }]);
        if (docSnap !== null) {
            switch (country) {
                case "United Arab Emirates":
                    setOptionsCity(docSnap.data().optionsCityUAE);
                    break;
                case "Qatar":
                    setOptionsCity(docSnap.data().optionsCityQAT);
                    break;
                case "Lebanon":
                    setOptionsCity(docSnap.data().optionsCityLBN);
                    break;
                case "Saudi Arabia":
                    setOptionsCity(docSnap.data().optionsCityKSA);
                    break;
                case "Kuwait":
                    setOptionsCity(docSnap.data().optionsCityKWT);
                    break;
                case "Jordan":
                    setOptionsCity(docSnap.data().optionsCityJOR);
                    break;
                case "Oman":
                    setOptionsCity(docSnap.data().optionsCityOMN);
                    break;
                case "Bahrain":
                    setOptionsCity(docSnap.data().optionsCityBHR);
                    break;
                case "Other...":
                    setOptionsCity([{ label: "Other...", value: "Other..." }]);
                    break;
                default:
                    setOptionsCity([]);
            }
        }
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024;  // 5MB, adjust as needed

    const validateFile = (file) => {
        // Validate file type (example: allow only PDF files)
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        if (!allowedTypes.includes(file.type)) {
            alert(t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.alerts.alert1"));
            return false;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            alert(t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.alerts.alert2"));
            return false;
        }

        return true;
    };

    const fetchDocuments = () => {

        if (user !== null) {
            const listRef = ref(storage, `users/${user.uid}`);
            listAll(listRef)
                .then((res) => {
                    if (res.items.length > 0) {
                        res.items.forEach((item) => {
                            //console.log("result : ", res.items[0]._location.path_);
                            // Get metadata properties

                            let docPath = item._location.path_;
                            //console.log("test", docPath);
                            let docRef = ref(storage, docPath);
                            let pathArray = docPath.split("/");
                            // Pop the last element from the array
                            let lastElement = pathArray.pop();
                            //console.log(lastElement)
                            getMetadata(docRef)
                                .then((metadata) => {
                                    if (lastElement === "incorporationCertificate") {
                                        setIncorporationCertificateMetadata(metadata);
                                    } else if (lastElement === "vatCertificate") {
                                        setVatCertificateMetadata(metadata);
                                    } else if (lastElement === "capabilityStatement") {
                                        setCapabilityStatementMetadata(metadata);
                                    } else {

                                    }

                                    //console.log(metadata);
                                })
                                .catch((error) => {
                                    // Uh-oh, an error occurred!
                                });
                        });
                    }

                }).catch((error) => {
                    // Uh-oh, an error occurred!
                });
            
        }

    };

    useEffect(() => {
        fetchDocuments();
    }, [user])

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");

            const docSnapTemp = await getDoc(docRef);
            setDocSnapStatic(docSnapTemp);

            const userData = await getDoc(userRef);

            const sortedOptionsCountry = docSnapTemp.data().optionsCountry;

            // Sort the copied array alphabetically based on the label property
            sortedOptionsCountry.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            });

            // Update state with the sorted array
            setOptionsCountry(sortedOptionsCountry);



            setOptionsState(docSnapTemp.data().optionsState);
            setOptionsNumEmployees(docSnapTemp.data().optionsNumEmployees);
            //Eliminated last element
            setOptionsBudget(docSnapTemp.data().optionsBudget.slice(0, docSnapTemp.data().optionsBudget.length - 1));
            setOptionsIndustries(docSnapTemp.data().optionsIndustries);
            setOptionsPracticeAreas(docSnapTemp.data().optionsPracticeAreas);
            setOptionsOnSiteRemote(docSnapTemp.data().optionsOnSiteRemote);
            setOptionsWorkStart(docSnapTemp.data().optionsWorkStart);
            setOptionsWorkTime(docSnapTemp.data().optionsDuration.slice(0, docSnapTemp.data().optionsDuration.length - 1));
            setOptionsWorkHoursWeek(docSnapTemp.data().optionsWorkHoursWeek.slice(0, docSnapTemp.data().optionsWorkHoursWeek.length - 1));
            setOptionsHourlyRate(docSnapTemp.data().optionsHourlyRate.slice(0, docSnapTemp.data().optionsHourlyRate.length - 1));

            cityDisplay(userData.data().country, docSnapTemp);
            //log(docSnap.data().optionsHourlyRate);
        };

        fetchData();
    }, []);

    const userRef = doc(db, 'userInfo', user.uid);
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#cfe2ff6e',
            // Add more custom styles as needed
        }),
    };


    useEffect(() => {
        // Function to fetch user data from Firestore
        const fetchUserData = async () => {
            try {
                const userData = await getDoc(userRef);

                if (userData.exists()) {
                    const data = userData.data();

                    //console.log(data)
                    // Update state variables with Firestore data
                    setLawFirmInformationObject({
                        lastName: data.lastName || '',
                        emailAddress: data.emailAddress || '',
                        billingEmailAddress:data.billingEmailAddress || '',
                        country: data.country || '',
                        state: data.state || '',
                        city: data.city || '',
                        zip: data.zip || '',
                        addressLine1: data.addressLine1 || '',
                        addressLine2: data.addressLine2 || '',
                        phoneNumber: data.phoneNumber || ''
                    });

                    setLawFirmRequiredInformationObject({
                        workPlace: data.workPlace || '',
                        companySize: data.companySize || 0,
                        minBudget: data.minBudget || 0,
                        industries: data.industries || [],
                        practiceAreas: data.practiceAreas || [],
                        hourlyRate: data.hourlyRate || 0,
                        workStart: data.workStart || 0,
                        workDuration: data.workDuration || 0,
                        maxHoursPerWeek: data.maxHoursPerWeek || 0,
                        additionalInfo: data.additionalInfo || '',
                    });

                    setLawFirmInformationObjectInit({
                        lastName: data.lastName || '',
                        emailAddress: data.emailAddress || '',
                        billingEmailAddress: data.billingEmailAddress || '',
                        country: data.country || '',
                        state: data.state || '',
                        city: data.city || '',
                        zip: data.zip || '',
                        addressLine1: data.addressLine1 || '',
                        addressLine2: data.addressLine2 || '',
                        phoneNumber: data.phoneNumber || ''
                    });

                    setLawFirmRequiredInformationObjectInit({
                        workPlace: data.workPlace || '',
                        companySize: data.companySize || 0,
                        minBudget: data.minBudget || 0,
                        industries: data.industries || [],
                        practiceAreas: data.practiceAreas || [],
                        hourlyRate: data.hourlyRate || 0,
                        workStart: data.workStart || 0,
                        workDuration: data.workDuration || 0,
                        maxHoursPerWeek: data.maxHoursPerWeek || 0,
                        additionalInfo: data.additionalInfo || '',
                    });

                    setAlreadyExists(true);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        // Call after mount
        fetchUserData();
    }, [user.uid]);

    const [lawFirmInformationObject, setLawFirmInformationObject] = useState({
        lastName: '',
        emailAddress: '',
        billingEmailAddress: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [lawFirmRequiredInformationObject, setLawFirmRequiredInformationObject] = useState({
        workPlace: '',
        companySize: 0,
        minBudget: 0,
        industries: [],
        practiceAreas: [],
        workStart: 0,
        workDuration: 0,
        maxHoursPerWeek: 0,
        additionalInfo: '',
        
    });

    const [lawFirmInformationObjectInit, setLawFirmInformationObjectInit] = useState({
        lastName: '',
        emailAddress: '',
        billingEmailAddress:'',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [lawFirmRequiredInformationObjectInit, setLawFirmRequiredInformationObjectInit] = useState({
        workPlace: '',
        companySize: 0,
        minBudget: 0,
        industries: [],
        practiceAreas: [],
        workStart: 0,
        workDuration: 0,
        maxHoursPerWeek: 0,
        additionalInfo: '',
    });

    const [incorporationCertificate, setIncorporationCertificate] = useState(null); 
    const [vatCertificate, setVatCertificate] = useState(null);
    const [capabilityStatement, setCapabilityStatement] = useState(null);


    const handleLawFirmRequiredInfoChange = (field, input) => {
        if (field === 'workPlace') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.workPlace = input.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'companySize') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.companySize = input.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'minBudget') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.minBudget = input.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'industries') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            const arrayOfStrings = input.map(map => map.value);
            updatedObject.industries =arrayOfStrings;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'practiceAreas') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            const arrayOfStrings = input.map(map => map.value);
            updatedObject.practiceAreas = arrayOfStrings;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'hourlyRate') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.hourlyRate = input.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'workStart') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.workStart = input.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'workDuration') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.workDuration = input.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'maxHoursPerWeek') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.maxHoursPerWeek = input.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else if (field == 'additionalInfo') {
            const updatedObject = { ...lawFirmRequiredInformationObject };
            updatedObject.additionalInfo = input.target.value;
            setLawFirmRequiredInformationObject(updatedObject);
        }
        else {
            //Nothing
        }
        //console.log(lawFirmRequiredInformationObject);
        handleLawFirmRequiredInformationObject(lawFirmRequiredInformationObject);

    };
    useEffect(() => {
        handleLawFirmRequiredInfoChange(lawFirmRequiredInformationObject)
    }, [lawFirmRequiredInformationObject]);

    const handleLawFirmInfoChange = (field, input) => {
        if (field === 'phoneNumber') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.phoneNumber = input;
            setLawFirmInformationObject(updatedObject);
            // console.log(updatedObject);
        }
        else if (field === 'billingEmailAddress') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.billingEmailAddress = input.target.value;
            setLawFirmInformationObject(updatedObject);
        }
        else if (field === 'country') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.country = input.value;
            updatedObject.city = "";
            setLawFirmInformationObject(updatedObject);
        }
        else if (field === 'zip') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.zip = input.target.value;
            setLawFirmInformationObject(updatedObject);
        }
        else if (field === 'state') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.state = input.target.value;
            setLawFirmInformationObject(updatedObject);
        }
        else if (field === 'city') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.city = input.value;
            setLawFirmInformationObject(updatedObject);
        }
        else if (field === 'addressLine1') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.addressLine1 = input.target.value;
            setLawFirmInformationObject(updatedObject);
        }
        else if (field === 'addressLine2') {
            const updatedObject = { ...lawFirmInformationObject };
            updatedObject.addressLine2 = input.target.value;
            setLawFirmInformationObject(updatedObject);
        } else {
            //Nothing
        }
        //console.log(lawFirmInformationObject);
        handleLawFirmInformationObject(lawFirmInformationObject);
        //console.log(lawFirmInformationObject);
    };

    useEffect(() => {
        handleLawFirmInfoChange(lawFirmInformationObject);
    }, [lawFirmInformationObject]);


    const handleAdditionalDocumentsChange = (field, input) => {

        if (field === 'incorporationCertificate') {

            const files = input.target.files;
            if (!validateFile(files[0])) {
                input.target.value = null;
                return;
            } else { 
                const metadata = {
                    contentType: files[0].type,  // Set content type based on the file type
                    customMetadata: {
                        personalName: files[0].name // Custom metadata property, adjust as needed
                    }
                };

                setIncorporationCertificate(files[0]);
                handleIncorporationCertificate(files[0]);
                handleIncorporationCertificateMetadata(metadata);
            }
        }
        else if (field === 'vatCertificate') {

            const files = input.target.files;
            if (!validateFile(files[0])) {
                input.target.value = null;
                return;
            } else { 
                const metadata = {
                    contentType: files[0].type,  // Set content type based on the file type
                    customMetadata: {
                        personalName: files[0].name // Custom metadata property, adjust as needed
                    }
                };
                setVatCertificate(files[0]);
                handleVatCertificate(files[0]);
                handleVatCertificateMetadata(metadata);
            }
        }
        else if (field === 'capabilityStatement') {
            
            const files = input.target.files;
            if (!validateFile(files[0])) {
                
                input.target.value = null;
                return;
            } else {
                
                const metadata = {
                    contentType: files[0].type,  // Set content type based on the file type
                    customMetadata: {
                        personalName: files[0].name // Custom metadata property, adjust as needed
                    }
                };
                setCapabilityStatement(files[0]);
                handleCapabilityStatement(files[0]);
                handleCapabilityStatementMetadata(metadata);
               
            }
        }
        else {
            //Nothing
        }
        
    };

    useEffect(() => {
        handleAdditionalDocumentsChange(incorporationCertificate)
    }, [incorporationCertificate]);
    useEffect(() => {
        handleAdditionalDocumentsChange(vatCertificate)
    }, [vatCertificate]);
    useEffect(() => {
        handleAdditionalDocumentsChange(capabilityStatement)
    }, [capabilityStatement]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };


    const personalInformation = () => {

        return (
            <Form noValidate validated={validated}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.lawFirmLegalNameLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                disabled
                                required
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.lawFirmLegalNamePlaceholder")}
                                className={`${alreadyExists && (lawFirmInformationObjectInit.lastName? lawFirmInformationObjectInit.lastName.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                value={lawFirmInformationObject.lastName}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.phoneNumberLabel")}</Form.Label>
                            <PhoneInput
                                
                                country={"bh"}
                                className="number"
                                country={"bh"}
                                value={lawFirmInformationObject.phoneNumber}
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.phoneNumberPlaceholder")}
                                onChange={(e) => {
                                    //console.log(e);
                                    handleLawFirmInfoChange('phoneNumber', e);
                                }}
                                inputStyle={{
                                    width: '100%',
                                    background: alreadyExists & (lawFirmInformationObjectInit.phoneNumber ? lawFirmInformationObjectInit.phoneNumber.trim().length !== 3 : false) ? '#cfe2ff6e' : 'transparent',
                                }}
                                countryCodeEditable={true}
                                autoFormat={true}
                                enableSearch={true}
                                disableSearchIcon={true}
                                preferredCountries={['lb', 'sa', 'ae', 'bh', 'kw', 'om', 'qa', 'jo']}
                                excludeCountries={['il']}
                                alwaysDefaultMask={true}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}> 
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.emailAddressLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                required
                                disabled
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.emailPlaceholder")}
                                className={`${alreadyExists & (lawFirmInformationObjectInit.emailAddress ? lawFirmInformationObjectInit.emailAddress.trim() !== "" : false)? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                value={user.email}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.billingEmailLabel")}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.billingEmailPlaceholder")}
                                className={`${alreadyExists & (lawFirmInformationObjectInit.billingEmailAddress ? lawFirmInformationObjectInit.billingEmailAddress.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                value={lawFirmInformationObject.billingEmailAddress}
                                onChange={(selectedOption) => { handleLawFirmInfoChange('billingEmailAddress', selectedOption); selectedOption.className = "form-control" }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.countryLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <div className="flex-grow-1">
                                <Select
                                    required
                                    value={optionsCountry.find((option) => option.value === lawFirmInformationObject.country)}
                                    styles={alreadyExists & (lawFirmInformationObjectInit.country ? lawFirmInformationObjectInit.country !== "" : false) ? customStyles : {}}
                                    options={optionsCountry}
                                    onChange={(selectedOption) => {
                                        handleLawFirmInfoChange('country', selectedOption);
                                        cityDisplay(selectedOption.value, docSnapStatic);
                                    }}
                                />
                            </div>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.stateLabel")}</Form.Label>
                            <div className="flex-grow-1">
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.statePlaceholder")}
                                    className={`${alreadyExists & (lawFirmInformationObjectInit.state ? lawFirmInformationObjectInit.state.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                    value={lawFirmInformationObject.state}
                                    onChange={(selectedOption) => { handleLawFirmInfoChange('state', selectedOption); selectedOption.className = "form-control" }}
                                />
                            </div>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.cityLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <div className="flex-grow-1">
                                <Select
                                    required
                                    styles={alreadyExists & (lawFirmInformationObjectInit.city ? lawFirmInformationObjectInit.city !== "" : false) ? customStyles : {}}
                                    value={(optionsCity && Array.isArray(optionsCity) && lawFirmInformationObject.city !== "")
                                        ? optionsCity.find(option => option.value === lawFirmInformationObject.city)
                                        : ""
                                    }
                                    options={optionsCity}
                                    onChange={(selectedOption) => handleLawFirmInfoChange('city', selectedOption)}
                                />
                            </div>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.zipLabel")}</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            className={`${alreadyExists & (lawFirmInformationObjectInit.zip ? lawFirmInformationObjectInit.zip !== "" : false)? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.zipPlaceholder")}
                            value={lawFirmInformationObject.zip}
                            onChange={(selectedOption) => {
                                handleLawFirmInfoChange('zip', selectedOption);
                                selectedOption.target.className = "form-control mb-3"
                            }}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.addressLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.addressLine1Placeholder")}
                                value={lawFirmInformationObject.addressLine1}
                                className={`${alreadyExists & (lawFirmInformationObjectInit.addressLine1 ? lawFirmInformationObjectInit.addressLine1 !== "" : false)? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                onChange={(selectedOption) => {
                                    handleLawFirmInfoChange('addressLine1', selectedOption);
                                    selectedOption.target.className = "form-control mb-3"
                                }}
                            />
                            <Form.Control
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.addressLine2Placeholder")}
                                className={`${alreadyExists & (lawFirmInformationObjectInit.addressLine2 ? lawFirmInformationObjectInit.addressLine2 !=="" : false)? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                value={lawFirmInformationObject.addressLine2}
                                onChange={(selectedOption) => {
                                    handleLawFirmInfoChange('addressLine2', selectedOption);
                                    selectedOption.target.className = "form-control mb-3"
                                }}
                            />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        );
    };



    const serviceInformation = () => {

        const animatedComponents = makeAnimated();

        return (

            <Container>
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.serviceInformation.companySizeLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select

                                    styles={alreadyExists && (lawFirmRequiredInformationObjectInit.companySize ? (lawFirmRequiredInformationObjectInit.companySize !== 0 || lawFirmRequiredInformationObjectInit.companySize.trim() !== "") : false) ? customStyles : {}}
                                    options={optionsNumEmployees}
                                    value={optionsNumEmployees.find((option) => option.value === lawFirmRequiredInformationObject.companySize)}
                                    onChange={(selectedOption) => {
                                        handleLawFirmRequiredInfoChange('companySize', selectedOption);
                                    }}
                                    
                                />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.serviceInformation.workPlaceLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    styles={alreadyExists && (lawFirmRequiredInformationObjectInit.workPlace ? lawFirmRequiredInformationObjectInit.workPlace.trim() !== "" : false) ? customStyles : {}}
                                    options={optionsOnSiteRemote}
                                    value={optionsOnSiteRemote.find((option) => option.value === lawFirmRequiredInformationObject.workPlace)}
                                    onChange={(selectedOption) => {
                                        handleLawFirmRequiredInfoChange('workPlace', selectedOption)

                                    }}
                                    
                                />
                            </div>
                        </Stack>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.serviceInformation.industriesLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    styles={alreadyExists && (lawFirmRequiredInformationObjectInit.industries ? lawFirmRequiredInformationObjectInit.industries.length !== 0 : false) ? customStyles : {}}
                                    options={optionsIndustries}
                                    onChange={(selectedOption) => handleLawFirmRequiredInfoChange('industries', selectedOption)}
                                    value={optionsIndustries.filter((option) =>
                                        lawFirmRequiredInformationObject.industries.includes(option.value)
                                    )}
                                />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.serviceInformation.practiceAreasLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    styles={alreadyExists && (lawFirmRequiredInformationObjectInit.practiceAreas ? lawFirmRequiredInformationObjectInit.practiceAreas.length !== 0 : false) ? customStyles : {}}
                                    options={optionsPracticeAreas}
                                    value={optionsPracticeAreas.filter((option) =>
                                        lawFirmRequiredInformationObject.practiceAreas.includes(option.value)
                                    )}
                                    onChange={(selectedOption) => handleLawFirmRequiredInfoChange('practiceAreas', selectedOption)}

                                />
                            </div>
                        </Stack>
                    </Col>
                </Row>


                <Row className="mb-3">
                    <Col>
                        <Stack>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.serviceInformation.additionalInformationLabel")}</h6>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.serviceInformation.additionalInformationPlaceholder")}
                                className={`${alreadyExists && (lawFirmRequiredInformationObjectInit.maxHoursPerWeek ? lawFirmRequiredInformationObjectInit.maxHoursPerWeek !== 0 : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                onChange={(selectedOption) => {
                                    handleLawFirmRequiredInfoChange('additionalInfo', selectedOption);
                                    selectedOption.target.className = "form-control"
                                }}
                                value={lawFirmRequiredInformationObject.additionalInfo}
                            />
                        </Stack>
                    </Col>
                </Row>


            </Container>

        );
    };

    const additionalDocuments = () => {

        return (
            <Container>
                <Row>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.incorporationCertificateLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="file"
                            required
                            onChange={(selectedOption) => handleAdditionalDocumentsChange('incorporationCertificate', selectedOption)}
                        />
                        <p style={{ color: incorporationCertificateMetadata && incorporationCertificateMetadata.customMetadata ? 'green' : 'red' }}>
                            {incorporationCertificateMetadata && incorporationCertificateMetadata.customMetadata ?
                                `${t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.pastlyUploadedFile") + incorporationCertificateMetadata.customMetadata.personalName}` :
                                t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.noFileUploaded")
                            }
                        </p>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.vatCertificateLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="file"
                            required
                            onChange={(selectedOption) => handleAdditionalDocumentsChange('vatCertificate', selectedOption)}
                        />
                        <p style={{ color: vatCertificateMetadata && vatCertificateMetadata.customMetadata ? 'green' : 'red' }}>
                            {vatCertificateMetadata && vatCertificateMetadata.customMetadata ?
                                `${t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.pastlyUploadedFile") + vatCertificateMetadata.customMetadata.personalName}` :
                                t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.noFileUploaded")
                            }
                        </p>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.capabilityStatementLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="file"
                            required
                            onChange={(selectedOption) => handleAdditionalDocumentsChange('capabilityStatement', selectedOption)}
                        />
                        <p style={{ color: capabilityStatementMetadata && capabilityStatementMetadata.customMetadata ? 'green' : 'red' }}>
                            {capabilityStatementMetadata && capabilityStatementMetadata.customMetadata ?
                                `${t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.pastlyUploadedFile") + capabilityStatementMetadata.customMetadata.personalName}` :
                                t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.noFileUploaded")
                            }
                        </p>
                    </Form.Group>
                </Row>
            </Container>
        );
    };


    return (
        
        <Accordion defaultActiveKey={['0', '1', '2']} className="d-flex flex-column " alwaysOpen>
            <Accordion.Item eventKey="0">
                <Accordion.Header style={{ marginTop: '15px' }}>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.personalInformation.title")}</Accordion.Header>
                <Accordion.Body>
                    {personalInformation()}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header style={{ marginTop: '15px' }}>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.serviceInformation.title")}</Accordion.Header>
                <Accordion.Body>
                    {serviceInformation()}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header style={{ marginTop: '15px' }}>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawFirm.additionalDocuments.title")}</Accordion.Header>
                <Accordion.Body>
                    {additionalDocuments()}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default FormLawFirm;