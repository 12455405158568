import { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { UserContext } from '../../App';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import { getAuth, signOut } from "firebase/auth";
import { Link } from 'react-router-dom';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import AppFirebase from '../../App.js';
import { useTranslation } from 'react-i18next';

function MyLoggedInUserMenu() {
    const { t } = useTranslation();

    const user = useContext(UserContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    const auth = getAuth();

    const [isAdmin, setIsAdmin] = useState(false);
    const handleSignOut = () => {
        setShow(false);
        signOut(auth)
            .then(() => {
                // Sign-out successful
                console.log("Sign-out successful");
            })
            .catch((error) => {
                // An error happened
                console.log("Error signing out:", error);
            });
    };
    const imageSrc = user?.photoURL || 'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg';

    useEffect(() => {
        const getIsAdmin = async () => {
            const db = getFirestore(AppFirebase);
            const docRef = doc(db, "userInfo", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                if (docSnap.data().isAdmin && docSnap.data().isAdmin === true) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);

                }
            } else {
                
                setIsAdmin(false);

            }
        }
        getIsAdmin();
    },[user])

    return (

        <>
            <Button variant="primary" onClick={handleShow} className="me-2 rounded-circle" style={{
                width: '50px', height: '50px', padding: 0,
                border: 'none', // Remove the border
                boxShadow: 'none', // Remove the box shadow
                background: 'transparent', }}>
                <Image
                    src={imageSrc}
                    alt="Button Image"
                    className="btn-image"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    roundedCircle
                    fluid
                />
            </Button>

            <Offcanvas  show={show} onHide={handleClose} placement="end" scroll>
                <Offcanvas.Header style={{ backgroundColor: 'rgb(13, 110, 253)' }} closeButton>
                    <Image
                        src={imageSrc}
                        alt="Button Image"
                        className="btn-image me-4"
                        style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                        roundedCircle
                        fluid
                    />
                    <Offcanvas.Title  style={{ color: 'white' }}>{user.displayName}</Offcanvas.Title>
                </Offcanvas.Header>  
                <Offcanvas.Body className="w-100">
                    <ListGroup className="w-100">
                        <Link to="/AccountInformation">
                            <ListGroup.Item
                                action
                                className="mb-2 rounded-0 border-0"
                                onClick={() => setShow(false)}
                            >
                                {t("loggedInUserMenu.accountInfo")}
                            </ListGroup.Item>
                        </Link>

                        { isAdmin && (
                            <Link to="/AdminWelcome">
                                <ListGroup.Item
                                    action
                                    className="mb-2 rounded-0 border-0"
                                    onClick={() => setShow(false)}
                                >
                                    {t("loggedInUserMenu.adminCenter")}
                                </ListGroup.Item>
                            </Link>
                        )}

                        <hr className="my-1" />
                        <Link to="/">
                            <ListGroup.Item
                                action
                                onClick={handleSignOut}
                                className="mb-2 rounded-0 border-0"
                            >
                                {t("loggedInUserMenu.signOut")}
                            </ListGroup.Item>
                        </Link>


                    </ListGroup>

                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
}

export default MyLoggedInUserMenu;
