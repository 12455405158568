import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { UserContext } from '../../App';
import { getStorage, ref, uploadBytes, getMetadata, listAll } from "firebase/storage";
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';


const MyProvideAServiceDocuments = (userProfile) => {

    const { t } = useTranslation();
    const user = useContext(UserContext);
    const storage = getStorage();
    //console.log(userProfile.userProfile);
    const isLawyerOrParalegal = userProfile.userProfile === "Lawyer" || userProfile.userProfile === "Paralegal";
    //console.log(isLawyerOrParalegal);

    const [resumeWithCredentials, setResumeWithCredentials] = useState(null);
    const [copyLawLicense, setCopyLawLicense] = useState(null);
    const [copyBarAdmission, setCopyBarAdmission] = useState(null);

    const [incorporationCertificate, setIncorporationCertificate] = useState(null);
    const [vatCertificate, setVatCertificate] = useState(null);
    const [capabilityStatement, setCapabilityStatement] = useState(null);

    const [resumeWithCredentialsMetadata, setResumeWithCredentialsMetadata] = useState(null);
    const [copyLawLicenseMetadata, setcopyLawLicenseMetadata] = useState(null);
    const [copyBarAdmissionMetadata, setCopyBarAdmissionMetadata] = useState(null);

    const [incorporationCertificateMetadata, setIncorporationCertificateMetadata] = useState(null);
    const [vatCertificateMetadata, setvatCertificateMetadata] = useState(null);
    const [capabilityStatementMetadata, setCapabilityStatementMetadata] = useState(null);


    const MAX_FILE_SIZE = 5 * 1024 * 1024;  // 5MB, adjust as needed

    const validateFile = (file) => {
        // Validate file type (example: allow only PDF files)
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        if (!allowedTypes.includes(file.type)) {
            alert(t("pages.accountInformation.provideAServiceDocuments.alerts.alert1"));
            return false;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            alert(t("pages.accountInformation.provideAServiceDocuments.alerts.alert2"));
            return false;
        }

        return true;
    };

    const handleAdditionalDocumentsChange = (field, input) => {
        if (isLawyerOrParalegal) {
            const refResume = ref(storage, `users/${user.uid}/resumeWithCredentials`);
            const refLawLicense = ref(storage, `users/${user.uid}/lawLicense`);
            const refBarAdmission = ref(storage, `users/${user.uid}/barAdmission`);
            if (field === 'resumeCredentials') {

                const files = input.target.files;

                if (!validateFile(files[0])) {
                    input.target.value = null;
                    return;
                } else {
                    const metadata = {
                        contentType: files[0].type,  // Set content type based on the file type
                        customMetadata: {
                            personalName: files[0].name // Custom metadata property, adjust as needed
                        }
                    };
                    uploadBytes(refResume, files[0], metadata).then((snapshotResume) => {
                        setResumeWithCredentials(files[0]);
                        console.log('Uploaded the resume file!');
                        setResumeWithCredentialsMetadata(metadata);
                    });
                }

            }
            else if (field === 'lawLicense') {
                const files = input.target.files;
                if (!validateFile(files[0])) {
                    input.target.value = null;
                    return;
                } else { 
                    const metadata = {
                        contentType: files[0].type,  // Set content type based on the file type
                        customMetadata: {
                            personalName: files[0].name // Custom metadata property, adjust as needed
                        }
                    };
                    uploadBytes(refLawLicense, files[0], metadata).then((snapshotLawLicense) => {
                        setCopyLawLicense(files[0]);
                        console.log('Uploaded the law license file!');
                        setcopyLawLicenseMetadata(metadata);
                    });
                }

            }
            else if (field === 'barAdmission') {
                const files = input.target.files;
                if (!validateFile(files[0])) {
                    input.target.value = null;
                    return;
                } else {
                    const metadata = {
                        contentType: files[0].type,  // Set content type based on the file type
                        customMetadata: {
                            personalName: files[0].name // Custom metadata property, adjust as needed
                        }
                    };
                    uploadBytes(refBarAdmission, files[0], metadata).then((snapshotBarAdmission) => {
                        setCopyBarAdmission(files[0]);
                        console.log('Uploaded the bar admission file!');
                        setCopyBarAdmissionMetadata(metadata);
                    });
                }

            }
            else {
                //Nothing
            }

        } else {
            const refIncorporation = ref(storage, `users/${user.uid}/incorporationCertificate`);
            const refVat = ref(storage, `users/${user.uid}/vatCertificate`);
            const refCapability = ref(storage, `users/${user.uid}/capabilityStatement`);

            if (field === 'incorporationCertificate') {
                const files = input.target.files;
                if (!validateFile(files[0])) {
                    input.target.value = null;
                    return;
                } else {
                    const metadata = {
                        contentType: files[0].type,  // Set content type based on the file type
                        customMetadata: {
                            personalName: files[0].name // Custom metadata property, adjust as needed
                        }
                    };
                    uploadBytes(refIncorporation, files[0], metadata).then((snapshotIncorporation) => {
                        setIncorporationCertificate(files[0]);
                        console.log('Uploaded the incorporation certificate file!');
                        setIncorporationCertificateMetadata(metadata);
                        //console.log(user.uid);
                    });
                }

            }
            else if (field === 'vatCertificate') {

                const files = input.target.files;
                if (!validateFile(files[0])) {
                    input.target.value = null;
                    return;
                } else {
                    const metadata = {
                        contentType: files[0].type,  // Set content type based on the file type
                        customMetadata: {
                            personalName: files[0].name // Custom metadata property, adjust as needed
                        }
                    };
                    uploadBytes(refVat, files[0], metadata).then((snapshotVat) => {
                        setVatCertificate(files[0]);
                        console.log('Uploaded the vat certificate file!');
                        setvatCertificateMetadata(metadata);
                    });
                }
            }
            else if (field === 'capabilityStatement') {

                const files = input.target.files;
                if (!validateFile(files[0])) {
                    input.target.value = null;
                    return;
                } else {
                    const metadata = {
                        contentType: files[0].type,  // Set content type based on the file type
                        customMetadata: {
                            personalName: files[0].name // Custom metadata property, adjust as needed
                        }
                    };
                    uploadBytes(refCapability, files[0], metadata).then((snapshotVat) => {
                        setCapabilityStatement(files[0]);
                        console.log('Uploaded the vat certificate file!');
                        setCapabilityStatementMetadata(metadata);
                    });
                }
            }
            else {
                //Nothing
            }
        }
        fetchDocuments();
    };

    const fetchDocuments = () => {

        if (user !== null) {
            if (isLawyerOrParalegal) {
                const listRef = ref(storage, `users/${user.uid}`);
                listAll(listRef)
                    .then((res) => {
                        if (res.items.length > 0) {
                            res.items.forEach((item) => {
                                //console.log("result : ", res.items[0]._location.path_);
                                // Get metadata properties

                                let docPath = item._location.path_;
                                //console.log("test", docPath);
                                let docRef = ref(storage, docPath);
                                let pathArray = docPath.split("/");
                                // Pop the last element from the array
                                let lastElement = pathArray.pop();
                                //console.log(lastElement)
                                getMetadata(docRef)
                                    .then((metadata) => {
                                        if (lastElement === "resumeWithCredentials") {
                                            setResumeWithCredentialsMetadata(metadata);
                                        } else if (lastElement === "barAdmission") {
                                            setCopyBarAdmissionMetadata(metadata);
                                        } else if (lastElement === "lawLicense") {
                                            setcopyLawLicenseMetadata(metadata);
                                        } else {

                                        }

                                        //console.log(metadata);
                                    })
                                    .catch((error) => {
                                        // Uh-oh, an error occurred!
                                    });
                            });
                        }

                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });


            } else {

                const listRef = ref(storage, `users/${user.uid}`);
                listAll(listRef)
                    .then((res) => {
                        if (res.items.length > 0) {
                            res.items.forEach((item) => {
                                //console.log("result : ", res.items[0]._location.path_);
                                // Get metadata properties

                                let docPath = item._location.path_;
                                //console.log("test", docPath);
                                let docRef = ref(storage, docPath);
                                let pathArray = docPath.split("/");
                                // Pop the last element from the array
                                let lastElement = pathArray.pop();
                                //console.log(lastElement)
                                getMetadata(docRef)
                                    .then((metadata) => {
                                        if (lastElement === "incorporationCertificate") {
                                            setIncorporationCertificateMetadata(metadata);
                                        } else if (lastElement === "vatCertificate") {
                                            setvatCertificateMetadata(metadata);
                                        } else if (lastElement === "capabilityStatement") {
                                            setCapabilityStatementMetadata(metadata);
                                        }

                                        else {

                                        }

                                        //console.log(metadata);
                                    })
                                    .catch((error) => {
                                        // Uh-oh, an error occurred!
                                    });
                            });
                        }

                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });
                
            }
        }

    };


    useEffect(() => {
        fetchDocuments();
    }, [user])

    useEffect(() => {
        fetchDocuments();
    }, [isLawyerOrParalegal]);

    useEffect(() => {
        setResumeWithCredentials(resumeWithCredentials);
    }, [resumeWithCredentials]);

    useEffect(() => {
        setCopyLawLicense(copyLawLicense);
    }, [copyLawLicense]);

    useEffect(() => {
        setCopyBarAdmission(copyBarAdmission);
    }, [copyBarAdmission]);

    useEffect(() => {
        setIncorporationCertificate(incorporationCertificate);

    }, [incorporationCertificate]);

    useEffect(() => {
        setVatCertificate(vatCertificate);
    }, [vatCertificate]);

    useEffect(() => {
        setCapabilityStatement(capabilityStatement);
    }, [capabilityStatement]);

    useEffect(() => {
        setResumeWithCredentialsMetadata(resumeWithCredentialsMetadata);
    }, [resumeWithCredentialsMetadata]);

    useEffect(() => {
        setcopyLawLicenseMetadata(copyLawLicenseMetadata);
    }, [copyLawLicenseMetadata]);

    useEffect(() => {
        setCopyBarAdmissionMetadata(copyBarAdmissionMetadata);
    }, [copyBarAdmissionMetadata]);

    useEffect(() => {
        setIncorporationCertificateMetadata(incorporationCertificateMetadata);
    }, [incorporationCertificateMetadata]);

    useEffect(() => {
        setvatCertificateMetadata(vatCertificateMetadata);
    }, [vatCertificateMetadata]);

    useEffect(() => {
        setCapabilityStatementMetadata(capabilityStatementMetadata);
    }, [capabilityStatementMetadata]);

    return user ? (
        <>
            <Card className="user-profile" style={{ padding: '0px', minHeight: '100%' }}>
                <Card.Header className="text-start h2" style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }} >
                    <Row className="d-flex mt-3 mb-3">
                        <Col md={12} className="d-flex align-items-center">
                            {t("pages.accountInformation.provideAServiceDocuments.title")}
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body className='text-start' style={{ height: '100%' }} >
                    <div >
                        <Card.Body>
                            {isLawyerOrParalegal ? (
                                <Container>
                                    <Row>
                                        <Form.Group className="position-relative mb-3">
                                            <Form.Label>{t("pages.accountInformation.provideAServiceDocuments.resume")}</Form.Label>

                                            <Form.Control
                                                type="file"
                                                onChange={(selectedOption) => handleAdditionalDocumentsChange('resumeCredentials', selectedOption)}

                                            />
                                            <p style={{ color: resumeWithCredentialsMetadata ? 'green' : 'red' }}>
                                                {resumeWithCredentialsMetadata
                                                    ? `${t("pages.accountInformation.provideAServiceDocuments.uploadedFile")} ${resumeWithCredentialsMetadata.customMetadata?.personalName || 'Unknown'}`
                                                    : t("pages.accountInformation.provideAServiceDocuments.noFile")}
                                            </p>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className="position-relative mb-3">
                                            <Form.Label>{t("pages.accountInformation.provideAServiceDocuments.lawLicense")}</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={(selectedOption) => handleAdditionalDocumentsChange('lawLicense', selectedOption)}

                                            />
                                            <p style={{ color: copyLawLicenseMetadata ? 'green' : 'red' }}>
                                                {copyLawLicenseMetadata ? `${t("pages.accountInformation.provideAServiceDocuments.uploadedFile")} ${copyLawLicenseMetadata.customMetadata?.personalName || 'Unknown'}` : t("pages.accountInformation.provideAServiceDocuments.noFile")}
                                            </p>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <Form.Group className="position-relative mb-3">
                                            <Form.Label>{t("pages.accountInformation.provideAServiceDocuments.barAdmission")}</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={(selectedOption) => handleAdditionalDocumentsChange('barAdmission', selectedOption)}

                                            />
                                            <p style={{ color: copyBarAdmissionMetadata ? 'green' : 'red' }}>
                                                {copyBarAdmissionMetadata ? `${t("pages.accountInformation.provideAServiceDocuments.uploadedFile")} ${copyBarAdmissionMetadata.customMetadata?.personalName || 'Unknown'}` : t("pages.accountInformation.provideAServiceDocuments.noFile")}
                                            </p>
                                        </Form.Group>
                                    </Row>
                                </Container>


                            ) : (

                                <Container>
                                    <Row>
                                        <Form.Group className="position-relative mb-3">
                                                <Form.Label>{t("pages.accountInformation.provideAServiceDocuments.incorporationCertificate")}</Form.Label>
                                            <Form.Control
                                                type="file"

                                                onChange={(selectedOption) => handleAdditionalDocumentsChange('incorporationCertificate', selectedOption)}

                                            />
                                            <p style={{ color: incorporationCertificateMetadata ? 'green' : 'red' }}>
                                                    {incorporationCertificateMetadata ? `${t("pages.accountInformation.provideAServiceDocuments.uploadedFile")} ${incorporationCertificateMetadata.customMetadata?.personalName || 'Unknown'}` : t("pages.accountInformation.provideAServiceDocuments.noFile")}

                                            </p>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className="position-relative mb-3">
                                                <Form.Label>{t("pages.accountInformation.provideAServiceDocuments.vatCertificate")}</Form.Label>
                                            <Form.Control
                                                type="file"

                                                onChange={(selectedOption) => handleAdditionalDocumentsChange('vatCertificate', selectedOption)}
                                            />
                                            <p style={{ color: vatCertificateMetadata ? 'green' : 'red' }}>
                                                    {vatCertificateMetadata ? `${t("pages.accountInformation.provideAServiceDocuments.uploadedFile")} ${vatCertificateMetadata.customMetadata?.personalName || 'Unknown'}` : t("pages.accountInformation.provideAServiceDocuments.noFile")}
                                            </p>
                                        </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className="position-relative mb-3">
                                                <Form.Label>{t("pages.accountInformation.provideAServiceDocuments.capabilityStatement")}</Form.Label>
                                                <Form.Control
                                                    type="file"

                                                    onChange={(selectedOption) => handleAdditionalDocumentsChange('capabilityStatement', selectedOption)}
                                                />
                                                <p style={{ color: capabilityStatementMetadata ? 'green' : 'red' }}>
                                                    {capabilityStatementMetadata ? `${t("pages.accountInformation.provideAServiceDocuments.uploadedFile")} ${capabilityStatementMetadata.customMetadata?.personalName || 'Unknown'}` : t("pages.accountInformation.provideAServiceDocuments.noFile")}
                                                </p>
                                            </Form.Group>
                                        </Row>

                                </Container>
                            )}
                        </Card.Body>
                    </div>

                </Card.Body>
            </Card>

        </>
    ) : (
        <Card className="d-flex align-items-center justify-content-center p-5">
            <Spinner animation="grow" variant="primary" size="lg" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Card.Text className="mt-3">Loading...</Card.Text>
        </Card>

    );
};

export default MyProvideAServiceDocuments;