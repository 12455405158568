import { Route, Routes } from "react-router-dom";
import MyFindALawyerSearch from './FindALawyerSearch.js';
import MyNotFoundPage from '../../Error/NotFoundPage/NotFound.js';
import MySelectionRecap from './SelectionRecap.js';
import MySelectionResult from './SelectionResult.js';
import MyConfirmationApplication from './ConfirmationApplication.js';
const MyFindALawyerSearchRoutes = () => {
    return (
        <Routes>
            <Route index element={<MyFindALawyerSearch />} />
            <Route path="/Recap" element={<MySelectionRecap />} />
            <Route path="/Result" element={<MySelectionResult />} />
            <Route path="/Confirmation" element={<MyConfirmationApplication />} />      
            <Route path="*" element={<MyNotFoundPage />} />
        </Routes>
    );
};

export default MyFindALawyerSearchRoutes;