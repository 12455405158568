import { Route, Routes } from "react-router-dom";
import MyAccountInformation from './AccountInformation';
import MyUserInformation from './UserInformation'

const MyAccountInformationRoutes = () => {
    return (
        <Routes>
            <Route index element={<MyAccountInformation />} />
            
            
        </Routes>
    );
};

export default MyAccountInformationRoutes;