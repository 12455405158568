import Header from './Header.js'
import './App.css';
import './Footer.js';
import ScrollToTop from "./ScrollToTop";
import Footer from './Footer.js';
import { Route, Routes } from "react-router-dom";
import MyAboutUsRoutes from "./LoggedOut/AboutUs/AboutUsRoutes";
import MyNotFoundPage from "./Error/NotFoundPage/NotFound.js";
import MyLoggedOutForm from './LoggedOutForm.js';
import MyFindALawyerRoutes from './LoggedOut/FindALawyer/FindALawyerRoutes.js';
import MyProvideAServiceRoutes from './LoggedOut/ProvideAService/ProvideAServiceRoutes';
import MyWhoDoWeHelpRoutes from './LoggedOut/WhoDoWeHelp/WhoDoWeHelpRoutes.js';
import MyWhereWeProvideServiceRoutes from './LoggedOut/WhereWeProvideService/WhereWeProvideServiceRoutes.js';
import MyAccountInformationRoutes from './LoggedIn/AccountInformation/AccountInformationRoutes';
import MyContactUs from './ContactUs/ContactUs.js';
import MyProvideAServiceApplyRoutes from './LoggedIn/ProvideAService/ProvideAServiceApplyRoutes.js';
import MyAdminPageRoutes from './Admin/AdminPageRoutes';
import MyFindALawyerSearchRoutes from './LoggedIn/FindALawyer/FindALawyerSearchRoutes.js';
import React, { createContext, useEffect, useState } from 'react';
import MyPageDevelopment from './Error/PageConstruction/PageDevelopment.js';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import AppFirebase from './FirebaseConfig.js';
import VerificationPage from './LoggedOut/VerificationPage.js';
import PasswordReset from './LoggedOut/PasswordReset.js';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import LanguageSelection from './Languages/LanguageSelection.js';
import Chatbot from './Chatbot/Chatbot.js'
import TermsRoutes from './Terms/TermsRoutes.js';

export const UserContext = createContext(null);

function App() {


    const db = getFirestore(AppFirebase);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const auth = getAuth()


    const [language, setLanguage] = useState('en');
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    useEffect(() => {
        const firstVisit = localStorage.getItem('firstVisit');
        //console.log(firstVisit)
        if (firstVisit) {
            setShowModal(true);
            localStorage.setItem('firstVisit', 'false');
        }
    }, []);


    const functions = getFunctions();
    const sendTrackingMail = httpsCallable(functions, 'sendTrackingMail');


 


  

    let currentQuestionIndex = 0;
    let currentQuestions;
    let docData = {};
    let quickButtonsDone = false;
    let emailAvailable = false;

    useEffect(() => {
        const getIsAdmin = async () => {
            const db = getFirestore(AppFirebase);
            const docRef = doc(db, "userInfo", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                if (docSnap.data().isAdmin && docSnap.data().isAdmin === true) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);

                }
            } else {
                // docSnap.data() will be undefined in this case
                setIsAdmin(false);

            }
        }
        if (user !== null) {
            getIsAdmin();
        }
    }, [user])
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);




    return (
        <UserContext.Provider value={user}>
            
            <div className='App'>
                <ScrollToTop />
                <Header />
                <article className="article">
                    <Routes>
                        <Route path="/" element={<MyLoggedOutForm />} />
                        <Route path="/PasswordReset" element={<PasswordReset />} />
                        <Route path="/ContactUs" element={<MyContactUs />} />
                        <Route path="/AboutUs/*" element={<MyAboutUsRoutes />} />
                        <Route path="/FindALawyer/*" element={<MyFindALawyerRoutes />} />
                        <Route path="/ProvideAService/*" element={<MyProvideAServiceRoutes />} />
                        <Route path="/WhoDoWeHelp/*" element={<MyWhoDoWeHelpRoutes />} />
                        <Route path="/WhereWeProvideService/*" element={<MyWhereWeProvideServiceRoutes />} />
                        <Route path="/AccountInformation/*" element={<MyAccountInformationRoutes />} />
                        <Route path="/Terms/*" element={<TermsRoutes />} />

                        {isAdmin && (
                            <Route path="/*" element={<MyAdminPageRoutes />} />
                        )}

                        <Route path="*" element={<MyNotFoundPage />} />
                        <Route path="/PageUnderConstruction" element={<MyPageDevelopment />} />
                        <Route path="/MailVerification" element={<VerificationPage />} />
                        {user && user.emailVerified ? (
                            <>
                                <Route path="/LawyerSearch/*" element={<MyFindALawyerSearchRoutes />} />
                                <Route path="/ProvideAServiceApply/*" element={<MyProvideAServiceApplyRoutes />} />
                            </>
                        ) : (
                            <Route path="*" element={<MyNotFoundPage />} />
                        )}
                    </Routes>
                    <div className='ChatBot'>
                        <Chatbot />
                    </div>
                    <LanguageSelection />
                </article>
                <Footer />
            </div>
        </UserContext.Provider >
    );
}



export default App;
