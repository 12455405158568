import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { FiInfo } from 'react-icons/fi';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useTranslation } from 'react-i18next';

function MyQuestionAdditionalInformation({ handleInputAdditionalInfo, savedInput, handleInputBillingEmail, savedInput2 }) {

    const { t } = useTranslation();

    const [message, setMessage] = useState(savedInput);
    const [billingEmail, setBillingEmail] = useState(savedInput2);
    const [isBillingEmailRequired, setIsBillingEmailRequired] = useState(false);

    const onAdditionalInfoChange = (event) => {
        setMessage(event.target.value);
        handleInputAdditionalInfo(event.target.value);
    };

    const onBillingEmailChange = (event) => {
        setBillingEmail(event.target.value);
        handleInputBillingEmail(event.target.value);
    };

    useEffect(() => {
        handleInputAdditionalInfo(message);
        handleInputBillingEmail(billingEmail);
    }, [message, billingEmail]);

    return (
        <Container style={{ marginBottom: '80px' }}>
            <Row className="mb-5">
                <Col>
                    <h1>
                        <FiInfo style={{ marginRight: '10px' }} /> {t("pages.findALawyerLoggedIn.findALawyerSearch.questionAdditionalInformation.title1")}
                    </h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col></Col>
                <Col sm={10}>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder={t("pages.findALawyerLoggedIn.findALawyerSearch.questionAdditionalInformation.additionlInfoPlaceholder")}
                        value={message}
                        onChange={onAdditionalInfoChange}
                        required
                    />
                </Col>
                <Col></Col>
            </Row>

            <Row className="mb-4 mt-5">
                <Col>
                    <h2>
                        <FiInfo style={{ marginRight: '10px' }} /> {t("pages.findALawyerLoggedIn.findALawyerSearch.questionAdditionalInformation.title2")}
                    </h2>
                    <ToggleButton
                        id="toggle-email"
                        type="checkbox"
                        variant="outline-primary"
                        checked={isBillingEmailRequired}
                        value="billingEmailToggle"
                        onChange={() => {
                            setIsBillingEmailRequired(!isBillingEmailRequired);
                            if (!isBillingEmailRequired) {
                                setBillingEmail("");
                            }
                        }}
                    >
                        {isBillingEmailRequired ? t("pages.findALawyerLoggedIn.findALawyerSearch.questionAdditionalInformation.billingButtonClicked") : t("pages.findALawyerLoggedIn.findALawyerSearch.questionAdditionalInformation.billingButtonNotClicked")}
                    </ToggleButton>
                </Col>
            </Row>

            {/* Billing Email Address Input */}
            {isBillingEmailRequired && (
                <Row className="mb-5">
                    <Col></Col>
                    <Col sm={10}>
                        <Form.Control
                            type="email"
                            placeholder={t("pages.findALawyerLoggedIn.findALawyerSearch.questionAdditionalInformation.billingEmailPlaceholder")}
                            value={billingEmail}
                            onChange={onBillingEmailChange}
                            required={isBillingEmailRequired}
                        />
                    </Col>
                    <Col></Col>
                </Row>
            )}
        </Container>
    );
}

export default MyQuestionAdditionalInformation;
