import { Stack, Form, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
export default function MyCountriesPaper() {
    const { t } = useTranslation();
    const initialCountries = [
        {
            id: 1,
            name: t("pages.whereWeProvideService.countries.bahrain"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FBahrein.jpg?alt=media&token=fa0b00d6-3e10-49c5-9942-684b69afc5c7'
        },
        {
            id: 2,
            name: t("pages.whereWeProvideService.countries.jordan"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FJordan.jpg?alt=media&token=9474e7ec-f68c-4555-bc77-474d30e73590'
        },
        {
            id: 3,
            name: t("pages.whereWeProvideService.countries.kuwait"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FKuwait.jpg?alt=media&token=eef21db1-78fb-4427-ac8a-cbc49dab449f'
        },
        {
            id: 4,
            name: t("pages.whereWeProvideService.countries.lebanon"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FBeirut.jpg?alt=media&token=358f6b0b-04ed-4098-934c-7945cbd3e010'
        },
        {
            id: 5,
            name: t("pages.whereWeProvideService.countries.oman"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FMascate.jpg?alt=media&token=a181cc0b-9ccc-438b-952b-488c5ec0fe52'
        },
        {
            id: 6,
            name: t("pages.whereWeProvideService.countries.qatar"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FQatar.jpg?alt=media&token=3da34bca-0611-489e-b618-908cf93bde8e'
        },
        {
            id: 7,
            name: t("pages.whereWeProvideService.countries.saudiArabia"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FRiyad.jpg?alt=media&token=a59b5666-75fa-48fc-9c86-9732203e8e7d'
        },
        {
            id: 8,
            name: t("pages.whereWeProvideService.countries.unitedArabEmirates"),
            imageSrc: 'https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FDubaiLandscape2.jpg?alt=media&token=99c5c006-be71-4f7e-a792-acb15dd6fc05'
        }
    ];

    const [countries, setCountries] = useState(initialCountries);
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredCountries = initialCountries.filter((country) =>
            country.name.toLowerCase().includes(searchTerm)
        );

        setCountries(filteredCountries);
        setSearchText(searchTerm);
    };

    return (
        <Container style={{ fontFamily: 'Lato' }}>
            <div className="row">
                {countries
                    .sort((a, b) => a.name.localeCompare(b.name)) // Trie les pays par leur nom
                    .map((country) => (
                        <div
                            key={country.id}
                            className="col-lg-4 col-md-3 col-xs-6 position-relative mb-3"
                        >
                            <Link to={`/WhereWeProvideService/${country.name}`}>
                                <img
                                    src={country.imageSrc}
                                    alt={country.name}
                                    className="w-100 h-100 rounded"
                                />
                                <h3 className="position-absolute bottom-0 start-0 w-100 text-white font-weight-bold mb-3" style={{ fontSize: '25px' }}>
                                    {country.name}
                                </h3>
                            </Link>
                        </div>
                    ))}
            </div>
        </Container>
    );

}