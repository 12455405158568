import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import AppFirebase from '../FirebaseConfig';
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signOut } from "firebase/auth";
import { doc, setDoc, getFirestore, deleteDoc } from "firebase/firestore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import "./../Navbar.css";
import { useTranslation } from 'react-i18next'


import { FaQuestionCircle, FaEye, FaEyeSlash } from 'react-icons/fa';

/*import axios from "axios";*/

function SignupModal() {

    const { t } = useTranslation();


    const RedTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgba(255, 100, 100, 0.85)',
            color: 'white',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    const navigate = useNavigate();

    const [error, setError] = useState("");
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [show, setShow] = useState(false);
    const [isLawFirm, setIsLawFirm] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const auth = getAuth();
    auth.useDeviceLanguage();
    //end firebase auth

    const handleCloseMailVerification = () => {

        setShowVerificationModal(false);
    }
    const handleClose = () => {
   
       
        setShow(false);
        resetState();
    };
    const handleShow = () => setShow(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [confirmPassword, setConfirmPassword] = useState('');
    const handleToggle = () => {
        setIsLawFirm(!isLawFirm);
    };

    const handleInputChange = (id, value) => {
        if (id === "firstName") {
            setFirstName(value);
        }
        if (id === "lastName") {
            setLastName(value);
        }
        if (id === "email") {
            setEmail(value);
        }
        if (id === "password") {
            setPassword(value);
        }
        if (id === 'confirmPassword') {
            setConfirmPassword(value);

        }
        if (id === "phoneNumber") {
            setPhoneNumber(value);
        }
    };

    const checkPasswordMatch = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            const error = new Error("Passwords do not match. Please check your passwords.");
            error.code = "auth/password-mismatch";
            throw error;
        }
    };

    const checkPasswordStrength = (password) => {
        // Implement your password strength checks here
        const minLength = 8;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

        if (password.length < minLength ||
            !uppercaseRegex.test(password) ||
            !lowercaseRegex.test(password) ||
            !digitRegex.test(password) ||
            !specialCharRegex.test(password)) {
            const error = new Error("Weak password. Please use a stronger password with a mix of uppercase and lowercase letters, numbers, and special characters.");
            error.code = "auth/weak-password";
            throw error;

        }
    };

    const resetState = () => {
        setEmail("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setConfirmPassword("");
        setPhoneNumber("");
        setValidated(false);
        setError("");
    };



    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {

            const additionalUserInfo = {
                displayName: `${firstName} ${lastName}`,
                // Add other properties as needed, photoURL
            };
            try {
                setLoading(true);
                // Check password strength
                checkPasswordStrength(password);

                // Check if both password match together
                checkPasswordMatch(password, confirmPassword);

                //console.log("Creating user");
                createUserWithEmailAndPassword(auth, email, password)
                    .then(async (response) => {
                        const user = response.user;

                        updateProfile(user, additionalUserInfo)
                            .then(() => {

                            })
                            .catch((error) => {
                                setError("An error occure while creating your profile.")
                            });
                        // console.log("Creating user Info");
                        const db = getFirestore(AppFirebase);
                        const data = {
                            additionalInfo: "",
                            addressLine1: "",
                            addressLine2: "",
                            billingEmailAddress: "",
                            city: "",
                            country: "",
                            emailAddress: email,
                            firstName: isLawFirm ? "" : firstName,
                            lastName: lastName,
                            industries: [],
                            maxHoursPerWeek: "",
                            minBudget: "",
                            phoneNumber: phoneNumber,
                            practiceAreas: [],
                            provideAServiceApplication: false,
                            profileLawyer: isLawFirm ? "Law Firm" : "Lawyer",
                            state: "",
                            verified: true,
                            workDuration: "",
                            workPlace: "",
                            workStart: "",
                            yearsExp: "",
                            zip: ""

                        };
                        await setDoc(doc(db, "userInfo", user.uid), data);

                        await setDoc(doc(db, "userInfo", user.uid, "provideAServiceQuery", "initializing"), {});
                        await deleteDoc(doc(db, "userInfo", user.uid, "provideAServiceQuery", "initializing"));

                        await setDoc(doc(db, "userInfo", user.uid, "findALawyerQuery", "initializing"), {});
                        await deleteDoc(doc(db, "userInfo", user.uid, "findALawyerQuery", "initializing"));

                        handleClose();

                        auth.useDeviceLanguage();
                        sendEmailVerification(auth.currentUser)
                            .then(() => {
                                setLoading(false);
                                navigate("/MailVerification");

                                // Sign out user if not verified
                                signOut(auth).then(() => {
                                    // Sign-out successful.
                                }).catch((error) => {
                                    // An error happened.
                                });
                                // setShowVerificationModal(true);
                                setLoading(false);

                            })
                            .catch((error) => {
                                signOut(auth).then(() => {
                                    // Sign-out successful.
                                }).catch((error) => {
                                    // An error happened.
                                });
                                // Handle any errors that occur during user creation
                                // setError(error.message);
                                setError("An error occurred. No verification mail has been sent.")
                                setLoading(false);
                                // console.log(error.message);
                            });
                        setLoading(false);
                        signOut(auth).then(() => {
                            // Sign-out successful.
                        }).catch((error) => {
                            // An error happened.
                        });
                        resetState();

                    })
                    .catch((error) => {
                        // Handle any errors that occur during user creation
                        // Handle user creation errors
                        setLoading(false);
                        switch (error.code) {
                            case "auth/weak-password":
                                setError("Weak Password.");
                                break;
                            case "auth/password-mismatch":
                                setError("Passwords do not match. Please check your passwords.");
                                break;
                            case "auth/email-already-in-use":
                                setError("The provided email is already in use by an existing user.");
                                break;
                            case "auth/invalid-email":
                                setError("The provided email is invalid.")
                                break;
                            case "auth/invalid-password":
                                setError("The provided password password is invalid. It must be a string with at least six characters.")
                                break;
                            default:
                                setError("An error occurred. Please try again later.");
                                break;

                        }
                    });
            } catch (error) {
                setLoading(false)
                setError(error.message);
                //console.log(error.message);
            }
        }

    };

    return (
        <>
            <Button variant="transparent" onClick={handleShow} className="custom-button">
                <p style={{ fontSize: '18px', fontWeight: 'bold', color: "white" }}>{t("pages.registerComponent.register.title")}</p>
            </Button>

            <Modal show={showVerificationModal} onHide={handleCloseMailVerification}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("pages.registerComponent.verificationEmail.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t("pages.registerComponent.verificationEmail.body")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMailVerification}>
                        {t("pages.registerComponent.verificationEmail.closeButton")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:"75px"  }} closeButton>
                    <Modal.Title >{t("pages.registerComponent.register.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} noValidate validated={validated} action="">
                        <Form.Group className="text-start mb-4 mt-2">
                            <Form.Check
                                type="checkbox"
                                id="custom-checkbox"
                                label={t("pages.registerComponent.register.registerAsCompany")}
                                onChange={handleToggle}
                                checked={isLawFirm}
                            />
                        </Form.Group>

                        {!isLawFirm && (
                            <Form.Group className="mb-3">
                                <Form.Label>{t("pages.registerComponent.register.firstName.label")} <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder={t("pages.registerComponent.register.firstName.placeholder")}
                                    value={firstName}
                                    onChange={(e) => handleInputChange("firstName", e.target.value)}
                                    autoFocus={!isLawFirm}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.registerComponent.errors.firstName")}
                                </Form.Control.Feedback>

                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>{isLawFirm ? t("pages.registerComponent.register.companyName.label") : t("pages.registerComponent.register.lastName.label")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                value={lastName}
                                placeholder={isLawFirm ? t("pages.registerComponent.register.companyName.placeholder") : t("pages.registerComponent.register.lastName.placeholder")}
                                onChange={(e) => handleInputChange("lastName", e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {isLawFirm ? t("pages.registerComponent.errors.companyName") : t("pages.registerComponent.errors.lastName")}
                            </Form.Control.Feedback>

                        </Form.Group>

                        <Form.Group className="mb-3">

                            <Form.Label>{t("pages.registerComponent.register.email.label")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                placeholder={t("pages.registerComponent.register.email.placeholder")}
                                onChange={(e) => handleInputChange("email", e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("pages.registerComponent.errors.email")}
                            </Form.Control.Feedback>
                        </Form.Group>




                        <Form.Group className="mb-3" style={{ width: '100%' }}>
                            <Form.Label>{t("pages.registerComponent.register.phone.label")}</Form.Label>
                            <PhoneInput
                                required
                                className="number"
                                country={"bh"}
                                value={phoneNumber}
                                placeholder={t("pages.registerComponent.register.phone.placeholder")}
                                onChange={(e) => handleInputChange("phoneNumber", e)}
                                inputStyle={{ width: '100%' }}
                                countryCodeEditable={false}
                                autoFormat={true}
                                enableSearch={true}
                                disableSearchIcon={true}
                                preferredCountries={['lb', 'sa', 'ae', 'bh', 'kw', 'om', 'qa', 'jo']}
                                excludeCountries={['il']}
                                alwaysDefaultMask={true}
                            />
                        </Form.Group>

                        <div className="d-flex align-items-center"> {/* Use flexbox to align items vertically */}
                            <div className="flex-grow-1"> {/* Use flex-grow-1 to make the Form.Control take most of the space */}
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("pages.registerComponent.register.password.label")} <span style={{ color: 'red' }}>*</span>
                                        <RedTooltip title={t("pages.registerComponent.register.tooltip")} className="ml-2"> {/* Add ml-2 for spacing */}
                                            <Button variant="transparent" style={{ padding: 0 }}>
                                                <FaQuestionCircle />
                                            </Button>
                                        </RedTooltip>
                                    </Form.Label>
                                    <div className="d-flex align-items-center"> {/* Nested flexbox container to align items vertically */}
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => handleInputChange("password", e.target.value)}
                                            placeholder={t("pages.registerComponent.register.password.placeholder")}
                                            required
                                        />
                                        <Button variant="transparent" onClick={() => setShowPassword(!showPassword)}> {/* Toggle show/hide password */}
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>

                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {t("pages.registerComponent.errors.password")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="d-flex align-items-center"> {/* Use flexbox to align items vertically */}
                            <div className="flex-grow-1"> {/* Use flex-grow-1 to make the Form.Control take most of the space */}
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("pages.registerComponent.register.confirmPassword.label")} <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <div className="d-flex align-items-center"> {/* Nested flexbox container to align items vertically */}
                                        <Form.Control
                                            id="confirmPassword"
                                            type={showPassword ? "text" : "password"}
                                            value={confirmPassword}
                                            onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
                                            placeholder={t("pages.registerComponent.register.confirmPassword.placeholder")}
                                            required
                                        />

                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {t("pages.registerComponent.errors.confirmPassword")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>

                        <Form.Group className="mb-3" style={{ display: 'inline-flex' }}>

                                <Form.Check
                                    required
                                label={t("pages.registerComponent.register.terms")}
                                feedback={t("pages.registerComponent.register.termsFeedback")}
                                    feedbackType="invalid"
                                />
                                <span style={{ color: 'red', marginLeft: '5px' }}> *</span>
                           
                        </Form.Group>


                        {error !== "" && (
                            <Form.Group className="mb-3">
                                <Form.Label style={{ color: 'red' }}>{error}</Form.Label>
                            </Form.Group>
                        )}

                        <div className="text-end col-12">
                            <Button className="btn-sec" variant="outline-primary" type="submit" disabled={loading}>
                                {loading ? <Spinner animation="border" variant="primary" /> : t("pages.registerComponent.register.createButton") }
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>


            </Modal>


        </>

    );
}

export default SignupModal;