import MyProcessSteps from './ProcessSteps.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import MyFindALawyerButton from './FindALawyerButton.js';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MyFindALawyer = () => {
    const { t } = useTranslation();

    const [scrollPercentage, setScrollPercentage] = useState(0);
    const thresholdPercentage = 55;

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const bodyHeight = document.body.scrollHeight - windowHeight;
            const percentage = (scrollY / bodyHeight) * 100;
            setScrollPercentage(percentage);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Container style={{ fontFamily: 'Lato' }}>
            <Row className="mt-5 mb-5">
                <Col md={7} lg={7} className="order-md-2 d-flex align-items-center justify-content-center">
                    <img
                        className="img-fluid min-width-536 min-height-316 shadow rounded"
                        src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FReportAnalysis.jpg?alt=media&token=efb455b0-1402-42dd-9b1c-99a635bbb9ac"
                        alt="Secure Legal Support"
                    />
                </Col>
                <Col md={5} lg={5} className="order-md-1 d-flex align-items-center text-start">
                    <Stack className="mt-5" gap={1}>
                        <h3 className="display-5 mb-5">{t("pages.findALawyer.header.heading")}</h3>

                        <p className="text-muted lead me-5" style={{ textAlign: 'justify', lineHeight: "1.6" }}>
                            {t("pages.findALawyer.header.text1")}                        </p>
                        <p className="text-muted lead me-5" style={{ textAlign: 'justify', lineHeight: "1.6", fontStyle: "italic" }}>
                            {t("pages.findALawyer.header.text2")}                        </p>
                        <div className="mt-5" >
                            <MyFindALawyerButton />
                        </div>
                    </Stack>
                </Col>


            </Row>


            <Row className="mb-5" style={{ marginTop: "100px" }}>
                <Col >
                    <Stack gap={3}>
                        <div className='text-center' style={{ marginBottom: '30px' }}  >
                            <h1>{t("pages.findALawyer.header.text3")}</h1>
                        </div>
                        <div >
                            <MyProcessSteps />
                        </div>
                    </Stack>
                </Col>
            </Row>


            <Row className="justify-content-center">
                <Col md={8}>
                    {scrollPercentage > thresholdPercentage && (
                        <div className="position-fixed bottom-0 start-50 translate-middle-x mb-3 rounded" style={{ backgroundColor: '#FFF5', color: "aliceblue" }}>
                            <MyFindALawyerButton />
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default MyFindALawyer;