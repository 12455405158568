import { Route, Routes } from "react-router-dom";
import AdminPage from './AdminPage';
import AdminPageOperations from './AdminPageOperations';
import AdminWelcome from './AdminWelcome';
import AdminPageInvoice from './AdminPageInvoice';


const MyAdminPageRoutes = () => {
    return (
        <Routes>
            <Route path='/AdminWelcome' element={<AdminWelcome/>}/>
            <Route path='/Admin' element={<AdminPage />} />
            <Route path='/AdminOperations' element={<AdminPageOperations />} />
            <Route path='/AdminPageInvoice' element={<AdminPageInvoice /> } />
        </Routes>
    );
};

export default MyAdminPageRoutes;