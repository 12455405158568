import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom if you're using it for routing
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function MyConfirmationApplication() {
    const { t } = useTranslation();

    return (
        <Container style={{ marginTop: "100px", minHeight: "400px" }}>
            <h1>{t("pages.provideAServiceLoggedIn.confirmationApplication.title")}</h1>

            <p>{t("pages.provideAServiceLoggedIn.confirmationApplication.text1")}</p>

            <p>{t("pages.provideAServiceLoggedIn.confirmationApplication.text2")}</p>

            <Link to="/"><Button size='lg' variant="outline-primary">{t("pages.provideAServiceLoggedIn.confirmationApplication.backButton")}</Button></Link> {/* Link to navigate back to the main page */}
        </Container>
    );
}

export default MyConfirmationApplication;
