import { Route, Routes } from "react-router-dom";
import MyWhereWeProvideService from './WhereWeProvideService.js';
import MyCountryInformation from './CountryInformation.js'
const MyWhereWeProvideServiceRoutes = () => {
    return (
        <Routes>
            <Route index element={<MyWhereWeProvideService />} />
            <Route path=":id" element={<MyCountryInformation />} />
        </Routes>
    );
};

export default MyWhereWeProvideServiceRoutes;