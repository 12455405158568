import React from 'react';
import { Link } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next'


const MyNotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center', fontFamily: 'Lato' }}>
            <h1 style={{ fontSize: '4em', fontWeight: 'bold', marginBottom: '20px' }}>{t("pages.notFound.title")}</h1>
            <hr style={{ width: '50%', margin: '20px 0' }} />
            <p style={{ fontSize: '1.5em', marginBottom: '50px' }}>{t("pages.notFound.text1")}</p>
            <Link to="/" style={{ fontSize: '1.2em', textDecoration: 'none', color: 'blue' }}>{t("pages.notFound.button")}</Link>
        </div>
    );
};

export default MyNotFoundPage;
