import Accordion from 'react-bootstrap/Accordion';
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from 'react-i18next';


function MyIndividualAccordion() {
    const { t } = useTranslation();

    return (
        <Accordion defaultActiveKey={['0']} alwaysOpen className="d-flex flex-column" style={{ fontFamily: 'Lato' }}>
                <Accordion.Item eventKey="0">
                <Accordion.Header style={{ marginTop: '15px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{t("pages.whoWeHelp.individuals.content1.header")}</p>
                </Accordion.Header>
                <Accordion.Body>
                    <Stack gap={3}>
                        <div className="mb-2"><p style={{ fontStyle: 'italic' }}>{t("pages.whoWeHelp.individuals.content1.description")}</p></div>
                        <div className="mb-2">
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content1.advantage.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content1.advantage.text")}</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content1.useCase.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content1.useCase.text")}</p>
                        </div>
                    </Stack>
                </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                <Accordion.Header style={{ marginTop: '15px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{t("pages.whoWeHelp.individuals.content2.header")}</p>
                </Accordion.Header>
                <Accordion.Body>
                    <Stack gap={3}>
                        <div className="mb-2"><p style={{ fontStyle: 'italic' }}>{t("pages.whoWeHelp.individuals.content2.description")}</p></div>
                        <div className="mb-2">
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content2.advantage.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content2.advantage.text")}</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content2.useCase.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content2.useCase.text")}</p>
                        </div>
                    </Stack>
                    </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header style={{ marginTop: '15px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{t("pages.whoWeHelp.individuals.content3.header")}</p>
                </Accordion.Header>
                <Accordion.Body>
                    <Stack gap={3}>
                        <div className="mb-2"><p style={{ fontStyle: 'italic' }}>{t("pages.whoWeHelp.individuals.content3.description")}</p></div>
                        <div className="mb-2">
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content3.advantage.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content3.advantage.text")}</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content3.useCase.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content3.useCase.text")}</p>
                        </div>
                    </Stack>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header style={{ marginTop: '15px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{t("pages.whoWeHelp.individuals.content4.header")}</p></Accordion.Header>
                <Accordion.Body>
                    <Stack gap={3}>
                        <div className="mb-2"><p style={{ fontStyle: 'italic' }}>{t("pages.whoWeHelp.individuals.content4.description")}</p></div>
                        <div className="mb-2">
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content4.advantage.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content4.advantage.text")}</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("pages.whoWeHelp.individuals.content4.useCase.title")}</p>
                            <p style={{ textAlign: 'justify' }}>{t("pages.whoWeHelp.individuals.content4.useCase.text")}</p>
                        </div>
                    </Stack>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
    );

}

export default MyIndividualAccordion;