import { Route, Routes } from "react-router-dom";
import TermsOfUse from './TermsOfUse.js';
import CookiesNotice from "./CookiesNotice.js";
import PrivacyNotice from "./PrivacyNotice.js";


const TermsRoutes = () => {
    return (
        <Routes>
            <Route index path="/TermsOfUse" element={<TermsOfUse />} />
            <Route index path="/CookiesNotice" element={<CookiesNotice />} />
            <Route index path="/PrivacyNotice" element={<PrivacyNotice />} />
        </Routes>
    );
};

export default TermsRoutes;