import React from 'react';
import { Col, Card } from 'react-bootstrap';

const InfoCard = ({ title, content }) => {
    const cardStyle = {
        backgroundColor: 'rgb(13, 110, 253)',
        borderRadius: '20px',
        boxShadow: '0px 8px 18px 0 rgba(0,0,0,0.14)',
        transition: 'transform 0.3s, box-shadow 0.3s',
    };


    return (
            <Card
                style={{ ...cardStyle }}
                onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; e.currentTarget.style.boxShadow = '0px 15px 30px 0 rgba(0,0,0,0.25)'; }}
                onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; e.currentTarget.style.boxShadow = '0px 8px 18px 0 rgba(0,0,0,0.14)'; }}
            >
            <Card.Body className="d-flex flex-column">
                <div style={{ minHeight: '32%', paddingBottom: '10px' }}>
                    <Card.Title className="text-start fw-bold d-flex align-items-start" style={{ fontSize: '22px', color: 'white' }}>
                        {title}
                    </Card.Title>
                </div>
                <div className="flex-grow-1">
                    <Card.Text className="d-flex text-start" style={{ textAlign: 'justify', fontSize: '18px', color: 'white' }}>
                        {content}
                    </Card.Text>
                </div>
            </Card.Body>



            </Card>
    );
}

export default InfoCard;
