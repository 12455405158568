import { Route, Routes } from "react-router-dom";
import MyProvideAService from './ProvideAService'

const MyProvideAServiceRoutes = () => {
    return (
        <Routes>
            <Route index element={<MyProvideAService/>} />

        </Routes>
    );
};

export default MyProvideAServiceRoutes;