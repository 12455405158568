import { Container, Row, Col, Carousel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import "./HomepageDesign.css";
import { useTranslation } from 'react-i18next'


function MyCarouselCountriesHomepage() {

    const { t } = useTranslation();

    return (
        <Container fluid style={{ borderRadius: "10px", fontFamily: 'Lato, sans-serif' }}>
            <Row className="mb-4">
                <h1>{t("pages.homepage.carouselCountriesHomepage.access")}</h1>
            </Row>
            <Row className="mb-5">

                <Link to={'/WhereWeProvideService'}>
                    <Button variant="outline-primary" size="lg"><p className="mb-2 mt-2" style={{ fontSize: "20px" }}>{t("pages.homepage.carouselCountriesHomepage.chooseJurisdiction")}</p></Button>
                </Link>

            </Row>
            <Row>
                <Col md={1}></Col>
                <Col md={10}>
                <Carousel fade>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FDubaiLandscape2.jpg?alt=media&token=99c5c006-be71-4f7e-a792-acb15dd6fc05"
                            alt="United Arab Emirates"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.unitedArabEmirates")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FQatar.jpg?alt=media&token=3da34bca-0611-489e-b618-908cf93bde8e"
                            alt="Qatar"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.qatar")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FBeirut.jpg?alt=media&token=358f6b0b-04ed-4098-934c-7945cbd3e010"
                            alt="Lebanon"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.lebanon")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FRiyad.jpg?alt=media&token=a59b5666-75fa-48fc-9c86-9732203e8e7d"
                            alt="Saudi Arabia"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.saudiArabia")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FKuwait.jpg?alt=media&token=eef21db1-78fb-4427-ac8a-cbc49dab449f"
                            alt="Kuwait"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.kuwait")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FMascate.jpg?alt=media&token=a181cc0b-9ccc-438b-952b-488c5ec0fe52"
                            alt="Oman"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.oman")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>


                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FJordan.jpg?alt=media&token=9474e7ec-f68c-4555-bc77-474d30e73590"
                            alt="Jordan"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.jordan")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>


                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FBahrein.jpg?alt=media&token=fa0b00d6-3e10-49c5-9942-684b69afc5c7"
                            alt="Bahrain"
                                style={{ height: "500px", borderRadius: "10px", objectFit: 'cover' }}
                        />
                        <Carousel.Caption>
                                <h2>{t("pages.homepage.carouselCountriesHomepage.bahrain")}</h2>
                        </Carousel.Caption>
                    </Carousel.Item>
                    </Carousel>
                </Col>
                <Col md={1}></Col>
            </Row>
            </Container>

    );
}

export default MyCarouselCountriesHomepage;