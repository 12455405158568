import { Route, Routes } from "react-router-dom";
import MyAboutUs from './AboutUs'

const MyAboutUsRoutes = () => {
    return (
        <Routes>
            <Route index element={<MyAboutUs />} />
            
        </Routes>
    );
};

export default MyAboutUsRoutes;