import React from 'react';
import { Row, Col } from "react-bootstrap";
import "./StyleSheet.css";

const HorizontalCard = ({ imageUrl, title, info, icon: Icon }) => {
    const cardStyle = {
        borderRadius: '10px',
        overflow: 'hidden',
        backgroundColor: 'transparent',
    };

    const imageStyle = {
        width: '100%',
        objectFit: 'cover',
        height: '350px',
    };

    const infoStyle = {
        fontSize: '15px',
        color: '#333',
        lineHeight: '1.6',
        margin: '0 40px',
        textAlign: 'justify',
    };

    const iconContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
    };

    const titleContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
    };

    return (
        <div className="horizontal-card" style={cardStyle}>
            <Row className="align-items-center">
                <Col xs={12} md={6} className="p-0">
                    <div className="image-container">
                        <img src={imageUrl} alt="Card" className="card-image" style={imageStyle} />
                    </div>
                </Col>
                <Col xs={12} md={6} className="p-3">
                    <div className="icon-container" style={iconContainerStyle}>
                        {Icon && <Icon size={100} color="rgb(13, 110, 253)" />}
                    </div>
                    <div className="title-container" style={titleContainerStyle}>
                        <h2 className="card-title-horizontal ml-2">{title}</h2>
                    </div>
                    <div className="content-container">
                        <p className="card-info" style={infoStyle}>{info}</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default HorizontalCard;
