import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const VerificationPage = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const returnHome = () => {
        navigate("/");
    }

    return ( 
        <Container style={{ maxWidth: '600px', marginTop: "100px", marginBottom: "100px", margin: '20px auto', padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', fontFamily: 'Lato' }}>
            <Row >
                <Col>
                    <h1 style={{ color: '#007bff' }}>{t("pages.verificationPage.title")}</h1>

                    <p>{t("pages.verificationPage.text1")}</p>
                    <hr />
                    <p className="mt-1 mb-1">{t("pages.verificationPage.text2")}</p>

                    <p className="mt-1 mb-2"> <strong>Important:</strong>{t("pages.verificationPage.text3")}</p>

                    <Button onClick={returnHome} variant="primary" style={{ marginTop: '10px' }}>{t("pages.verificationPage.button")}</Button>
                </Col>
            </Row>
        </Container>
    )   
}

export default VerificationPage;
