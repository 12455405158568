import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import MyContactUs from '../../ContactUs/ContactUs.js';

import { useTranslation } from 'react-i18next';

const MyPageDevelopment = () => {

    
    const { t } = useTranslation();

    return (
        <Container style={{ fontFamily: 'Lato'}}>
            <Row className="mt-5 mb-5">
                <Col>
                    <h1>{t("pages.pageDevelopment.title")}</h1>
                </Col>
            </Row>
            <Row className="mt-5 mb-5">
                <Col>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FWorking.png?alt=media&token=5ae39526-780a-480f-85d3-9fd6b600695d&_gl=1*zvlorf*_ga*MTExOTUzNTA4Mi4xNjg1NjUxNTQw*_ga_CW55HF8NVT*MTY5OTE5Njg1Ni4xMTYuMS4xNjk5MjAwMDc2LjQ3LjAuMA.."
                        width="200"
                        height="200"
                        alt="Page under development"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <h3 >{t("pages.pageDevelopment.text1")}</h3>
            </Row>
            <Row className="mt-5 mb-5">
                <Col>
                    <Stack>
                        <h3>{t("pages.pageDevelopment.text2")}</h3>
                    </Stack>
                </Col>
            </Row>
            <hr /> 
            <Row>
                <MyContactUs />
            </Row>
        </Container>
    );
};

export default MyPageDevelopment;