import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import InfoCard from "../../Components/InfoCard.js"
import { useTranslation } from 'react-i18next';


const MyOurMission = () => {

    const { t } = useTranslation();

    return (
        <Container className="text-left w-100 border-0" style={{ fontFamily: 'Lato' }}>
           
            <Row className="text-center mt-5 mb-5" style={{ color: 'black' }} >
                <Col>
                    <h2 style={{ fontSize: '25px' }}>{t("pages.aboutUs.ourMission.title")}</h2>
                </Col>
            </Row>

            <Row className="d-flex mt-3 mb-3">
                <Col className="justify-content-center mt-3 mb-3 d-flex" md={6} lg={3}>
                    <InfoCard
                        title={t("pages.aboutUs.ourMission.box1.title")}
                        content={t("pages.aboutUs.ourMission.box1.content")}
                    />
                </Col>
                <Col className="justify-content-center mt-3 mb-3 d-flex" md={6} lg={3}>
                    <InfoCard
                        title={t("pages.aboutUs.ourMission.box2.title")}
                        content={t("pages.aboutUs.ourMission.box2.content")}
                    />
                </Col>
                <Col className="justify-content-center mt-3 mb-3 d-flex" md={6} lg={3}>
                    <InfoCard
                        title={t("pages.aboutUs.ourMission.box3.title")}
                        content={t("pages.aboutUs.ourMission.box3.content")}
                    />
                </Col>
                <Col className="justify-content-center mt-3 mb-3 d-flex" md={6} lg={3}>
                    <InfoCard
                        title={t("pages.aboutUs.ourMission.box4.title")}
                        content={t("pages.aboutUs.ourMission.box4.content")}
                    />
                </Col>
            </Row>
            <Row className="mt-5 mb-5">
            </Row>
            
        </Container>
    );
};
export default MyOurMission;
