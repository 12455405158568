import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Stack} from 'react-bootstrap';
import Select from 'react-select';
import AppFirebase from '../../App.js';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';


function MyQuestionTimeFrame({ handleSelectedTimeFrame, savedInput }) {

    const { t } = useTranslation();

    const [optionsWorkStart, setOptionsWorkStart] = useState([]);
    const [optionsWorkTime, setOptionsWorkTime] = useState([]);
    const [optionsWorkHoursWeek, setOptionsWorkHoursWeek] = useState([]);

    const db = getFirestore(AppFirebase);

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);


            setOptionsWorkStart(docSnap.data().optionsWorkStart);
            setOptionsWorkTime(docSnap.data().optionsDuration);
            setOptionsWorkHoursWeek(docSnap.data().optionsWorkHoursWeek);
        };

        fetchData();
    }, []);

    const [selectedOption, setSelectedOption] = useState(savedInput);

    const handleChange = (input, field) => {
        if (field === "workStart") {
            const tempObject = { ...selectedOption };
            tempObject.workStart = input;
            setSelectedOption(tempObject);
        } else if (field === "workTime") {
            const tempObject = { ...selectedOption };
            tempObject.workTime = input;
            setSelectedOption(tempObject);
        } else if (field === "workHoursWeek") {
            const tempObject = { ...selectedOption };
            tempObject.workHoursWeek = input;
            setSelectedOption(tempObject);
        } else {
            //Nothing
        }
        handleSelectedTimeFrame(selectedOption);
    };

    useEffect(()=>handleSelectedTimeFrame(selectedOption), [selectedOption]);

    return (
        <Container>
            <Row className="mb-5">
                <Col>
                    <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.questionTimeFrame.title")} <span style={{ color: 'red' }}>*</span></h1>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col></Col>
                <Col sm={10}>
                    <Stack gap={1}>
                        <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionTimeFrame.legalSupportStart")} <span style={{ color: 'red' }}>*</span></h5>
                        <div className="flex-grow-1">
                            <Select
                                defaultValue={selectedOption.workStart}
                                onChange={(input) => handleChange(input, 'workStart')}
                                options={optionsWorkStart}
                            />
                        </div>
                        </Stack>
                </Col>
                <Col></Col>
            </Row>
            <Row className="mb-3">
                <Col></Col>
                <Col sm={10}>
                <Stack gap={1}>
                        <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionTimeFrame.legalSupportLast")} <span style={{ color: 'red' }}>*</span></h5>
                    <div className="flex-grow-1">
                        <Select
                            defaultValue={selectedOption.workTime}
                            onChange={(input) => handleChange(input, 'workTime')}
                            options={optionsWorkTime}
                        />
                    </div>
                    </Stack>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};

export default MyQuestionTimeFrame;