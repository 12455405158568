import LoginModal from './LoggedOut/LogInModal';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import './Navbar.css';
import MyLoggedInUserMenu from './LoggedIn/LoggedInHomepage/LoggedInUserMenu';
import { getAuth } from "firebase/auth";
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignupModal from './LoggedOut/SignUpModal';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'


function MyNavbar() {

    //const user = useContext(UserContext);
    const auth = getAuth();
    const user = auth.currentUser;

    const { t } = useTranslation();

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;
        setVisible(visible);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    return (
        <Navbar className={`navbar ${visible ? 'visible' : 'hidden'}`} variant="light" expand="lg">
            <Container className="nav-container" fluid>
                <Navbar.Brand style={{ color: '#f8f9fa', fontSize: '25px', fontWeight: 'bold' }} className="" color='#f8f9fa' href="/">
                    LEXREACH</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-4 my-lg-0" style={{ maxHeight: '100px', color: '#f8f9fa' }} navbarScroll>
                        <NavLink to="/FindALawyer" className="ms-5"><p style={{ fontSize: '18px' }} className="links">{t("navbar.secureLegalSupport")}</p></NavLink>
                        <NavLink to="/ProvideAService" className="ms-5"><p style={{ fontSize: '18px' }} className="links">{t("navbar.provideLegalSupport")}</p></NavLink>
                        <NavLink to="/AboutUs" className="ms-5"><p style={{ fontSize: '18px' }} className="links">{t("navbar.aboutUs")}</p></NavLink>
                        <NavLink to="/ContactUs" className="ms-5"><p style={{ fontSize: '18px' }} className="links">{t("navbar.contactUs")}</p></NavLink>
                    </Nav>
                    <Container className="d-flex justify-content-end h-100">
                        {user && user.emailVerified ? (
                            <MyLoggedInUserMenu />
                        ) : (
                                <>
                                    <div >
                                        <SignupModal />
                                    </div>
                                    <div className="vertical-rule-nav">
                                        <LoginModal />
                                    </div>
                                </>
                        )}
                    </Container>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}


export default MyNavbar;