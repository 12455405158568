import MyUserInformation from './UserInformation';
import MyProvideAServiceDocuments from './ProvideAServiceDocuments';
import ProvideAServiceInformation from './ProvideAServiceInformation';
import MyUserHistory from './UserHistory';
import { Row, Col, Container, Image, OverlayTrigger, Tooltip, Card, Spinner } from 'react-bootstrap';
import { useState, useContext, useRef, useEffect } from 'react';
import { UserContext } from '../../App';
import { updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import AppFirebase from '../../App.js';
import { useTranslation } from 'react-i18next';


const MyAccountInformation = () => {

    const { t } = useTranslation();

    const storage = getStorage();
    const db = getFirestore(AppFirebase);

    const user = useContext(UserContext);
    const [userProfile, setUserProfile] = useState('');

    const [imageURL, setImageURL] = useState(user?.photoURL || null);
    const fileInputRef = useRef(null);

    const MAX_FILE_SIZE = 2 * 1024 * 1024;  // 5MB, adjust as needed

    const validateFile = (file) => {
        // Validate file type (example: allow only PDF files)
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
            alert(t("pages.accountInformation.alerts.alert1"));
            return false;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            alert(t("pages.accountInformation.alerts.alert2"));
            return false;
        }

        return true;
    };


    const handleFileChange = (event) => {
        const files = event.target.files;

        // Check if files array is empty
        if (!files || files.length === 0) {
            return;
        }

        const file = event.target.files[0];
        // Check if the file object is undefined
        if (!file) {
            console.error('File is undefined.');
            return;
        }
        if (!validateFile(file)) {
            return;
        } else {
            const reader = new FileReader();

            reader.onloadend = async () => {
                // Read the image data as a base64-encoded data URL

                setImageURL(reader.result);

                try {
                    // Upload the image to Firebase Storage
                    const storageRef = ref(storage, `users/${user.uid}/profilePicture`);
                    const snapshot = await uploadBytes(storageRef, file);

                    // Update the user's photoURL in Firebase Authentication
                    const photoURL = await getDownloadURL(snapshot.ref);
                    await updateProfile(user, { photoURL });

                    setImageURL(photoURL);

                    // The user's profile picture has been updated successfully
                    console.log('Profile picture updated.');
                } catch (error) {
                    console.error('Error updating profile picture:', error.message);
                    // Handle any error that may occur while updating the profile picture
                }
            };


            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };
    const handleImageClick = () => {
        fileInputRef.current.click();
    };
    const tooltip = <Tooltip id="tooltip">{t("pages.accountInformation.profilePic")}</Tooltip>;


    const retrieveProfile = async () => {
        try {
            if (!user) {
                //console.log("User is not defined in AccountInformation");
                return;
            }
            const userRef = doc(db, 'userInfo', user.uid);
            const docSnap = await getDoc(userRef);
            setUserProfile(docSnap.data().profileLawyer);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        retrieveProfile();
        //console.log(userProfile);
    }, [user]);

    return (
        user ? (
            <Container>
                <Row className='mb-5 mt-5 '>
                    <Col md={9}>
                        <MyUserInformation userProfile={userProfile} />
                    </Col>
                    <Col md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <OverlayTrigger placement="top" overlay={tooltip}>

                            <Image
                                src={imageURL ? imageURL : (user?.photoURL || 'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg')}
                                alt="User Image"
                                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                roundedCircle
                                fluid
                                onClick={handleImageClick}
                            />


                        </OverlayTrigger>
                        <input
                            type="file"
                            id="imageInput"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </Col>
                </Row>

                <Row className='mb-5 mt-5'>
                    <Col>
                        <MyUserHistory />
                    </Col>
                </Row>

                <Row className='mb-5 mt-5'>
                    <Col md={6} className="mt-3 mb-3">
                        <ProvideAServiceInformation userProfile={userProfile} />
                    </Col>
                    <Col md={6} className="mt-3 mb-3">
                        <MyProvideAServiceDocuments userProfile={userProfile} />
                    </Col>
                </Row>
            </Container>
        ) : (
            <Card className="d-flex align-items-center justify-content-center p-5">
                <Spinner animation="border" variant="primary" size="lg" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <Card.Text className="mt-3">Loading...</Card.Text>
            </Card>

        )
    );
};

export default MyAccountInformation;