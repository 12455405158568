import React from 'react';
import './TermsOfUse.css'; // Ensure you have a CSS file for styling.

const PrivacyNotice = () => {
    return (
        <div className="terms-container">
            <header className="terms-header">
                <h1>Privacy Notice</h1>
                <p className="date">Last updated: August 28, 2024</p>
            </header>

            <section className="terms-content">

            </section>

            <footer className="terms-footer">
                <p>&copy; 2024 Lexreach. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default PrivacyNotice;
