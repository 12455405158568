import React from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import MyProvideAServiceProcessSteps from './ProvideAServiceProcessSteps.js';
import MyProvideAServiceButton from './ProvideAServiceButton';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MyProvideAService = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const thresholdPercentage = 25;
    const { t } = useTranslation();


    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const bodyHeight = document.body.scrollHeight - windowHeight;
            const percentage = (scrollY / bodyHeight) * 100;
            setScrollPercentage(percentage);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Container style={{ fontFamily: 'Lato' }}>
            <Row className="mt-5 mb-5" >
                <Col md={7} lg={7} className="order-md-2  d-flex align-items-center justify-content-center">
                    <img
                        className="img-fluid min-width-536 min-height-280 shadow rounded"
                        src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FArabsMen2.jpg?alt=media&token=24a05e9e-a0c7-4d5e-8a48-4869e7f74af3"
                        alt="Provide Legal Support"
                    />
                </Col>
                <Col md={5} lg={5} className="order-md-1 d-flex align-items-center">
                    <Stack className="mt-5" gap={1}>
                        <h3 className="text-start display-5 mb-5">{t("pages.provideAService.title")}</h3>
                        <p className="text-muted lead me-5" style={{ textAlign: 'justify', lineHeight: "1.6" }}>
                            {t("pages.provideAService.text1")}
                        </p>
                        <p className="text-muted lead me-5" style={{ textAlign: 'justify', lineHeight: "1.6", fontStyle: "italic" }}>
                            {t("pages.provideAService.text2")}
                        </p>
                        <div className="mt-5" >
                            <MyProvideAServiceButton />
                        </div>
                    </Stack>
                </Col>
            </Row>
            <Row className="mb-5" style={{ marginTop: "100px" }}>
                <Col >
                    <Stack gap={3}>
                        <div className='text-center' style={{ marginBottom: '30px' }}  >
                            <h1>
                                {t("pages.provideAService.text3")}
                            </h1>
                        </div>
                        <div >
                            <MyProvideAServiceProcessSteps />
                        </div>
                    </Stack>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={8}>
                    {scrollPercentage > thresholdPercentage && (
                        <div className="position-fixed bottom-0 start-50 translate-middle-x mb-3 rounded" style={{ backgroundColor: '#FFF5', color:"aliceblue" }}>
                            <MyProvideAServiceButton />
                        </div>
                    )}
                </Col>
            </Row>
            <Row className="mb-5 mt-5">
                <Col>
                 
                </Col>
            </Row>
        </Container>
    );
};

export default MyProvideAService;
