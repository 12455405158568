import { getStorage, ref, getMetadata, listAll } from "firebase/storage";
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { useState, useEffect, useContext } from 'react';
import AppFirebase from '../../App.js';
import { Container, Stack, Row, Col, Form, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { UserContext } from '../../App';
import PhoneInput from "react-phone-input-2";
import { useTranslation } from 'react-i18next';
function FormLawyer({ selectedProfile, handlePersonalInformationObject, handleRequiredInformationObject, handleResumeWithCredentials, handleResumeWithCredentialsMetadata, handleCopyLawLicense, handleCopyLawLicenseMetadata, handleCopyBarAdmission, handleCopyBarAdmissionMetadata }) {

    const { t } = useTranslation();

    const user = useContext(UserContext);
    const [alreadyExists, setAlreadyExists] = useState(false);

    const [resumeWithCredentialsMetadata, setResumeWithCredentialsMetadata] = useState(null);
    const [copyLawLicenseMetadata, setcopyLawLicenseMetadata] = useState(null);
    const [copyBarAdmissionMetadata, setCopyBarAdmissionMetadata] = useState(null);


    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsCity, setOptionsCity] = useState([]);
    const [optionsYearsExp, setOptionsYearsExp] = useState([]);
    const [optionsBudget, setOptionsBudget] = useState([]);

    const [optionsHourlyRate, setOptionsHourlyRate] = useState([]);

    const [optionsIndustries, setOptionsIndustries] = useState([]);
    const [optionsPracticeAreas, setOptionsPracticeAreas] = useState([]);
    const [optionsOnSiteRemote, setOptionsOnSiteRemote] = useState([]);
    const [optionsWorkStart, setOptionsWorkStart] = useState([]);
    const [optionsWorkTime, setOptionsWorkTime] = useState([]);
    const [optionsWorkHoursWeek, setOptionsWorkHoursWeek] = useState([]);
    const [docSnapStatic, setDocSnapStatic] = useState(null);
   

    const cityDisplay = (country, docSnap) => {
        //setOptionsCity([{ label: "", value: "" }]);
        if (docSnap !== null) {
            switch (country) {
                case "United Arab Emirates":
                    setOptionsCity(docSnap.data().optionsCityUAE);
                    break;
                case "Qatar":
                    setOptionsCity(docSnap.data().optionsCityQAT);
                    break;
                case "Lebanon":
                    setOptionsCity(docSnap.data().optionsCityLBN);
                    break;
                case "Saudi Arabia":
                    setOptionsCity(docSnap.data().optionsCityKSA);
                    break;
                case "Kuwait":
                    setOptionsCity(docSnap.data().optionsCityKWT);
                    break;
                case "Jordan":
                    setOptionsCity(docSnap.data().optionsCityJOR);
                    break;
                case "Oman":
                    setOptionsCity(docSnap.data().optionsCityOMN);
                    break;
                case "Bahrain":
                    setOptionsCity(docSnap.data().optionsCityBHR);
                    break;
                case "Other...":
                    setOptionsCity([{ label: "Other...", value: "Other..." }]);
                    break;
                default:
                    setOptionsCity([]);
            }
        }
    };


    const MAX_FILE_SIZE = 5 * 1024 * 1024;  // 5MB, adjust as needed

    const validateFile = (file) => {
        // Validate file type (example: allow only PDF files)
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        if (!allowedTypes.includes(file.type)) {
            alert(t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.alerts.alert1"));
            return false;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            alert(t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.alerts.alert2"));
            return false;
        }

        return true;
    };

    useEffect(() => {
        
        const fetchDocuments = () => {

            if (user !== null) {
                const storage = getStorage();

                const listRef = ref(storage, `users/${user.uid}`);
                listAll(listRef)
                    .then((res) => {
                        if (res.items.length > 0) {
                            res.items.forEach((item) => {
                                //console.log("result : ", res.items[0]._location.path_);
                                // Get metadata properties

                                let docPath = item._location.path_;
                                //console.log("test", docPath);
                                let docRef = ref(storage, docPath);
                                let pathArray = docPath.split("/");
                                // Pop the last element from the array
                                let lastElement = pathArray.pop();
                                //console.log(lastElement)
                                getMetadata(docRef)
                                    .then((metadata) => {
                                        if (lastElement === "resumeWithCredentials") {
                                            setResumeWithCredentialsMetadata(metadata);
                                        } else if (lastElement === "barAdmission") {
                                            setCopyBarAdmissionMetadata(metadata);
                                        } else if (lastElement === "lawLicense") {
                                            setcopyLawLicenseMetadata(metadata);
                                        } else {

                                        }

                                        //console.log(metadata);
                                    })
                                    .catch((error) => {
                                        // Uh-oh, an error occurred!
                                    });
                            });
                        }

                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });
            }

        };
        fetchDocuments();

    }, [user])

    useEffect(() => {
        const db = getFirestore(AppFirebase);
        const userRef = doc(db, 'userInfo', user.uid);
        const docRef = doc(db, "staticData", "staticDataV1");

        //console.log(selectedProfile);

        const fetchData = async () => {

            const docSnapTemp = await getDoc(docRef);
            setDocSnapStatic(docSnapTemp);

            const sortedOptionsCountry = docSnapTemp.data().optionsCountry;

            // Sort the copied array alphabetically based on the label property
            sortedOptionsCountry.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            });

            // Update state with the sorted array
            setOptionsCountry(sortedOptionsCountry);
            setOptionsYearsExp(docSnapTemp.data().optionsYearsExp.slice(0, docSnapTemp.data().optionsYearsExp.length - 1));
            setOptionsHourlyRate(selectedProfile === "Lawyer" ? docSnapTemp.data().optionsHourlyRateLawyer : docSnapTemp.data().optionsHourlyRateParalegal);
            setOptionsBudget(docSnapTemp.data().optionsBudget.slice(0, docSnapTemp.data().optionsBudget.length - 1));
            setOptionsIndustries(docSnapTemp.data().optionsIndustries);
            setOptionsPracticeAreas(docSnapTemp.data().optionsPracticeAreas);
            setOptionsOnSiteRemote(docSnapTemp.data().optionsOnSiteRemote);
            setOptionsWorkStart(docSnapTemp.data().optionsWorkStart);
            setOptionsWorkTime(docSnapTemp.data().optionsDuration.slice(0, docSnapTemp.data().optionsDuration.length - 1));
            setOptionsWorkHoursWeek(docSnapTemp.data().optionsWorkHoursWeek.slice(0, docSnapTemp.data().optionsWorkHoursWeek.length - 1));
            const userData = await getDoc(userRef);
            cityDisplay(userData.data().country, docSnapTemp);
        };
        fetchData();
        // eslint-disable-next-line
    }, []);


    
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#cfe2ff6e',
            // Add more custom styles as needed
        }),
    };

    useEffect(() => {
        // Function to fetch user data from Firestore
        const fetchUserData = async () => {
            try {
                const db = getFirestore(AppFirebase);
                const userRef = doc(db, 'userInfo', user.uid);
                const userData = await getDoc(userRef);
                if (userData.exists()) {
                    const data = userData.data();
                    
                    
                    // Update state variables with Firestore data
                    setPersonalInformationObject({
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        emailAddress: data.emailAddress || '',
                        billingEmailAddress: data.billingEmailAddress || '',
                        country: data.country || '',
                        state: data.state || '',
                        city: data.city || '',
                        zip: data.zip || '',
                        addressLine1: data.addressLine1 || '',
                        addressLine2: data.addressLine2 || '',
                        phoneNumber: data.phoneNumber || ''
                    });

                    setRequiredInformationObject({
                        workPlace: data.workPlace || '',
                        yearsExp: data.yearsExp || 0,
                        minBudget: data.minBudget || 0,
                        hourlyRate: data.hourlyRate || 0,
                        industries: data.industries || [],
                        practiceAreas: data.practiceAreas || [],
                        workStart: data.workStart || 0,
                        workDuration: data.workDuration || 0,
                        maxHoursPerWeek: data.maxHoursPerWeek || 0,
                        additionalInfo: data.additionalInfo || '',
                    });

                    setPersonalInformationObjectInit({
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        emailAddress: data.emailAddress || '',
                        billingEmailAddress: data.billingEmailAddress || '',
                        country: data.country || '',
                        state: data.state || '',
                        city: data.city || '',
                        zip: data.zip || '',
                        addressLine1: data.addressLine1 || '',
                        addressLine2: data.addressLine2 || '',
                        phoneNumber: data.phoneNumber || ''
                    });

                    setRequiredInformationObjectInit({
                        workPlace: data.workPlace || '',
                        yearsExp: data.yearsExp || 0,
                        minBudget: data.minBudget || 0,
                        hourlyRate: data.hourlyRate || 0,
                        industries: data.industries || [],
                        practiceAreas: data.practiceAreas || [],
                        workStart: data.workStart || 0,
                        workDuration: data.workDuration || 0,
                        maxHoursPerWeek: data.maxHoursPerWeek || 0,
                        additionalInfo: data.additionalInfo || '',
                    });


                    setAlreadyExists(true);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        // Call after mount
        fetchUserData();
    }, [user.uid]);


    const [personalInformationObject, setPersonalInformationObject] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        billingEmailAddress: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [requiredInformationObject, setRequiredInformationObject] = useState({
        workPlace: '',
        yearsExp: 0,
        minBudget: 0,
        hourlyRate:0,
        industries: [],
        practiceAreas: [],
        workStart: 0,
        workDuration: 0,
        maxHoursPerWeek: 0,
        additionalInfo: '',
    });

    const [personalInformationObjectInit, setPersonalInformationObjectInit] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        billingEmailAddress: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: ''
    });

    const [requiredInformationObjectInit, setRequiredInformationObjectInit] = useState({
        workPlace: '',
        yearsExp: 0,
        minBudget: 0,
        hourlyRate:0,
        industries: [],
        practiceAreas: [],
        workStart: 0,
        workDuration: 0,
        maxHoursPerWeek: 0,
        additionalInfo: '',
    });

    //documents fetching if available
    const [resumeWithCredentials, setResumeWithCredentials] = useState(null);
    const [copyLawLicense, setCopyLawLicense] = useState(null);
    const [copyBarAdmission, setCopyBarAdmission] = useState(null);

    useEffect(() => {
        // Function to fetch additional documents data from Firestore

        const fetchAdditionalDocumentsData = async () => {
            try {
                const db = getFirestore(AppFirebase);
                const userRef = doc(db, 'userInfo', user.uid);
                const userData = await getDoc(userRef);

                if (userData.exists()) {
                    const data = userData.data();

                    // Update la kell l variables
                    setResumeWithCredentials(data.resumeWithCredentials || null);
                    setCopyLawLicense(data.copyLawLicense || null);
                    setCopyBarAdmission(data.copyBarAdmission || null);
                }
            } catch (error) {
                console.error('Error fetching additional documents data:', error);
            }
        };

        // Call after mount
        fetchAdditionalDocumentsData();
    }, [user.uid]);



    const handlePersonalInfoChange = (field, input) => {
        if (field === 'phoneNumber') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.phoneNumber = input;
            setPersonalInformationObject(updatedObject);
        }
        else if (field === 'billingEmailAddress') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.billingEmailAddress = input.target.value;
            setPersonalInformationObject(updatedObject);
        }
        else if (field === 'country') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.country = input.value;
            updatedObject.city = "";
            setPersonalInformationObject(updatedObject);
        }
        else if (field === 'zip') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.zip = input.target.value;
            setPersonalInformationObject(updatedObject);
        }
        else if (field === 'state') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.state = input.target.value;
            setPersonalInformationObject(updatedObject);
        }
        else if (field === 'city') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.city = input.value;
            setPersonalInformationObject(updatedObject);
        }
        else if (field === 'addressLine1') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.addressLine1 = input.target.value;
            setPersonalInformationObject(updatedObject);
        }
        else if (field === 'addressLine2') {
            const updatedObject = { ...personalInformationObject };
            updatedObject.addressLine2 = input.target.value;
            setPersonalInformationObject(updatedObject);
        } else {
            //Nothing
        }
        //console.log(personalInformationObject);
        handlePersonalInformationObject(personalInformationObject);
    };

    useEffect(() => {
        handlePersonalInfoChange(personalInformationObject);
        // eslint-disable-next-line
    }, [personalInformationObject]);

    const handleRequiredInfoChange = (field, input) => {
        if (field === 'workPlace') {
            const updatedObject = { ...requiredInformationObject };
            updatedObject.workPlace = input.value;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'yearsExp') {
            const updatedObject = { ...requiredInformationObject };
            updatedObject.yearsExp = input.value;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'hourlyRate') {
            const updatedObject = { ...requiredInformationObject };
            updatedObject.hourlyRate = input.value;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'industries') {
            const updatedObject = { ...requiredInformationObject };
            const arrayOfStrings = input.map(map => map.value);
            updatedObject.industries = arrayOfStrings;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'practiceAreas') {
            const updatedObject = { ...requiredInformationObject };
            const arrayOfStrings = input.map(map => map.value);
            updatedObject.practiceAreas = arrayOfStrings;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'workStart') {
            const updatedObject = { ...requiredInformationObject };
            updatedObject.workStart = input.value;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'workDuration') {
            const updatedObject = { ...requiredInformationObject };
            updatedObject.workDuration = input.value;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'maxHoursPerWeek') {
            const updatedObject = { ...requiredInformationObject };
            updatedObject.maxHoursPerWeek = input.value;
            setRequiredInformationObject(updatedObject);
        }
        else if (field === 'additionalInfo') {
            const updatedObject = { ...requiredInformationObject };
            updatedObject.additionalInfo = input.target.value;
            setRequiredInformationObject(updatedObject);
        }
        else {
            //Nothing
        }
       
        handleRequiredInformationObject(requiredInformationObject);

    };

    useEffect(() => {
        handleRequiredInfoChange(requiredInformationObject)
        // eslint-disable-next-line
    }, [requiredInformationObject]);

    const handleAdditionalDocumentsChange = (field, input) => {

        if (field === 'resumeCredentials') {

            const files = input.target.files;

            // console.log(files)
            if (!validateFile(files[0])) {
                //console.log("Not validated")
                input.target.value = null;
                return;
            } else {
                //console.log("Validated")
                const metadata = {
                    contentType: files[0].type,  // Set content type based on the file type
                    customMetadata: {
                        personalName: files[0].name // Custom metadata property, adjust as needed
                    }
                };
                setResumeWithCredentials(files[0]);
                handleResumeWithCredentials(files[0]);
                handleResumeWithCredentialsMetadata(metadata);
            }
        }
        else if (field === 'lawLicense') {
            const files = input.target.files;
            if (!validateFile(files[0])) {
                input.target.value = null;
                return;
            } else {
                const metadata = {
                    contentType: files[0].type,  // Set content type based on the file type
                    customMetadata: {
                        personalName: files[0].name // Custom metadata property, adjust as needed
                    }
                };
                setCopyLawLicense(files[0]);
                handleCopyLawLicense(files[0]);
                handleCopyLawLicenseMetadata(metadata);
            }
        }
        else if (field === 'barAdmission') {
            const files = input.target.files;
            if (!validateFile(files[0])) {
                input.target.value = null;
                return;
            } else {
                const metadata = {
                    contentType: files[0].type,  // Set content type based on the file type
                    customMetadata: {
                        personalName: files[0].name // Custom metadata property, adjust as needed
                    }
                };

                setCopyBarAdmission(files[0]);
                handleCopyBarAdmission(files[0]);
                handleCopyBarAdmissionMetadata(metadata);
            }
        }
        else {
            //Nothing
        }
    };

    useEffect(() => {
        handleAdditionalDocumentsChange(resumeWithCredentials)
        // eslint-disable-next-line
    }, [resumeWithCredentials]);
    useEffect(() => {
        handleAdditionalDocumentsChange(copyLawLicense)
        // eslint-disable-next-line
    }, [copyLawLicense]);
    useEffect(() => {
        handleAdditionalDocumentsChange(copyBarAdmission)
        // eslint-disable-next-line
    }, [copyBarAdmission]);

    const personalInformation = () => {

        return (
            <Container>
                <Row className="mb-3" >
                    <Col xs={12} md={6}>
                        <Stack>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.firstNameLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                disabled
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.firstNamePlaceholder")}
                                defaultValue={personalInformationObject.firstName}
                            />
                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Stack>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.lastNameLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                disabled
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.lastNamePlaceholder")}
                                defaultValue={personalInformationObject.lastName}
                                className={`${alreadyExists && (personalInformationObjectInit.lastName ? personalInformationObjectInit.lastName.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                            />
                        </Stack>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12} md={4}>
                        <Stack>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.phoneNumberLabel")}</Form.Label>
                            <PhoneInput
                                required
                                country={"bh"}
                                className="number"
                                value={personalInformationObject.phoneNumber}
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.phoneNumberPlaceholder")}
                                onChange={(e) => {
                                    handlePersonalInfoChange('phoneNumber', e);
                                }}
                                inputStyle={{
                                    width: '100%',
                                    background: alreadyExists && (personalInformationObjectInit.phoneNumber ? personalInformationObjectInit.phoneNumber.trim().length !== 3 : false) ? '#cfe2ff6e' : 'transparent',
                                }}
                                countryCodeEditable={true}
                                autoFormat={true}
                                enableSearch={true}
                                disableSearchIcon={true}
                                preferredCountries={['lb', 'sa', 'ae', 'bh', 'kw', 'om', 'qa', 'jo']}
                                excludeCountries={['il']}
                                alwaysDefaultMask={true}
                                masks={{
                                    lb: '(...) ..-...-...',
                                    sa: '...-...-....',
                                    ae: '...-...-....',
                                    bh: '...-....-....',
                                    kw: '...-....-....',
                                    om: '...-....-....',
                                    qa: '...-....-....',
                                    jo: '...-....-....',
                                }}
                            />
                        </Stack>
                    </Col>
                    <Col xs={12} md={4}>
                        <Stack>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.emailAddressLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                disabled
                                type="text"
                                className={`${alreadyExists && (personalInformationObjectInit.emailAddress ? personalInformationObjectInit.emailAddress.trim() !== "" : false) ? 'mb-0 autofilled-placeholder' : 'mb-0'}`}
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.emailPlaceholder")}
                                defaultValue={user.email}
                            />
                        </Stack>
                    </Col>
                    <Col xs={12} md={4}>
                        <Stack>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.billingEmailLabel")}</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${alreadyExists && (personalInformationObjectInit.billingEmailAddress ? personalInformationObjectInit.billingEmailAddress.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.billingEmailPlaceholder")}
                                defaultValue={personalInformationObject.billingEmailAddress}
                                onChange={(e) => {
                                    handlePersonalInfoChange('billingEmailAddress', e);
                                    e.target.className = "form-control "
                                }}
                            />
                        </Stack>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.countryLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <div className="flex-grow-1">
                                <Select
                                    styles={alreadyExists & (personalInformationObjectInit.country ? personalInformationObjectInit.country !== "" : false) ? customStyles : {}}
                                    value={optionsCountry.find((option) => option.value === personalInformationObject.country)}
                                    options={optionsCountry}
                                    onChange={(selectedOption) => {
                                        handlePersonalInfoChange('country', selectedOption);
                                        cityDisplay(selectedOption.value, docSnapStatic);
                                    }}

                                />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.stateLabel")}</Form.Label>
                            <div className="flex-grow-1">
                                <Form.Control
                                    type="text"
                                    className={`${alreadyExists && (personalInformationObjectInit.state ? personalInformationObjectInit.state.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                    placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.statePlaceholder")}
                                    value={personalInformationObject.state}
                                    onChange={(e) => {
                                        handlePersonalInfoChange('state', e);
                                        e.target.className = "form-control "
                                    }}
                                />
                            </div>
                        </Stack>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.cityLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <div className="flex-grow-1 ">
                                <Select
                                    styles={alreadyExists & (personalInformationObjectInit.city ? personalInformationObjectInit.city !== "" : false) ? customStyles : {}}
                                    value={personalInformationObject.city !== "" ? (optionsCity.find((option) => option.value === personalInformationObject.city)) : ""}
                                    options={optionsCity}
                                    onChange={(selectedOption) => handlePersonalInfoChange('city', selectedOption)}
                                />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.zipLabel")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.zipPlaceholder")}
                            className={`${alreadyExists && (personalInformationObjectInit.zip ? personalInformationObjectInit.zip.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                            defaultValue={personalInformationObject.zip}
                            onChange={(e) => {
                                handlePersonalInfoChange('zip', e);
                                e.target.className = "form-control mb-3"
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Stack>
                            <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.addressLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.addressLine1Placeholder")}
                                className={`${alreadyExists && (personalInformationObjectInit.addressLine1 ? personalInformationObjectInit.addressLine1.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                defaultValue={personalInformationObject.addressLine1}
                                onChange={(e) => {
                                    handlePersonalInfoChange('addressLine1', e);
                                    e.target.className = "form-control mb-3"
                                }}
                            />

                            <Form.Control
                                type="text"
                                placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.addressLine2Placeholder")}
                                className={`${alreadyExists && (personalInformationObjectInit.addressLine2 ? personalInformationObjectInit.addressLine2.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                                defaultValue={personalInformationObject.addressLine2}
                                onChange={(e) => {
                                    handlePersonalInfoChange('addressLine2', e);
                                    e.target.className = "form-control mb-3"
                                }}
                            />
                        </Stack>
                    </Col>
                </Row>
            </Container>

        );
    };


    const requiredInformation = () => {

        const animatedComponents = makeAnimated();


        return (

            <Container>
                <Row className="mb-3">
                    <Col xs={12} >
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.desiredWorkplaceLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    options={optionsOnSiteRemote}
                                    styles={alreadyExists && (requiredInformationObjectInit.workPlace ? requiredInformationObjectInit.workPlace.trim() !== "" : false) ? customStyles : {}}
                                    onChange={(selectedOption) => handleRequiredInfoChange('workPlace', selectedOption)}
                                    value={optionsOnSiteRemote.find((option) => option.value === requiredInformationObject.workPlace)}
                                />
                            </div>
                        </Stack>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.professionalExperienceLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    options={optionsYearsExp}
                                    styles={alreadyExists && (requiredInformationObjectInit.yearsExp ? requiredInformationObjectInit.yearsExp !== 0 : false) ? customStyles : {}}
                                    onChange={(selectedOption) => handleRequiredInfoChange('yearsExp', selectedOption)}
                                    value={optionsYearsExp.find((option) => option.value === requiredInformationObject.yearsExp)}
                                />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.hourlyRateLabel")}</h6>
                            <div className="flex-grow-1">
                                <Select
                                    styles={alreadyExists && (requiredInformationObjectInit.hourlyRate ? requiredInformationObjectInit.hourlyRate !== 0 : false) ? customStyles : {}}
                                    options={optionsHourlyRate}
                                    onChange={(selectedOption) => handleRequiredInfoChange('hourlyRate', selectedOption)}
                                    value={optionsHourlyRate.find((option) => option.value === requiredInformationObject.hourlyRate)}
                                />
                            </div>
                        </Stack>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.industriesOfExpertiseLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={optionsIndustries}
                                    styles={alreadyExists && (requiredInformationObjectInit.industries ? requiredInformationObjectInit.industries.length !== 0 : false) ? customStyles : {}}
                                    onChange={(selectedOptions) => handleRequiredInfoChange('industries', selectedOptions)}
                                    value={optionsIndustries.filter((option) =>
                                        requiredInformationObject.industries.includes(option.value)
                                    )}
                                />
                            </div>

                        </Stack>
                    </Col>
                    <Col xs={12} md={6}>
                        <Stack gap={1}>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.legalPracticeAreasLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    styles={alreadyExists && (requiredInformationObjectInit.practiceAreas ? requiredInformationObjectInit.practiceAreas.length !== 0 : false) ? customStyles : {}}
                                    options={optionsPracticeAreas}
                                    onChange={(selectedOptions) => handleRequiredInfoChange('practiceAreas', selectedOptions)}
                                    value={optionsPracticeAreas.filter((option) =>
                                        requiredInformationObject.practiceAreas.includes(option.value)
                                    )}
                                />
                            </div>
                        </Stack>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={12} md={4}>
                        <Stack>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.availabilityToStartLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    options={optionsWorkStart}
                                    styles={alreadyExists && (requiredInformationObjectInit.workStart ? requiredInformationObjectInit.workStart !== 0 : false) ? customStyles : {}}
                                    onChange={(selectedOption) => handleRequiredInfoChange('workStart', selectedOption)}
                                    value={optionsWorkStart.find((option) => option.value === requiredInformationObject.workStart)}
                                />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={12} md={4}>
                        <Stack>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.maximumProjectDurationLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    options={optionsWorkTime}
                                    styles={alreadyExists && (requiredInformationObjectInit.workDuration ? requiredInformationObjectInit.workDuration !== 0 : false) ? customStyles : {}}
                                    onChange={(selectedOption) => handleRequiredInfoChange('workDuration', selectedOption)}
                                    value={optionsWorkTime.find((option) => option.value === requiredInformationObject.workDuration)}
                                />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={12} md={4}>
                        <Stack>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.maximumWeeklyWorkingHoursLabel")} <span style={{ color: 'red' }}>*</span></h6>
                            <div className="flex-grow-1">
                                <Select
                                    options={optionsWorkHoursWeek}
                                    required

                                    classNamePrefix="react-select"
                                    styles={alreadyExists && (requiredInformationObjectInit.maxHoursPerWeek ? requiredInformationObjectInit.maxHoursPerWeek !== 0 : false) ? customStyles : {}}
                                    onChange={(selectedOption) => { handleRequiredInfoChange('maxHoursPerWeek', selectedOption) }}
                                    value={optionsWorkHoursWeek.find((option) => option.value === requiredInformationObject.maxHoursPerWeek)}
                                />

                            </div>
                        </Stack>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12} >
                        <Stack>
                            <h6 className="text-center">{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.additionalInformationLabel")}</h6>

                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder={t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.additionalInformationPlaceholder")}
                            required
                            className={`${alreadyExists && (requiredInformationObjectInit.additionalInfo ? requiredInformationObjectInit.additionalInfo.trim() !== "" : false) ? 'mb-3 autofilled-placeholder' : 'mb-3'}`}
                            defaultValue={requiredInformationObject.additionalInfo}
                            onChange={(selectedOption) => {
                                handleRequiredInfoChange('additionalInfo', selectedOption);
                                selectedOption.target.className = "form-control "
                                
                            }}
                            
                            />
                        </Stack>
                    </Col>
                </Row>
            </Container>


        );
    };

    const additionalDocuments = () => {

        return (
            <Container>
                <Row>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.resumeSubmissionLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>

                        <Form.Control
                            type="file"
                            required
                            onChange={(selectedOption) => handleAdditionalDocumentsChange('resumeCredentials', selectedOption)}
                            defaultValue={resumeWithCredentials}
                        />
                        <p style={{ color: resumeWithCredentialsMetadata && resumeWithCredentialsMetadata.customMetadata ? 'green' : 'red' }}>
                            {resumeWithCredentialsMetadata && resumeWithCredentialsMetadata.customMetadata ?
                                `${t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.pastlyUploadedFile") + resumeWithCredentialsMetadata.customMetadata.personalName}` :
                                t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.noFileUploaded")
                            }
                        </p>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.lawLicenseSubmissionLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="file"
                            required
                            onChange={(selectedOption) => handleAdditionalDocumentsChange('lawLicense', selectedOption)}
                            defaultValue={copyLawLicense}
                        />
                        <p style={{ color: copyLawLicenseMetadata && copyLawLicenseMetadata.customMetadata ? 'green' : 'red' }}>
                            {copyLawLicenseMetadata && copyLawLicenseMetadata.customMetadata ?
                                `${t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.pastlyUploadedFile") + copyLawLicenseMetadata.customMetadata.personalName}` :
                                t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.noFileUploaded")
                            }
                        </p>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className="position-relative mb-3">
                        <Form.Label>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.lawLicenseSubmissionLabel")} <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="file"
                            required
                            onChange={(selectedOption) => handleAdditionalDocumentsChange('barAdmission', selectedOption)}
                            defaultValue={copyBarAdmission}
                        />
                        <p style={{ color: copyBarAdmissionMetadata && copyBarAdmissionMetadata.customMetadata ? 'green' : 'red' }}>
                            {copyBarAdmissionMetadata && copyBarAdmissionMetadata.customMetadata ?
                                `${t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.pastlyUploadedFile") + copyBarAdmissionMetadata.customMetadata.personalName}` :
                                t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.noFileUploaded")
                            }
                        </p>
                    </Form.Group>
                </Row>
            </Container>
        );
    };


    return (

        <Accordion defaultActiveKey={['0', '1', '2']} className="d-flex flex-column " alwaysOpen>
            <Accordion.Item eventKey="0">
                <Accordion.Header style={{ marginTop: '15px' }}>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.personalInformation.title")}</Accordion.Header>
                <Accordion.Body>
                    {personalInformation()}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header style={{ marginTop: '15px' }}>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.requiredInformation.title")}</Accordion.Header>
                <Accordion.Body>
                    {requiredInformation()}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header style={{ marginTop: '15px' }}>{t("pages.provideAServiceLoggedIn.provideAServiceApply.questionsLawyer.additionalDocuments.title")}</Accordion.Header>
                <Accordion.Body>
                    {additionalDocuments()}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default FormLawyer;