import MyNavbar from './Navbar';


function Header() {

    return (
        <MyNavbar />
        );

}

export default Header;