import { useContext } from 'react';
import { UserContext } from '../../App';
import { Card, Button, Modal, Form, Row, Col, Container, Spinner } from 'react-bootstrap';
import AppFirebase from '../../App.js';
import { getFirestore, doc, setDoc, getDocs, deleteDoc, collection, getDoc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from 'react';
import { getAuth, updatePassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import Select from 'react-select'; 
import "./AccountInformationDesign.css";
import { useNavigate} from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import { useTranslation } from 'react-i18next';

const MyUserInformation = (userProfile) => {

    const { t } = useTranslation();
    const isLawyerOrParalegal = userProfile.userProfile === "Lawyer" || userProfile.userProfile === "Paralegal";

    //Static Data
    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsState, setOptionsState] = useState([]);
    const [optionsCity, setOptionsCity] = useState([]);

    // Value Data
    const [billingEmailAddress, setBillingEmailAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [phone, setPhone] = useState("");

    //Display Data
    const [countryDisplay, setCountryDisplay] = useState("");
    const [stateDisplay, setStateDisplay] = useState("");
    const [cityDisplay, setCityDisplay] = useState("");
    const [zipDisplay, setZipDisplay] = useState("");
    const [firmNameDisplay, setFirmNameDisplay] = useState("");

    const cityDisplayFunction = (country, docSnap) => {
        //setOptionsCity([{ label: "", value: "" }]);
        if (docSnap !== null) {
            switch (country) {
                case "United Arab Emirates":
                    setOptionsCity(docSnap.data().optionsCityUAE);
                    break;
                case "Qatar":
                    setOptionsCity(docSnap.data().optionsCityQAT);
                    break;
                case "Lebanon":
                    setOptionsCity(docSnap.data().optionsCityLBN);
                    break;
                case "Saudi Arabia":
                    setOptionsCity(docSnap.data().optionsCityKSA);
                    break;
                case "Kuwait":
                    setOptionsCity(docSnap.data().optionsCityKWT);
                    break;
                case "Jordan":
                    setOptionsCity(docSnap.data().optionsCityJOR);
                    break;
                case "Oman":
                    setOptionsCity(docSnap.data().optionsCityOMN);
                    break;
                case "Bahrain":
                    setOptionsCity(docSnap.data().optionsCityBHR);
                    break;
                case "Other...":
                    setOptionsCity([{ label: "Other...", value: "Other..." }]);
                    break;
                default:
                    setOptionsCity([{ label: "", value: "" }]);
            }
        }
    };

    const navigate = useNavigate();

    let auth = getAuth();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const [docSnapStatic, setDocSnapStatic] = useState(null);

    const db = getFirestore(AppFirebase);

    const user = useContext(UserContext);

    const handleEditPassword = () => {
        setShowModal(true);
    };

    const handleDeleteAccount = () => {
        setShowModalDelete(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setShowModalDelete(false);
        setPasswordsMatch(false);
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
        setErrorMessage('')
    };

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    useEffect(() => {
        if (password === confirmPassword) {
            setPasswordsMatch(true);
            setErrorMessage('');
        }
        else {
            setPasswordsMatch(false);
            setErrorMessage(t("pages.accountInformation.errors.error1"));
        }

        setErrorMessage(errorMessage)

    }, [errorMessage, password, oldPassword, confirmPassword, passwordsMatch]);

    const handlePasswordSend = async () => {

        if (passwordsMatch) {
            try {
                const userCredential = await signInWithEmailAndPassword(auth, user.email, oldPassword);
                const newAuthUser = userCredential.user;
                await updatePassword(newAuthUser, confirmPassword);
                alert(t("pages.accountInformation.alerts.alert3"));
                handleClose();
            } catch (error) {
                if (error.message.includes("auth/weak-password")) {
                    setErrorMessage(t("pages.accountInformation.errors.error2"));
                } else if (error.message.includes("auth/wrong-password")) {
                    setErrorMessage(t("pages.accountInformation.errors.error3"));
                } else {
                    setErrorMessage(t("pages.accountInformation.errors.error4"));
                }
            }

        };
    };

    const confirmDeleteAccount = async () => {

        signInWithEmailAndPassword(auth, user.email, oldPassword)
            .then(async (userCredential) => {
                const userDocRef = doc(db, "userInfo", user.uid);

                const docSnap = await getDoc(userDocRef);

                if (docSnap.exists()) {
                    //Write userdoc in userArchive
                    await setDoc(doc(db, "userArchive", user.uid), docSnap.data());

                    //Write Find a lawyer query from userInfo to userArchive
                    const findLawyerQuerySnapshot = await getDocs(collection(db, "userInfo", user.uid, "findALawyerQuery"));
                    console.log(findLawyerQuerySnapshot);
                    findLawyerQuerySnapshot.forEach(async (document) => {

                        await setDoc(doc(db, "userArchive", user.uid, "findALawyerQuery", document.id), document.data());
                        await deleteDoc(doc(db, "userInfo", user.uid, "findALawyerQuery", document.id));
                    });

                    //Provide a service query
                    const provideServiceQuerySnapshot = await getDocs(collection(db, "userInfo", user.uid, "provideAServiceQuery"));
                    console.log(provideServiceQuerySnapshot);
                    provideServiceQuerySnapshot.forEach(async (document) => {
                        await setDoc(doc(db, "userArchive", user.uid, "provideAServiceQuery", document.id), document.data());
                        await deleteDoc(doc(db, "userInfo", user.uid, "provideAServiceQuery", document.id));
                    });

                } else {
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                }

                // Delete  document in userInfo
                await deleteDoc(doc(db, "userInfo", user.uid));

                const newAuthUser = userCredential.user;
                newAuthUser.delete().then(() => {
                    //Get document to be delete


                    alert(t("pages.accountInformation.alerts.alert4"))
                    navigate('/');
                }).catch((error) => {
                    console.log(error.message);
                });
            })
            .catch((error) => {
                console.log(error.message)
                setErrorMessage(t("pages.accountInformation.errors.error5"));
            });

    };

    const deleteAccountModal = () => {
        return (
            <Modal show={showModalDelete} onHide={handleClose} centered>
                <Modal.Header style={{ backgroundColor: 'rgb(220, 53, 69)', color: 'white', minHeight:'75px' }} closeButton>
                    <Modal.Title >{t("pages.accountInformation.userInformation.deleteAccountModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="py-2">
                        {t("pages.accountInformation.userInformation.deleteAccountModal.text1")}
                    </p>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder={t("pages.accountInformation.userInformation.deleteAccountModal.placeholderPassword")}
                            value={oldPassword}
                            onChange={handleOldPasswordChange}
                        />
                    </Form.Group>

                    <div className="text-danger">{errorMessage}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        {t("pages.accountInformation.userInformation.deleteAccountModal.cancelButton")}
                    </Button>
                    <Button variant="outline-danger" onClick={confirmDeleteAccount}>
                        {t("pages.accountInformation.userInformation.deleteAccountModal.deleteButton")}
                    </Button>
                </Modal.Footer>
            </Modal>

        );
    };

    const [isMailSent, setIsMailSent] = useState(false);
    const handleResetPasswordViaMail = () => {
        const auth = getAuth();
        auth.useDeviceLanguage();
        const user = auth.currentUser;


        sendPasswordResetEmail(auth, user.email)
            .then(() => {
                setIsMailSent(true);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(t("pages.accountInformation.alerts.alert5"))
                
            });


    };

    const modifyPasswordModal = () => {

        return (


            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title className="h4">{t("pages.accountInformation.userInformation.resetPasswordModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5">
                    <p className="py-2">
                        {t("pages.accountInformation.userInformation.resetPasswordModal.text1")}
                    </p>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder={t("pages.accountInformation.userInformation.resetPasswordModal.placeholderPassword")}
                            value={oldPassword}
                            onChange={handleOldPasswordChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder={t("pages.accountInformation.userInformation.resetPasswordModal.placeholderNewPassword")}
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder={t("pages.accountInformation.userInformation.resetPasswordModal.placeholderRetypePassword")}
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                    </Form.Group>

                    <div className="text-danger">{errorMessage}</div>

                    <Container>
                        <Row className="justify-content-center align-items-center mb-4">
                            <Col className="text-center mt-4">
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    {t("pages.accountInformation.userInformation.resetPasswordModal.cancelButton")}
                                </Button>
                            </Col>
                            <Col className="text-center mt-4">
                                <Button  variant="outline-primary" onClick={handlePasswordSend}>
                                    {t("pages.accountInformation.userInformation.resetPasswordModal.resetButton")}
                                </Button>
                            </Col>
                        </Row>
          
                    </Container>
                </Modal.Body>

                {isMailSent ? (
                    <div className="alert alert-success mt-3">
                        <i className="bi bi-check-circle-fill me-2"></i>
                        {t("pages.accountInformation.userInformation.resetPasswordModal.emailSent")}
                    </div>
                ) : (
                        <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="link" onClick={handleResetPasswordViaMail} className="mb-3">
                                {t("pages.accountInformation.userInformation.resetPasswordModal.resetButtonEmail")}
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        );
    };

    // fetches required static data in account Info
    const fetchStaticData = async () => {
        try {
            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);
            setDocSnapStatic(docSnap);

            const userRef = doc(db, 'userInfo', user.uid);
            const userSnapshot = await getDoc(userRef);

    

            const sortedOptionsCountry = docSnap.data().optionsCountry

            // Sort the copied array alphabetically based on the label property
            sortedOptionsCountry.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            });

            // Update state with the sorted array
            setOptionsCountry(sortedOptionsCountry);

            cityDisplayFunction(userSnapshot.data().country, docSnap)

        } catch (error) {
            console.log(error.message);
        }

    };

    function retrieveLabelByValue(dictionaryArray, value) {
        for (const dictionary of dictionaryArray) {
            if (dictionary.value === value) {
                return dictionary.label;
            }
        }
        return null; // Return null if value is not found
    };

    useEffect(() => {
        fetchStaticData();
        fetchUserInfo();
    }, [user]);

    useEffect(() => {
        fetchUserInfo();
    }, [optionsCountry]);


    const fetchUserInfo = async () => {
        try {

            if (!user) {
                return;
            }
            const userRef = doc(db, 'userInfo', user.uid);
            const userSnapshot = await getDoc(userRef);

            //console.log(userSnapshot.data().phoneNumber)

            if (isLawyerOrParalegal) {
                setCountry(userSnapshot.data().country);
                setState(userSnapshot.data().state);
                setCity(userSnapshot.data().city);
                setZip(userSnapshot.data().zip);
                setAddress1(userSnapshot.data().addressLine1);
                setAddress2(userSnapshot.data().addressLine2);
                setPhone(userSnapshot.data().phoneNumber)
                setBillingEmailAddress(userSnapshot.data().billingEmailAddress);
                setCountryDisplay(retrieveLabelByValue(optionsCountry, userSnapshot.data().country));
                setStateDisplay(retrieveLabelByValue(optionsState, userSnapshot.data().state));
                setCityDisplay(retrieveLabelByValue(optionsCity, userSnapshot.data().city));
                setZipDisplay(userSnapshot.data().zip);

            }
            else {

                setCountry(userSnapshot.data().country);
                setState(userSnapshot.data().state);
                setCity(userSnapshot.data().city);
                setZip(userSnapshot.data().zip);
                setAddress1(userSnapshot.data().addressLine1);
                setAddress2(userSnapshot.data().addressLine2);
                setBillingEmailAddress(userSnapshot.data().billingEmailAddress);
                setPhone(userSnapshot.data().phoneNumber)
                setCountryDisplay(retrieveLabelByValue(optionsCountry, userSnapshot.data().country));
                setStateDisplay(retrieveLabelByValue(optionsState, userSnapshot.data().state));
                setCityDisplay(retrieveLabelByValue(optionsCity, userSnapshot.data().city));
                setZipDisplay(userSnapshot.data().zip);
                setFirmNameDisplay(userSnapshot.data().lastName);

            }

        } catch (error) {
            console.error('Error fetching required information:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModalEdit(false);
        fetchUserInfo();
    };

    const handleSaveUserInfo = async () => {
        try {
            const userRef = doc(db, 'userInfo', user.uid);
            if (isLawyerOrParalegal) {
                const updates = {
                    billingEmailAddress: billingEmailAddress,
                    phoneNumber: phone,
                    country: country,
                    state: state,
                    zip: zip,
                    city: city,
                    addressLine1: address1,
                    addressLine2: address2

                };

                await updateDoc(userRef, updates);
                handleCloseModal();
            } else {
                const updates = {
                    billingEmailAddress: billingEmailAddress,
                    phoneNumber: phone,
                    country: country,
                    state: state,
                    city: city,
                    zip: zip,
                    addressLine1: address1,
                    addressLine2: address2
                };

                await updateDoc(userRef, updates);
                handleCloseModal();
            }

        } catch (error) {
            console.error('Error updating required information:', error);
        }
    };

    const handleEditUserInfo = () => {
        setShowModalEdit(true);
    };

    const modalUserInfo = () => {

        return (
            <Modal show={showModalEdit} onHide={handleCloseModal}>
                <Modal.Header style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title>{t("pages.accountInformation.userInformation.modalUserInfo.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="mb-3">
                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.billingEmail")}
                                </Col>
                                <Col>
                                    <Form.Control
                                        placeholder={t("pages.accountInformation.userInformation.modalUserInfo.placeholderBillingEmail")}
                                        value={billingEmailAddress}
                                        onChange={(e) => { setBillingEmailAddress(e.target.value); }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.country")}
                                </Col>
                                <Col>
                                    <Select
                                        options={optionsCountry}
                                        defaultValue={optionsCountry.find(option => option.value === country) }
                                        onChange={(selectedOption) => {
                                            setCountry(selectedOption.value);
                                            setCity("");
                                            cityDisplayFunction(selectedOption.value, docSnapStatic);
                                        }}
                                        isSearchable={false}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.state")}
                                </Col>
                                <Col>
                                    <Form.Control
                                        placeholder={t("pages.accountInformation.userInformation.modalUserInfo.placeholderState")}
                                        value={state}
                                        onChange={(e) => { setState(e.target.value); }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.city")}
                                </Col>
                                <Col>
                                    <Select
                                        options={optionsCity}
                                        value={(city !=="" ? optionsCity.find(option => option.value === city) : "")}
                                        onChange={(selectedOption) => setCity(selectedOption.value)}
                                        isSearchable={false}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.zip")}
                                </Col>
                                <Col>
                                    <Form.Control
                                        placeholder={t("pages.accountInformation.userInformation.modalUserInfo.placeholderZip")}
                                        value={zip}
                                        onChange={(e) => { setZip(e.target.value); }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.phone")}
                                </Col>
                                <Col>
                                    <PhoneInput
                                        required
                                        className="number"
                                        country={"bh"}
                                        value={phone}
                                        placeholder="Enter your phone number"
                                        onChange={(e) => { setPhone(e); }}
                                        inputStyle={{ width: '100%' }}
                                        countryCodeEditable={true}
                                        autoFormat={true}
                                        enableSearch={true}
                                        disableSearchIcon={true}
                                        preferredCountries={['lb', 'sa', 'ae', 'bh', 'kw', 'om', 'qa', 'jo']}
                                        excludeCountries={['il']}
                                        alwaysDefaultMask={true}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.address1")}
                                </Col>
                                <Col>
                                    <Form.Control
                                        value={address1}
                                        onChange={(e) => { setAddress1(e.target.value); }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    {t("pages.accountInformation.userInformation.modalUserInfo.address2")}
                                </Col>
                                <Col>
                                    <Form.Control
                                        placeholder={t("pages.accountInformation.userInformation.modalUserInfo.placeholderAddress2")}
                                        value={address2}
                                        onChange={(e) => { setAddress2(e.target.value); }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleCloseModal}>
                        {t("pages.accountInformation.userInformation.modalUserInfo.closeButton")}
                    </Button>
                    <Button variant="outline-primary" onClick={handleSaveUserInfo}>
                        {t("pages.accountInformation.userInformation.modalUserInfo.saveButton")}
                    </Button>
                </Modal.Footer>
            </Modal>

        )
    }

    return (

        user ? (
            <Card className="user-profile">
                <>
                    <Card.Header className="text-start h2" style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }}>
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col md={10} className="d-flex mb-2 mt-2">
                                {t("pages.accountInformation.userInformation.card.title")}
                            </Col>
                            <Col md={2} className="d-flex mb-2 mt-2">
                                <Button variant="outline-light" style={{ width: '100%' }} onClick={handleEditUserInfo}>{t("pages.accountInformation.userInformation.card.editButton")}</Button>
                            </Col>
                        </Row>


                    </Card.Header>

                    <Card.Body className='text-start' >
                        <div className="mb-5">
                            <Card.Body>
                                <Row>
                                    <Col md={4} >
                                        <div className="mb-4" style={{ borderRight: '2px solid #add8e6', paddingRight: '15px' }}>
                                            <p className="mb-3" style={{ fontWeight: 'bold' }}>{isLawyerOrParalegal ? t("pages.accountInformation.userInformation.card.applicantDetails") : t("pages.accountInformation.userInformation.card.firmDetails")}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.fullName")} {user.displayName}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.email")} {user.email}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.billingEmail")} {billingEmailAddress}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.phone")} {phone}</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-4" style={{ borderRight: '2px solid #add8e6', paddingRight: '15px' }}>
                                            <p className="mb-3" style={{ fontWeight: 'bold' }} >{t("pages.accountInformation.userInformation.card.locationDetails")}</p>
                                            <p className="mb-2"> {countryDisplay}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.country")} {state}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.state")} {cityDisplay}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.zip")} {zipDisplay}</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="mb-4">
                                            <p className="mb-3" style={{ fontWeight: 'bold' }}>{t("pages.accountInformation.userInformation.card.addressDetails")}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.address1")} {address1}</p>
                                            <p className="mb-2">{t("pages.accountInformation.userInformation.card.address2")} {address2}</p>
                                        </div>
                                    </Col>
                                </Row>

                            </Card.Body>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={8}>
                                    <hr />
                                </Col>
                                <Col md={2}></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <p className="mb-3"><strong>{t("pages.accountInformation.userInformation.card.accountActivity")}</strong></p>
                                    <p className="mb-2">{t("pages.accountInformation.userInformation.card.accountCreated")} {user.metadata.creationTime || 'N/A'}</p>
                                    <p className="mb-2">{t("pages.accountInformation.userInformation.card.lastLogin")} {user.metadata.lastSignInTime || 'N/A'}</p>
                                </Col>
                            </Row>
                            {/* Add any other user information you want to display */}




                        </div>


                        <Container>
                            <Row className="justify-content-between">
                                <Col xs="auto" className='mt-2 mb-2'>
                                    <Button variant="outline-danger" onClick={handleDeleteAccount}>{t("pages.accountInformation.userInformation.deleteButton")}</Button>
                                </Col>
                                <Col xs="auto" className='mt-2 mb-2'>
                                    <Button variant="outline-primary" onClick={handleEditPassword}>{t("pages.accountInformation.userInformation.resetButton")}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                    {modifyPasswordModal()}
                    {deleteAccountModal()}
                    {modalUserInfo()}
                </>

            </Card>
        ) : (
            <Card className="d-flex align-items-center justify-content-center p-5">
                <Spinner animation="grow" variant="primary" size="lg" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <Card.Text className="mt-3">Loading...</Card.Text>
            </Card>
        )
    );
};




export default MyUserInformation;
