import MyCarouselCountriesHomepage from "./LoggedOut/Homepage/CarouselCountriesHomepage";
import MyServiceSelectionHomepage from './LoggedOut/Homepage/ServiceSelectionHomepage';
import MyAboutUsHomepage from './LoggedOut/Homepage/AboutUsHomepage';
import MyWhoDoWeHelp from './LoggedOut/Homepage/WhoDoWeHelpHomepage'
import './LoggedOut/Homepage/HomepageDesign.css'
import { Row, Container, Card } from "react-bootstrap";
import React, { useEffect } from 'react';

//Correct footer

function MyLoggedOutForm() {

    useEffect(() => {
        const handleScroll = () => {
            const scrollElements = document.querySelectorAll('.scroll-transition');
            const windowHeight = window.innerHeight;

            scrollElements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                if (elementTop < windowHeight * 0.9) {
                    element.classList.add('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <Container fluid>
            <div>
                <Row className="mb-5">
                    <MyServiceSelectionHomepage />
                </Row>
            </div>
            <div className="scroll-transition">
                <Row className="mb-5 mt-5">
                    <MyAboutUsHomepage />
                </Row>
            </div>
            <div className="scroll-transition">
                <Row className="mt-5 mb-5">
                    <MyWhoDoWeHelp />
                </Row>
            </div>
            <div className="scroll-transition">
                <Row className="mt-5 mb-5">
                    <MyCarouselCountriesHomepage />
                </Row>
            </div>
        </Container>
    );
};

export default MyLoggedOutForm;