import React, { useEffect, useState, useReducer } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Table, Input, Dropdown, Button, TableRow, TableHeader, TableBody, TableHeaderCell, TableCell  } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import _ from 'lodash';
import AdminMenu from './AdminMenu';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { getFunctions, httpsCallable } from "firebase/functions";
import {
    getFirestore,
    collection,
    getDocs,
    updateDoc,
    doc,
    getDoc,
} from 'firebase/firestore';
import AppFirebase from '../App.js';

const AdminPageOperations = () => {
    const db = getFirestore(AppFirebase);

    const functions = getFunctions();
    const sendApprovalFLSASMail = httpsCallable(functions, 'sendApprovalFLSASMail');

    const [flattenedQueries, setFlattenedQueries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [selectedPracticeAreas, setSelectedPracticeAreas] = useState([]);
    const [selectedWorkPlace, setSelectedWorkPlace] = useState([]);
    const [userApplicationData, setUserApplicationData] = useState([]);
    const [userDetailData, setUserDetailData] = useState([]);
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [loadingStates, setLoadingStates] = useState({});

    // Static Data
    const [optionsPracticeAreas, setOptionsPracticeAreas] = useState([]);
    const [optionsprofileLawyer, setOptionsprofileLawyer] = useState([]);
    const [optionsOnSiteRemote, setOptionsOnSiteRemote] = useState([]);
    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsYearsExp, setOptionsYearsExp] = useState([]);
    const [optionsWorkStart, setOptionsWorkStart] = useState([]);
    const [optionsWorkTime, setOptionsWorkTime] = useState([]);
    const [optionsWorkHoursWeek, setOptionsWorkHoursWeek] = useState([]);
    const [optionsWorkDuration, setOptionsWorkDuration] = useState([]);
    const [optionsBudget, setOptionsBudget] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);
            //console.log("Static info : ", docSnap.data());
            setOptionsCountry(docSnap.data().optionsCountry);
            setOptionsYearsExp(docSnap.data().optionsYearsExp);
            setOptionsWorkStart(docSnap.data().optionsWorkStart);
            setOptionsWorkTime(docSnap.data().optionsWorkTime);
            setOptionsWorkHoursWeek(docSnap.data().optionsWorkHoursWeek);
            setOptionsWorkDuration(docSnap.data().optionsDuration);
            setOptionsBudget(docSnap.data().optionsBudget);

        };

        fetchData();
    }, []);

    const exampleReducer = (state, action) => {
        switch (action.type) {
            case 'CHANGE_SORT':
                if (state.column === action.column) {
                    return {
                        ...state,
                        direction:
                            state.direction === 'ascending' ? 'descending' : 'ascending',
                    };
                }
                return {
                    column: action.column,
                    direction: 'ascending',
                };
            default:
                throw new Error();
        }
    };


    const handleCustomAction = async (userId, queryId) => {

        const filteredQueries = currentItems.filter(
            (query) => query.userId === userId && query.queryId === queryId
        );

        setUserApplicationData(filteredQueries[0].data);


        const docRef = doc(db, "userInfo", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) { 
            setUserDetailData(docSnap.data());

        } else {
            console.log("No such document!");
        }


        let tempArray = [];
        //console.log(filteredQueries[0].data.profilePreference)
        for (const selectedProfile in filteredQueries[0].data.profileResult) {
            //console.log(filteredQueries[0].data.profileResult[selectedProfile]);
            const profileRef = doc(db, "userInfo", filteredQueries[0].data.profileResult[selectedProfile]);
            const snapProfile = await getDoc(profileRef);
            
            if (snapProfile.exists) {
                const profileData = {
                    ...snapProfile.data(),
                    selectedUserId: filteredQueries[0].data.profileResult[selectedProfile],
                };
                tempArray.push(profileData);
                
            } else {
                tempArray.push(null)
            }
        }
        setUserSearchResults(tempArray);
    };

    const [sortState, dispatchSort] = useReducer(exampleReducer, {
        column: null,
        direction: null,
    });

    useEffect(() => {
        const fetchAndFlattenQueries = async () => {
            try {
                const queriesData = await fetchAllQueries();
                const flattenedQueries = await flattenQueries(queriesData);
                setFlattenedQueries(flattenedQueries);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching and flattening queries:', error);
                setLoading(false);
            }
        };

        fetchAndFlattenQueries();
        fetchStaticData(); // Fetch static data when the component mounts
    }, []);


    const columns = [
        'Display Info',
        'Status',
        'Date of application',
        'Profile',
        'Support',
        'Place of work',
        'Country',
        'Industries',
        'Practice Areas',
        'Hours per week',
        'Start date',
        'Work duration',
        'Approve ?'
    ];

    const tableHeader = (
        <Table.Header>
            <Table.Row>
                {columns.map((column) => (
                    <Table.HeaderCell
                        key={column}
                        onClick={() => handleSort(column)}
                        sorted={sortState.column === column ? sortState.direction : null}
                    >
                        {column}
                    </Table.HeaderCell>
                ))}
            </Table.Row>
        </Table.Header>
    );

    const flattenQueries = async (queriesArray) => {
        if (queriesArray.length === 0) {
            return [];
        }

        let flattenedQueries = [];

        for (let n = 0; n < queriesArray.length; n++) {
            const currentPromise = queriesArray[n];
            const queries = await currentPromise;
            flattenedQueries = flattenedQueries.concat(queries.flat());
        }

        return flattenedQueries;
    };

    const fetchAllQueries = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'userInfo'));

            const queriesData = querySnapshot.docs.flatMap((userDoc) => {
                const findALawyerQueryRef = collection(
                    db,
                    'userInfo',
                    userDoc.id,
                    'findALawyerQuery'
                );
                return fetchAllLawyerQueries(findALawyerQueryRef);
            });

            return queriesData;
        } catch (error) {
            console.error('Error fetching documents:', error);
            throw error;
        }
    };

    const fetchAllLawyerQueries = async (queryRef) => {
        const querySnapshot = await getDocs(queryRef);

        return querySnapshot.docs.map((doc) => {
            return {
                userId: doc.ref.parent.parent.id,
                queryId: doc.id,
                data: doc.data(),
            };
        });
    };

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleProfileChange = (e, { value }) => {
        setSelectedProfiles(value);
    };

    const handlePracticeAreasChange = (e, { value }) => {
        setSelectedPracticeAreas(value);
    };

    const handleWorkPlaceChange = (e, { value }) => {
        setSelectedWorkPlace(value);
    };

    const fetchStaticData = async () => {
        try {
            const docRef = doc(db, 'staticData', 'staticDataV1');
            const docSnap = await getDoc(docRef);
            //console.log('Static info : ', docSnap.data());

            setOptionsPracticeAreas(
                docSnap.data().optionsPracticeAreas.map((option) => ({
                    key: option.key,
                    text: option.label,
                    value: option.value,
                }))
            );
            setOptionsprofileLawyer(
                docSnap.data().profileLawyer.map((option) => ({
                    key: option.key,
                    text: option.label,
                    value: option.value,
                }))
            );
            setOptionsOnSiteRemote(
                docSnap.data().optionsOnSiteRemote.map((option) => ({
                    key: option.key,
                    text: option.label,
                    value: option.value,
                }))
            );
            //console.log(optionsOnSiteRemote);
        } catch (error) {
            console.log(error.message);
        }
    };

    const filteredData = flattenedQueries.filter((item) => {
        // Adjust the property names based on your actual data structure
        const lowerCasedLastName = (item.data.typeLegalSupport || '').toLowerCase();
        const matchesSearchTerm = lowerCasedLastName.includes(searchTerm.toLowerCase());
        const matchesProfile =
            selectedProfiles.length === 0 ||
            selectedProfiles.includes(item.data.typeLegalSupport);
        const matchesPracticeAreas =
            selectedPracticeAreas.length === 0 ||
            selectedPracticeAreas.some((area) =>
                (item.data.practiceAreas || []).some(
                    (selectedArea) => selectedArea.value === area
                )
            );
        const matchesWorkPlace =
            selectedWorkPlace.length === 0 || selectedWorkPlace.includes(item.data.onSiteRemote);

        return (
            matchesSearchTerm && matchesProfile && matchesPracticeAreas && matchesWorkPlace
        );
    });



    const handleSort = (clickedColumn) => {
        dispatchSort({
            type: 'CHANGE_SORT',
            column: clickedColumn,
        });
    };

    const sortedData = React.useMemo(() => {
        if (!sortState.column) {
            return filteredData;
        }

        const sorted = _.sortBy(filteredData, [sortState.column]);

        return sortState.direction === 'descending' ? sorted.reverse() : sorted;
    }, [filteredData, sortState.column, sortState.direction]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleToggleFinished = async (userId, queryId) => {
        try {
            setLoadingRequest(true);

            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [queryId]: true,
            }));
            const userDocRef = doc(
                db,
                'userInfo',
                userId,
                'findALawyerQuery',
                queryId
            );
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const currentFinishedStatus = userDoc.data().finished || false;

                
                // Use updateDoc to update only the 'finished' field
                await updateDoc(userDocRef, {
                    finished: !currentFinishedStatus,
                });
                //console.log(!currentFinishedStatus);
                //If finishedstatus === true send approval email to the specific row user
                
                if (!currentFinishedStatus === true) {
                    const userDetailInfoRef = doc(db,'userInfo',userId);
                    const userDetailDoc = await getDoc(userDetailInfoRef);

                    const result = await sendApprovalFLSASMail({ email: userDetailDoc.data().emailAddress, userId: userId, queryId: queryId });
                    //console.log("Mail is sent");
                } 
                // Update the local state to reflect the change
                setFlattenedQueries((prevQueries) =>
                    prevQueries.map((query) =>
                        query.userId === userId && query.queryId === queryId
                            ? { ...query, data: { ...query.data, finished: !currentFinishedStatus } }
                            : query
                    )
                );
                setLoadingRequest(false);
            } else {
                console.log('User document not found');
            }
        } catch (error) {
            console.error('Error toggling finished:', error);
        } finally {
            // Set loading states to false in the finally block
            setLoadingRequest(false);
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [queryId]: false,
            }));
        }

    };


    const tableBody = (
        <Table.Body>
            {currentItems.map((item) => {
                if (!item || !item.data) {
                    return null;
                }

                const {
                    data: {
                        finished,
                        queryCreationDate,
                        profile,
                        typeLegalSupport,
                        onSiteRemote,
                        remotePlaceOfWork,
                        industries,
                        practiceAreas,
                        timeFrame,
                    },
                    userId,
                    queryId,
                    
                } = item;

                const statusDisplay = finished ? (
                    <span style={{ color: 'green' }}>Completed</span>
                ) : (
                    <span style={{ color: 'red' }}>In Progress</span>
                );

                const formattedDate =
                    queryCreationDate && queryCreationDate.seconds
                        ? new Date(queryCreationDate.seconds * 1000).toLocaleDateString()
                        : '';

                const rowData = [
                    <Button
                        content="show"
                        color="blue"
                        onClick={() => handleCustomAction(userId, queryId)}
                    />,
                    statusDisplay,
                    formattedDate,
                    profile || '',
                    typeLegalSupport || '',
                    onSiteRemote || '',
                    (remotePlaceOfWork &&
                        remotePlaceOfWork.length > 0 &&
                        remotePlaceOfWork[0].label) ||
                    '',
                    (industries && industries.length > 0 && industries.map((industry) => industry.label).join(', ')) || '',
                    (practiceAreas && practiceAreas.length > 0 && practiceAreas.map((area) => area.label).join(', ')) || '',
                    (timeFrame &&
                        timeFrame.workHoursWeek &&
                        timeFrame.workHoursWeek.label) ||
                    '',
                    (timeFrame && timeFrame.workStart && timeFrame.workStart.label) || '',
                    (timeFrame && timeFrame.workTime && timeFrame.workTime.label) || '',
                    loadingStates[queryId] ? (
                        <Spinner animation="border" variant="primary" />
                    ) : (
                        <Button
                            icon="check"
                            color={finished ? 'green' : 'red'}
                            onClick={() => handleToggleFinished(userId, queryId)}
                        />
                    ),
                ];

                return (
                    <Table.Row key={queryId}>
                        {rowData.map((data, index) => (
                            <Table.Cell key={index}>{data}</Table.Cell>
                        ))}
                    </Table.Row>
                );
            })}
        </Table.Body>
    );

    const tableFooter = (
        <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan={columns.length} textAlign="center">
                    <Button.Group>
                        <Button
                            icon="angle double left"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(1)}
                        />
                        <Button
                            icon="angle left"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                        />
                        <Button
                            icon="angle right"
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        />
                        <Button
                            icon="angle double right"
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(totalPages)}
                        />
                    </Button.Group>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    );


    const UserDetailsAccordion = () => {

        // Function to get label based on value and options array
        // Function to get label based on value and options array
        const getLabel = (value, options) => {
            if (!options || !Array.isArray(options)) {
                return value;
            }

            const option = options.find((option) => option.value === value);
            return option ? option.label : value;
        };

        return (
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Application Details</Accordion.Header>
                    <Accordion.Body>

                        <Form className="mb-5">
                            <Form.Group className="mb-5">
                                <Form.Label>Contact details:</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control type="text" value={(userDetailData.firstName ? userDetailData.firstName + " " : "") + (userDetailData.lastName || 'N/A')} readOnly />
                                        <Form.Text className="text-muted">Name</Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.emailAddress || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">Email Address</Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.billingEmailAddress || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">Billing Email Address</Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.phoneNumber || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">Phone Number</Form.Text>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group className="mb-5">
                                <Form.Label>Business Address:</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.state || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">State</Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.country || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">Country</Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.city|| 'N/A'} readOnly />
                                        <Form.Text className="text-muted">City</Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.zip || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">Zip</Form.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.addressLine1 || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">Address 1</Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control type="text" value={userDetailData.addressLine2 || 'N/A'} readOnly />
                                        <Form.Text className="text-muted">Address 2</Form.Text>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Additional Info:</Form.Label>
                                <Form.Control type="text" as="textarea" value={userApplicationData.additionalInfo || 'N/A'} readOnly />
                            </Form.Group>
                        </Form>

                        <h3>Search Results & Preferences </h3>
                        <div style={{ overflowX: "auto" }}>
                        <Table celled selectable>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>Name</TableHeaderCell>
                                    <TableHeaderCell>Profile</TableHeaderCell>
                                    <TableHeaderCell>Email Address</TableHeaderCell>
                                    <TableHeaderCell>Phone</TableHeaderCell>
                                    <TableHeaderCell>Country</TableHeaderCell>
                                    <TableHeaderCell>Experience</TableHeaderCell>
                                    <TableHeaderCell>Industries</TableHeaderCell>
                                    <TableHeaderCell>PracticeAreas</TableHeaderCell>
                                    <TableHeaderCell>Work Place</TableHeaderCell>
                                    <TableHeaderCell>Work Start</TableHeaderCell>
                                    <TableHeaderCell>Work Duration</TableHeaderCell>
                                </TableRow>
                            </TableHeader>

                            <Table.Body>
                                {userSearchResults.map((item) => (
                                    <Table.Row key={item.selectedUserId}>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)} >{item.firstName ? `${item.firstName} ${item.lastName}` : `${item.lastName}`}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{item.profileLawyer}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{item.emailAddress}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{item.phoneNumber}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{getLabel(item.country, optionsCountry)}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{getLabel(item.yearsExp, optionsYearsExp)}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{item.industries.join(', ')}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{item.practiceAreas.join(', ')}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{item.workPlace}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{getLabel(item.workStart, optionsWorkStart)}</Table.Cell>
                                        <Table.Cell positive={userApplicationData.profilePreference.includes(item.selectedUserId)}>{getLabel(item.workDuration, optionsWorkDuration)}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                            </Table>
                            </div>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        );
    };

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }



    return (
        <Container>
            <Row style={{ marginTop: '50px' }}>
                <Col md={1}>
                    <AdminMenu />
                </Col>
                <Col>
                    <h2>Admin Center</h2>
                    <h3>Find a Lawyer Manager</h3>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <Input
                        icon="search"
                        placeholder="Search by Profile Type"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        placeholder="Select Profile Type"
                        fluid
                        multiple
                        selection
                        options={optionsprofileLawyer}
                        onChange={handleProfileChange}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        placeholder="Select Practice Areas"
                        fluid
                        multiple
                        selection
                        options={optionsPracticeAreas}
                        onChange={handlePracticeAreasChange}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        placeholder="Select Work Place"
                        fluid
                        multiple
                        selection
                        options={optionsOnSiteRemote}
                        onChange={handleWorkPlaceChange}
                    />
                </Col>
            </Row>
            <Row style={{ marginBottom: '50px' }}>
                <div style={{ overflowX: "auto" }}>
                <Table striped sortable selectable>
                    {tableHeader}
                    {tableBody}
                    {tableFooter}
                    </Table>
                </div>

            </Row>
            <Row className="mb-5 mt-3">
                <UserDetailsAccordion />
            </Row>
        </Container>
    );
};

export default AdminPageOperations;
