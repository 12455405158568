import { useState } from 'react';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import HorizontalCard from "../../Components/HorizontalCard.js"
import { FaUserFriends, FaUserShield, FaGlobe, FaLightbulb } from 'react-icons/fa'; // Import the icon component
import { useTranslation } from 'react-i18next';

const MyWhoWeAre = () => {

    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState('Efficiency');

    return (
        <Container style={{ fontFamily: 'Lato' }} className="mt-5">
            <Row className="mb-5">
                <h2 style={{ fontSize: '25px' }}>
                    Forging relationships between legal professionals and clients
                </h2>
            </Row>

            <Tabs
                defaultActiveKey="Efficiency"
                id="fill-tab-example"
                className="mb-3"
                fill
                activeKey={selectedTab}
                onSelect={(tab) => setSelectedTab(tab)}
            >
                <Tab eventKey="Efficiency" title={
                    <span
                        style={{
                            fontSize: '20px',
                            color: selectedTab === 'Efficiency' ? '#007bff' : '#6c757d',
                            borderRadius: '5px', // Optional for rounded corners on hover
                            padding: '5px', // Optional for padding on hover
                            cursor: 'pointer', // Optional to show hover cursor
                            transition: 'background-color 0.2s ease-in-out', // Optional for smooth transition
                        }}
                        onMouseEnter={() => {
                            // Update the style directly using inline styles
                            document.querySelector('#efficiency-tab').style.color = '#007bff';
                        }}
                        onMouseLeave={() => {
                            // Restore the original color based on selectedTab state
                            document.querySelector('#efficiency-tab').style.color =
                                selectedTab === 'Efficiency' ? '#007bff' : '#6c757d';
                        }}
                        id="efficiency-tab" // Unique identifier for the Efficiency tab
                    >
                        {t("pages.aboutUs.whoWeAre.tab1.title1")}
                    </span>
                }>
                    <div style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '10px', marginTop: '10px' }}>
                        <HorizontalCard
                            imageUrl="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FMixedPeopleMeeting.jpg?alt=media&token=c20298ee-3979-41a2-ac3a-3152be346282"
                            icon={FaUserFriends} 
                            title={t("pages.aboutUs.whoWeAre.tab1.title2")}
                            info={t("pages.aboutUs.whoWeAre.tab1.content")}
                    />
                        </div>
                </Tab>
                <Tab eventKey="Integrity" title={
                    <span
                        style={{
                            fontSize: '20px',
                            color: selectedTab === 'Integrity' ? '#007bff' : '#6c757d',
                            borderRadius: '5px', // Optional for rounded corners on hover
                            padding: '5px', // Optional for padding on hover
                            cursor: 'pointer', // Optional to show hover cursor
                            transition: 'background-color 0.2s ease-in-out', // Optional for smooth transition
                        }}
                        onMouseEnter={() => {
                            // Update the style directly using inline styles
                            document.querySelector('#integrity-tab').style.color = '#007bff';
                        }}
                        onMouseLeave={() => {
                            // Restore the original color based on selectedTab state
                            document.querySelector('#integrity-tab').style.color =
                                selectedTab === 'Integrity' ? '#007bff' : '#6c757d';
                        }}
                        id="integrity-tab" 
                    >
                        {t("pages.aboutUs.whoWeAre.tab2.title1")}
                        </span>}>
                    <div style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '10px', marginTop: '10px' }}>
                        <HorizontalCard
                            imageUrl="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FHandshake.jpg?alt=media&token=c07b42fc-2a04-43a5-a8ef-0b6924bbab83"
                            icon={FaUserShield}
                            title={t("pages.aboutUs.whoWeAre.tab2.title2")}
                            info={t("pages.aboutUs.whoWeAre.tab2.content")}
                        />
                        </div>
                </Tab>


                <Tab eventKey="Community"
                    title={
                        <span
                            style={{
                                fontSize: '20px',
                                color: selectedTab === 'Community' ? '#007bff' : '#6c757d',
                                borderRadius: '5px', // Optional for rounded corners on hover
                                padding: '5px', // Optional for padding on hover
                                cursor: 'pointer', // Optional to show hover cursor
                                transition: 'background-color 0.2s ease-in-out', // Optional for smooth transition
                            }}
                            onMouseEnter={() => {
                                // Update the style directly using inline styles
                                document.querySelector('#community-tab').style.color = '#007bff';
                            }}
                            onMouseLeave={() => {
                                // Restore the original color based on selectedTab state
                                document.querySelector('#community-tab').style.color =
                                    selectedTab === 'Community' ? '#007bff' : '#6c757d';
                            }}
                            id="community-tab" 
                        >
                            {t("pages.aboutUs.whoWeAre.tab3.title1")}
                        </span>}>
                    <div style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '10px', marginTop: '10px' }}>
                        <HorizontalCard
                            imageUrl="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FConnectedWorld.jpg?alt=media&token=5375376c-3293-4b09-99cd-e48e86bd41ab"
                            icon={FaGlobe}
                            title={t("pages.aboutUs.whoWeAre.tab3.title2")}
                            info={t("pages.aboutUs.whoWeAre.tab3.content")}
                            />
                    </div>
                </Tab>
                <Tab eventKey="Innovation"
                    title={
                        <span
                            style={{
                                fontSize: '20px',
                                color: selectedTab === 'Innovation' ? '#007bff' : '#6c757d',
                                borderRadius: '5px', // Optional for rounded corners on hover
                                padding: '5px', // Optional for padding on hover
                                cursor: 'pointer', // Optional to show hover cursor
                                transition: 'background-color 0.2s ease-in-out', // Optional for smooth transition
                                background:'transparent'

                            }}
                            onMouseEnter={() => {
                                // Update the style directly using inline styles
                                document.querySelector('#innovation-tab').style.color = '#007bff';
                            }}
                            onMouseLeave={() => {
                                // Restore the original color based on selectedTab state
                                document.querySelector('#innovation-tab').style.color =
                                    selectedTab === 'Innovation' ? '#007bff' : '#6c757d';
                            }}
                            id="innovation-tab" 
                        >
                            {t("pages.aboutUs.whoWeAre.tab4.title1")}
                        </span>}>
                    <div style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '10px', marginTop: '10px' }}>
                        <HorizontalCard
                            imageUrl="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FLawSymbol.jpg?alt=media&token=1ad1a7d0-b15d-41d2-85ee-14835eab9109"
                            icon={FaLightbulb}
                            title={t("pages.aboutUs.whoWeAre.tab4.title2")}
                            info={t("pages.aboutUs.whoWeAre.tab4.content")}
                        />
                    </div>
                </Tab>
            </Tabs>

            <Row className="mt-5 mb-5">
            </Row>
        </Container>
    );
};

export default MyWhoWeAre;
