import { useContext, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { UserContext } from '../../App';
import LoginModal from '../LogInModal';
import SignupModal from '../SignUpModal';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import AppFirebase from '../../App.js';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

function MyProvideAServiceButton() {

    const { t } = useTranslation();

    const db = getFirestore(AppFirebase);

    const navigate = useNavigate();

    const user = useContext(UserContext);
    const [showOffcanvas, setShowOffcanvas] = useState(false);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(false);

    const handleShowOffcanvas = () => {
        setShowOffcanvas(true);
    };

    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    };

    const handleSubmit = async () => {
        if (user) {

            setLoading(true);
            const docRef = doc(db, "userInfo", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();

                if (userData.firstApplicationDone && !userData.provideAServiceApplication) {
                    alert(t("pages.provideAService.provideAServiceButton.errors.error1"));
                } else {
                    if (userData.profileLawyer === "Lawyer" || userData.profileLawyer === "Paralegal") {
                        setLoading(false);
                        navigate("/ProvideAServiceApply", { state: { key: "Lawyer" } });
                    } else {
                        setLoading(false);
                        navigate("/ProvideAServiceApply", { state: { key: "Law Firm" } });
                    }
                }
                setLoading(false);
            } else {
                console.log("No such document!");
            }
        } else {
            handleShowOffcanvas();
        }
    }



    return (
        <>

            <Button
                variant="outline-primary"
                className="justify-content-center d-flex align-items-center"
                onClick={handleSubmit}
                style={{ fontSize: "20px", fontFamily: 'Lato' }}
                disabled={loading}
                size="lg"
            >
                {loading ? <Spinner animation="border" variant="primary" /> : <p className="mb-2 mt-2">{t("pages.provideAService.provideAServiceButton.title")}</p>}
            </Button>

            <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="top" backdrop={true} style={{ fontFamily: 'Lato' }}>
                <Offcanvas.Header closeButton style={{ backgroundColor: 'rgb(13,110,253)' }}>
                    <Offcanvas.Title className="d-flex justify-content-center align-items-center w-100"><p style={{ color: "white", fontSize: '18px', fontWeight: "bold" }}>{t("pages.provideAService.provideAServiceButton.offCanvas.title")}</p></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ backgroundColor: 'rgb(224 233 240 / 66%)' }}>
                    <p className="text-center mb-4" style={{ fontSize: '16px' }}>
                        {t("pages.provideAService.provideAServiceButton.offCanvas.text")}
                    </p>

                    <Row className="text-center">
                        <Col md={1} xs={1}></Col>
                        <Col md={4} xs={4} className="mb-3">
                            <Card className="mb-3 mt-4 border-0" style={{ backgroundColor: 'rgb(13,110,253)' }}>
                                <SignupModal />
                            </Card>
                        </Col>
                        <Col md={2} xs={2}></Col>
                        <Col md={4} xs={4} className="mb-3">
                            <Card className="mb-3 mt-4 border-0" style={{ backgroundColor: 'rgb(13,110,253)' }}>
                                <LoginModal />
                            </Card>
                        </Col>
                        <Col md={1} xs={1}></Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default MyProvideAServiceButton;
