import React, { useEffect, useState, useReducer } from 'react';
import { Table, Input, Icon, Button, Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import AdminMenu from "./AdminMenu";
import { Col, Row, Container } from 'react-bootstrap';
import AppFirebase from '../App.js';
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import Spinner from 'react-bootstrap/Spinner';
import { getFunctions, httpsCallable } from "firebase/functions";
import _ from 'lodash';
import JSZip from 'jszip';


function AdminPage() {
    const db = getFirestore(AppFirebase);

    const [allData, setAllData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedWorkPlace, setSelectedWorkPlace] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});
    const storage = getStorage();
    const functions = getFunctions();
    const sendApprovalPASASMail = httpsCallable(functions, 'sendApprovalPASASMail');

    //Static Data
    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsprofileLawyer, setOptionsprofileLawyer] = useState([]);
    const [optionsOnSiteRemote, setOptionsOnSiteRemote] = useState([]);

    const [sortState, dispatchSort] = useReducer(exampleReducer, {
        column: null,
        direction: null,
    });

    const getAllDataOnce = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "userInfo"));
            const data = querySnapshot.docs.map((doc) => {
                const itemData = doc.data();
                // Assuming uid is part of the document's data
                return { ...itemData, uid: doc.id };
            });
            setAllData(data);
            //console.log("data:",allData)
            dispatchSort({ type: 'CHANGE_SORT', column: 'state' });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        getAllDataOnce();
        fetchStaticData();
    }, []);

    const handleSort = (column) => {
        dispatchSort({ type: 'CHANGE_SORT', column });
    };

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const handleProfileChange = (e, { value }) => {
        setSelectedProfiles(value);
    };
    const handleCountryChange = (e, { value }) => {
        setSelectedCountry(value);
    };

    const handleWorkPlaceChange = (e, { value }) => {
        setSelectedWorkPlace(value);
    };

    const fetchStaticData = async () => {
        try {
            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);
            //console.log("Static info : ", docSnap.data());

            setOptionsCountry(docSnap.data().optionsCountry.map(option => ({
                key: option.key,
                text: option.label,
                value: option.value,
            })));
            setOptionsprofileLawyer(docSnap.data().profileLawyer.map(option => ({
                key: option.key,
                text: option.label,
                value: option.value,
            })))
            setOptionsOnSiteRemote(docSnap.data().optionsOnSiteRemote.map(option => ({
                key: option.key,
                text: option.label,
                value: option.value,
            })))
            //console.log(optionsOnSiteRemote);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleToggleVerified = async (item) => {
        try {
            //console.log('Item:', item);

            // Make sure the item has a uid property
            if (!item || !item.uid) {
                console.error("Invalid item object");
                return;
            }

            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [item.uid]: true,
            }));

            const userDocRef = doc(db, "userInfo", item.uid);
            const userDoc = await getDoc(userDocRef);

            //console.log('User Document:', userDoc);

            if (userDoc.exists()) {
                const currentVerifiedStatus = userDoc.data().provideAServiceApplication || false;

                // Use updateDoc to update only the 'verified' field
                await updateDoc(userDocRef, {
                    provideAServiceApplication: !currentVerifiedStatus
                });

                if (!currentVerifiedStatus === true) {
                    const result = await sendApprovalPASASMail({ email: userDoc.data().emailAddress, userId: item.uid })
                    console.log("mail is sent !");
                }

                // Update the local state to reflect the change
                setAllData((prevData) =>
                    prevData.map((dataItem) =>
                        dataItem.uid === item.uid
                            ? { ...dataItem, provideAServiceApplication: !currentVerifiedStatus }
                            : dataItem
                    )
                );
            } else {
                console.log("User document not found");
            }
        } catch (error) {
            console.error("Error toggling verification:", error);
        } finally {
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [item.uid]: false,
            }));
        }
    };





    const filteredData = allData.filter((item) => {
        const lowerCasedLastName = (item.lastName || '').toLowerCase();
        const matchesSearchTerm = lowerCasedLastName.includes(searchTerm.toLowerCase());
        const matchesProfile =
            selectedProfiles.length === 0 || selectedProfiles.includes(item.profileLawyer);
        const matchesCountry =
            selectedCountry.length === 0 || selectedCountry.includes(item.country);
        const matchesWorkPlace =
            selectedWorkPlace.length === 0 || selectedWorkPlace.includes(item.workPlace);

        return matchesSearchTerm && matchesProfile && matchesCountry && matchesWorkPlace;
    });

    const sortedData = _.sortBy(filteredData, [sortState.column]);
    if (sortState.direction === 'descending') {
        sortedData.reverse();
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const tableHeader = (
        <Table.Header>
            <Table.Row>
                {columns.map((column) => (
                    <Table.HeaderCell
                        key={column}
                        onClick={() => handleSort(column)}
                        sorted={sortState.column === column ? sortState.direction : null}
                    >
                        {column}
                    </Table.HeaderCell>
                ))}
                <Table.HeaderCell>
                    Download
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );




    const handleDownload = async (item) => {
        const listRef = ref(storage, `users/${item.uid}`);
        try {
            const res = await listAll(listRef);

            const zip = new JSZip();

            for (const itemRef of res.items) {
                const url = await getDownloadURL(itemRef);

                // Fetch the file data
                const response = await fetch(url);

                const data = await response.blob();

                // Add the file to the ZIP archive with a specific content type
                zip.file(itemRef.name+".pdf", data, { binary: true, compression: 'DEFLATE', mimeType: 'application/pdf' });
            }

            // Generate a blob containing the ZIP archive
            const zipBlob = await zip.generateAsync({ type: 'blob' });

            // Create a download link for the ZIP archive
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(zipBlob);
            downloadLink.download = item.lastName+ "_"+ item.uid + '.zip';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

        } catch (error) {
            // Handle errors related to listing files
            console.error('Error listing files:', error);

            // You may want to add further error handling here if needed
        }
    };




    const tableBody = (

        <Table.Body>
            {currentItems.map((item, index) => (
                <Table.Row key={index}>
                    {columns.map((column) => (
                        <Table.Cell key={column}>
                            <>
                                {column === "Verified" ? (
                                    loadingStates[item.uid] ? (
                                        <Spinner animation="border" variant="primary" />
                                    ) : (
                                        <Icon
                                                name={item.provideAServiceApplication ? "check" : "close"}
                                                color={item.provideAServiceApplication ? "green" : "red"}
                                            onClick={() => handleToggleVerified(item)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) ) : column === "practiceAreas" || column === "industries" ? (

                                        item[column].join(', ')
                                ) : (
                                    item[column]
                                )}
                            </>
                            
                        </Table.Cell>
                    ))}
                    <Table.Cell>
                        <Button
                            icon="download"
                            onClick={(button) => handleDownload(item, button)}
                        />
                    </Table.Cell>
                </Table.Row>
            ))}
        </Table.Body>
    );


    const tableFooter = (
        <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan={columns.length} textAlign="center">
                    <Button.Group>

                        <Button
                            icon="angle left"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                        />
                        <Button
                            icon="angle right"
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        />
                        <Button
                            icon="angle double right"
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(totalPages)}
                        />
                    </Button.Group>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    );

    return (
        <Container  style={{ marginTop: "30px", marginBottom: "30px" }}>
            <Row className=" mb-3">
                <Col md={1}><AdminMenu /></Col>
                <Col><h2>Admin Center</h2>
                    <h3>Provide a Service Manager</h3> </Col>
            </Row>
            <Row className="mb-3">
                <Col md={3}>
                    <Input
                        icon="search"
                        placeholder="Search by last name..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        placeholder="Select Profile Type"
                        fluid
                        multiple
                        selection
                        options={optionsprofileLawyer}
                        onChange={handleProfileChange}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        placeholder="Select Country"
                        fluid
                        multiple
                        selection
                        options={optionsCountry}
                        onChange={handleCountryChange}
                    />
                </Col>
                <Col md={3}>
                    <Dropdown
                        placeholder="Select Work Place"
                        fluid
                        multiple
                        selection
                        options={optionsOnSiteRemote}
                        onChange={handleWorkPlaceChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ overflowX: "auto" }}>
                    <Table striped sortable>
                        {tableHeader}
                        {tableBody}
                        {tableFooter}
                        </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    );
}

const exampleReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_SORT':
            if (state.column === action.column) {
                return {
                    ...state,
                    direction:
                        state.direction === 'ascending' ? 'descending' : 'ascending',
                };
            }
            return {
                column: action.column,
                direction: 'ascending',
            };
        default:
            throw new Error();
    }
};

const columns = [
    "Verified",
    "firstName",
    "lastName",
    "emailAddress",
    "phoneNumber",
    "profileLawyer",
    "yearsExp",
    "workPlace",
    "minBudget",
    "industries",
    "practiceAreas",
    "country",
];

export default AdminPage;
