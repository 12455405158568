import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import MyContactUs from '../../ContactUs/ContactUs.js';
import MyIndividualAccordion from './IndividualAccordion.js';
import MyStartupAccordion from './StartupAccordion.js';
import MyOrganisationAccordion from './OrganisationAccordion';
import { useTranslation } from 'react-i18next';

const MyWhoDoWeHelp = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollElements = document.querySelectorAll('.scroll-transition');
            const windowHeight = window.innerHeight;

            scrollElements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                if (elementTop < windowHeight * 0.9) {
                    element.classList.add('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once


    return (
        <>
            <Container fluid className="mb-5" style={{ fontFamily: 'Lato' }}>
                <Row className="mb-5">
                    <div className="mt-5 mb-5 w-100">
                        <h1 className="ms-5" style={{ textAlign: 'center', fontSize: '36px' }}>{t("pages.whoWeHelp.title")}</h1>
                    </div>
                </Row>
                <Row>
                    <div style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white' }}>
                        <Row className="mt-5 mb-5">
                            <Col md={1}>
                            </Col>
                            <Col md={10}>
                                <p style={{ textAlign: 'left', fontSize: '40px' }}>
                                    {t("pages.whoWeHelp.text1")}
                                </p>
                                <p style={{ textAlign: 'left', fontSize: '22px' }}>
                                    {t("pages.whoWeHelp.text2")}
                                </p>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                    </div>

                </Row>
            </Container>
            <Container className="mt-5">
                <Row style={{ marginBottom: '100px', marginTop: '100px' }} className="scroll-transition">
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                        <Stack gap={3} >
                            <h2 className="mt-2">
                                {t("pages.whoWeHelp.individuals.title")}
                            </h2>
                            <div className="p-2">
                                <MyIndividualAccordion />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className="d-flex align-items-center justify-content-center">
                        <img
                            className="img-fluid shadow rounded"
                            src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FArabMenCity.jpg?alt=media&token=2462e43a-730b-48f9-aa7e-fe78a5c7f753"
                            alt="We help Individuals"
                        />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '100px' }} className="scroll-transition">
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }} className="d-flex align-items-center justify-content-center">
                        <img
                            className="img-fluid shadow rounded"
                            src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FCaucasianBusinessPeople2.jpg?alt=media&token=6ede9c7c-ea68-4830-a5ca-7b5f315fc875"
                            alt="We help Startups"
                        />
                    </Col>
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
                        <Stack gap={3}>
                            <h2 className="mt-2">
                                {t("pages.whoWeHelp.startups.title")}
                            </h2>
                            <div className="p-2">
                                <MyStartupAccordion />
                            </div>
                        </Stack>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '100px' }} className="scroll-transition">
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                        <Stack gap={3}>
                            <h2 className="mt-2">
                                {t("pages.whoWeHelp.corporations.title")}
                            </h2>
                            <div className="p-2">
                                <MyOrganisationAccordion />
                            </div>
                        </Stack>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className="d-flex align-items-center justify-content-center">
                        <img
                            className="img-fluid shadow rounded"
                            src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FCaucasianBusinessPeople3.jpg?alt=media&token=ef35c527-9710-48f9-a286-6308d0367bd6"
                            alt="Oops, content is not available."
                        />
                    </Col>
                </Row>

                <Row className="mb-5 scroll-transition">
                    <Col>
                        <MyContactUs />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default MyWhoDoWeHelp;