import React from 'react';
import { Button, Container, Row, Col, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./HomepageDesign.css";
import { useTranslation } from 'react-i18next'


const MyAboutUsHomepage = () => {
    const { t } = useTranslation();

    return (
        <Container className="w-100 bg-transparent border-0" style={{ backgroundBlendMode: "hard-light", marginTop: "50px", marginBottom: "50px", fontFamily: 'Lato' }} >
            <Row className="justify-content-center text-center mb-5">
                <Col xs={12}>
                    <h1>
                        {t("pages.homepage.aboutUsHomepage.title")}
                    </h1>
                </Col>
            </Row>

            <Row>
                <Col md={2}></Col>
                <Col md={2} className="mb-2 vertical-rule">
                    <Stack className="d-flex justify-content-center align-items-center mb-3 mt-3">
                        <div><h1>{t("pages.homepage.aboutUsHomepage.column1.line1")}</h1></div>
                        <div><h1>{t("pages.homepage.aboutUsHomepage.column1.line2")}</h1></div>
                        <div><h1>{t("pages.homepage.aboutUsHomepage.column1.line3")}</h1></div>
                    </Stack>
                </Col>

                <Col md={4}>
                    <Stack className="d-flex justify-content-center align-items-center mb-3 mt-3">
                        <div className="p-1"><h3>{t("pages.homepage.aboutUsHomepage.column2.line1")}</h3></div>
                        <div className="p-1"><h3>{t("pages.homepage.aboutUsHomepage.column2.line2")}</h3></div>
                        <div className="p-1"><h3>{t("pages.homepage.aboutUsHomepage.column2.line3")}</h3></div>
                    </Stack>

                </Col>
                <Col className="d-flex justify-content-center align-items-center mb-3 mt-3" md={2}>
                    <Link to="/AboutUs" style={{ display: 'block', width: '100%', height: '80%' }}>
                        <Button variant="outline-primary" size="lg"><p className="mb-2 mt-2" style={{ fontSize: '20px' }}>{t("pages.homepage.aboutUsHomepage.exploreButton")}</p></Button>
                    </Link>
                </Col>
                <Col md={1}></Col>
            </Row>
        </Container>
    );
};

export default MyAboutUsHomepage;
