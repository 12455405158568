
import { Button } from 'semantic-ui-react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';


function AdminMenu() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button circular style={{width:"80px"}} icon='settings' onClick={handleShow} />

            <Offcanvas show={show} onHide={handleClose} style={{ width: '300px' }} >
                <Offcanvas.Header style={{ background: '#0d78ae' }} closeButton>
                    <Offcanvas.Title style={{ color: 'aliceblue' }}>Admin Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ListGroup>
                        <Link to="/AdminWelcome">
                        <ListGroup.Item action  className="mb-2 rounded-0 border-0">

                            Admin Home

                            </ListGroup.Item>
                            </Link>
                        <hr />
                        <Link to="/Admin">
                        <ListGroup.Item action className=" mt-2 mb-2 rounded-0 border-0">
                            
                                PaS Manager
                            
                            </ListGroup.Item>
                        </Link>
                        <Link to="/AdminOperations">
                        <ListGroup.Item action  className="mb-2 rounded-0 border-0">
                            
                                FaL Manager
                            
                            </ListGroup.Item>
                        </Link>
                        <Link to="/AdminPageInvoice">
                        <ListGroup.Item action className="mb-2 rounded-0 border-0">

                            Invoice Sender

                            </ListGroup.Item>
                        </Link>

                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default AdminMenu;
