import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Modal, Button, Table, Spinner } from 'react-bootstrap';
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import AppFirebase from '../../App.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import { useTranslation } from 'react-i18next';


function MySelectionResult() {

    const { t } = useTranslation();

    const user = useContext(UserContext);

    const db = getFirestore(AppFirebase);
    const functions = getFunctions();
    const sendFLSASMail = httpsCallable(functions, 'sendFLSASMail');
    const sendFilterData = httpsCallable(functions, 'sendFilterData');

    const [staticData, setStaticData] = useState({});

    const [show, setShow] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [loading, setLoading] = useState(true); 

    const navigate = useNavigate();
     

    // Final sorted profiles
    const [finalUsers, setFinalUsers] = useState(Promise.resolve([]));

    // Selected users 
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    //Sets the filters that we will be using
    // Where should the work take place
    const [onSiteRemoteFilter, setOnSiteRemoteFilter] = useState('');

    // Stores the country of work
    const [remotePlaceOfWorkFilter, setRemotePlaceOfWorkFilter] = useState([]);

    // Stores the min experience requred for the job
    const [minExperienceFilter, setMinExperienceFilter] = useState(0);

    // Stores the min budget
    const [minBudgetFilter, setMinBudgetFilter] = useState(0);

    // Stores all industries 
    const [industriesFilter, setIndustriesFilter] = useState([]);

    // Stores all practice areas 
    const [practiceAreasFilter, setPracticeAreasFilter] = useState([]);

    // Stores demanded profile
    const [typeLegalSupportFilter, setTypeLegalSupportFilter] = useState('');

    // Stores the number of min hours per week
    const [workHoursWeekFilter, setWorkHoursWeekFilter] = useState(0);

    // Stores the min time when the work shoudl start
    const [workStartFilter, setWorkStartFilter] = useState(0);

    // Stores the work duration 
    const [workDurationFilter, setWorkDurationFilter] = useState(0);
    // eslint-disable-next-line
    const [usersId, setUsersId] = useState([]);

    function retrieveLabelByValue(dictionaryArray, value) {
        for (const dictionary of dictionaryArray) {
            if (dictionary.value === value) {
                return dictionary.label;
            }
        }
        return null; // Return null if value is not found
    };

    const { findALawyerQueryRefId } = useLocation().state;

    //Execute scoring to retrieve best 6 profiles
    useEffect(() => {

        const fetchFilterData = async () => {
            try {
                //Fetch data that will be used for filtering

                //console.log("findALawyerQueryId : ", findALawyerQueryRefId);
                const db = getFirestore(AppFirebase);
                const docRef = doc(db, "userInfo", user.uid, "findALawyerQuery", findALawyerQueryRefId);
                const docSnap = await getDoc(docRef);
                //Fetch all filtering data here
                //console.log("Document data : ", docSnap.data());
                /* console.log("Fetched document data:", docSnap.data());*/
                setTypeLegalSupportFilter(docSnap.data().typeLegalSupport);

                let tempArray = docSnap.data().industries.map(item => item.value);
                setIndustriesFilter(tempArray);

                tempArray = docSnap.data().practiceAreas.map(item => item.value);
                setPracticeAreasFilter(tempArray);

                setMinExperienceFilter(docSnap.data().experienceBudget.experience.value);
                setMinBudgetFilter(docSnap.data().experienceBudget.budget.value);

                if (docSnap.data().onSiteRemote === "Remote") {
                    let tempArray = docSnap.data().remotePlaceOfWork.map(item => item.value);
                    setRemotePlaceOfWorkFilter(tempArray);


                } else if (docSnap.data().onSiteRemote === "Onsite") {
                    let tempArray = [docSnap.data().businessAddress.country.value];
                    setRemotePlaceOfWorkFilter(tempArray);
                } else {
                    let tempArray = docSnap.data().remotePlaceOfWork.map(item => item.value);
                    setRemotePlaceOfWorkFilter(tempArray);
                }
                setOnSiteRemoteFilter(docSnap.data().onSiteRemote);
                setWorkHoursWeekFilter(docSnap.data().timeFrame.workHoursWeek.value);
                setWorkStartFilter(docSnap.data().timeFrame.workStart.value);
                setWorkDurationFilter(docSnap.data().timeFrame.workTime.value);
            }
            catch (error) {
                console.log(error);
            }
        };



        const fetchData = async () => {
            try {
                const db = getFirestore(AppFirebase);
                const docRef = doc(db, "staticData", "staticDataV1");
                const docSnap = await getDoc(docRef);
                setStaticData(docSnap.data());


            } catch (error) {

                console.error("Error fetching document:", error);

            }
        };


        fetchFilterData();
        fetchData();
        //fetchUsers();

        setLoading(false);

    }, []);

    useEffect(() => {
        const fetchUsers = async () => {

            try {
                const users = await sendFilterDataToBackend();
                if (Array.isArray(users) && users.length > 0) {
                    setFinalUsers(users);
                    
                } else {
                    await sendFLSASMail({ appId: findALawyerQueryRefId });
                    navigate("/LawyerSearch/Confirmation");
                    console.log("else")
                }
                
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        

        // Attendre que toutes les variables soient initialis�es avant de d�clencher fetchData
        if (
            typeLegalSupportFilter !== "" &&
            industriesFilter.length > 0 &&
            practiceAreasFilter.length > 0 &&
            minExperienceFilter !== 0 &&
            minBudgetFilter !== 0 &&
            onSiteRemoteFilter !== '' &&
            workStartFilter !== 0 &&
            workDurationFilter !== 0
        ) {
            fetchUsers();
        }
    }, [typeLegalSupportFilter, industriesFilter, practiceAreasFilter, minExperienceFilter, minBudgetFilter, onSiteRemoteFilter, workStartFilter, workDurationFilter]);

    const sendFilterDataToBackend = async () => {
        try {
            
            const filterData = {
                typeLegalSupport: typeLegalSupportFilter,
                industries: industriesFilter,
                practiceAreas: practiceAreasFilter,
                minExperience: minExperienceFilter,
                minBudget: minBudgetFilter,
                onSiteRemote: onSiteRemoteFilter,
                workStart: workStartFilter,
                workDuration: workDurationFilter,
                
            };

            const result = await sendFilterData(filterData);
            return result.data.data;
        } catch (error) {
            console.error('Error sending filter data to backend:', error);
        }
    };



    const handleSelectUser = (userId) => {
        if (selectedUserIds.length < 2 || selectedUserIds.includes(userId)) {
            setSelectedUserIds((prevSelectedUserIds) => {
                if (prevSelectedUserIds.includes(userId)) {
                    return prevSelectedUserIds.filter((id) => id !== userId);
                } else {
                    return [...prevSelectedUserIds, userId];
                }
            });
        }
    };

    const handleClose = () => {
        setShow(false);
        setShowValidation(false);
    };
    const handleShow = () => setShow(true);

    const handleConfirm = () => {
        setShowValidation(true);

    };

    const handleCancel = () => {
        setShow(false)
        setShowValidation(false);
        navigate("/FindALawyer");
    };

    const [loadingPage, setLoadingPage] = useState(false);
    const handleSend = async () => {

        try {
            setLoadingPage(true);
            const findALawyerRef = doc(db, "userInfo", user.uid, "findALawyerQuery", findALawyerQueryRefId);
            await updateDoc(findALawyerRef, {
                "profilePreference": selectedUserIds,
                "profileResult": finalUsers.map(user => user.user.id) 
            });

            await sendFLSASMail({ appId: findALawyerQueryRefId });
            setLoadingPage(false);
            navigate("/LawyerSearch/Confirmation");
        } catch (error) {
            console.log('Error adding document:', error)
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
           // event.returnValue = 'Please confirm your choice. You will need to repeat the procedure.';
        };

        const handlePopState = () => {
            const confirmLeave = window.confirm('Are you sure you want to leave this page? Your changes may not be saved.');
            if (confirmLeave) {
                navigate('/');
                window.location.reload();
            } else {
                 // Stay on the current page
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);


    if (loading) {
        return <Spinner animation="border" />
    }

    function replaceEmptyString(value) {
        if (value === null || value.trim() === '') {
            return 'Undefined';
        }
        return value;
    }


    if (!loading) {


        return (
            <>
                <Modal show={showValidation} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }}>
                        <Modal.Title>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal1.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal1.text1")} <br />
                            {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal1.text2")}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={handleClose} disabled={loadingPage}>
                            {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal1.cancelButton")}
                        </Button>
                        <Button variant="outline-primary" onClick={handleSend} disabled={loadingPage}>
                            {loadingPage ? <Spinner animation="border" variant="primary" /> : t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal1.confirmButton")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header style={{ backgroundColor: 'rgb(220,53,69)', color: 'white', minHeight:'75px' }}>
                        <Modal.Title>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal2.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal2.text1")} <br />
                            {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal2.text2")}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={handleClose}>
                            {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal2.cancelButton")} 
                        </Button>
                        <Button variant="outline-danger" onClick={handleCancel}>
                            {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.modal2.confirmButton")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Container>
                    <style>
                        {`
                        .whiteBlueBackground {
                            background-color: #e6f3ff;
                        }

                        /* Style for the tables */
                        table {
                            border-radius: 10px; /* Rounded corners */
                            overflow: hidden; /* Ensure rounded corners are visible */
                            width: 100%; /* Make the table take full width */
                        }

                        /* Style for the table cells */
                        table td {
                            padding: 10px; /* Adjust padding as needed */
                            border: 1px solid #dee2e6; /* Add borders for cells */
                        }
                    `}
                    </style>

                    <Row className="mt-5 mb-5">
                        <Col>
                            {Array.isArray(finalUsers) && finalUsers.length > 0 ? (
                                <>
                                    <h1 className="mb-4">{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.header1")}</h1>
                                    <Card className="p-4">
                                        <p className="lead text-muted">
                                            {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.text1")}
                                        </p>
                                        <div className="profile-selection-info">
                                            <span className="info-text" style={{ color: 'green', fontSize: '2em', fontWeight: 'bold' }}>
                                                {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.text2")}
                                            </span>
                                        </div>
                                    </Card>
                                </>
                            ) : (
                                    <>
                                    </>
                            )}
                        </Col>
                    </Row>

                    <Row xs={1} sm={2} md={2} lg={3} className="mb-3 mt-3">
                        {Array.isArray(finalUsers) ? (
                            finalUsers.slice(0, 6).map((user, index) => (
                                <Col key={index}>
                                    <Card
                                        className={`mb-3 ${selectedUserIds.includes(user.user.id) ? 'whiteBlueBackground' : 'bg-white'
                                            }`}
                                        style={{
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow
                                            border: 'none', // No border
                                            borderRadius: '20px', // Optional: add some border-radius for a softer look
                                        }}
                                    >
                                        <Card.Body>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <Card.Title style={{ textDecoration: 'underline dotted', marginBottom: '5px', fontSize: '1.2em' }}>
                                                            <b>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.profile")} {index + 1}</b>
                                                        </Card.Title>
                                                    </div>
                                                    <div>
                                                        <Card.Title className="text-success" style={{ fontSize: '1.2em' }}>
                                                            <b>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.score")} {user.user.data.score} %</b>
                                                        </Card.Title>
                                                    </div>
                                                </div>
                                            </Card.Body>


                                            <Card.Body>
                                                <Container>
                                                    <Row>
                                                        {/*<Col>*/}
                                                        <Row className="text-start">
                                                            <span style={{ fontWeight: 'bold', fontSize: '1.5em', fontFamily: 'Roboto, sans-serif', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.header2")} </span>
                                                        </Row>


                                                        <Row className="mb-4">
                                                            <Table bordered hover style={{ marginBottom: '20px', borderCollapse: 'separate', borderSpacing: '0 15px' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', width: '30%', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.profile")}</td>
                                                                        <td>{replaceEmptyString(user.user.data.profileLawyer)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.experience")}</td>
                                                                        <td>{replaceEmptyString(retrieveLabelByValue(staticData.optionsYearsExp, user.user.data.yearsExp))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.placeOfWork")}</td>
                                                                        <td>{replaceEmptyString(user.user.data.workPlace)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.country")}</td>
                                                                        <td>{replaceEmptyString(user.user.data.country)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>


                                                        </Row>
                                                        {/* </Col>
                                                    <Col>*/}
                                                        <Row className="text-start">
                                                            <span style={{ fontWeight: 'bold', fontSize: '1.5em', fontFamily: 'Roboto, sans-serif', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.header3")}</span>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Table bordered hover style={{ marginBottom: '20px', borderCollapse: 'separate', borderSpacing: '0 15px' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', width: '30%', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.industries")}</td>
                                                                        <td>{replaceEmptyString(user.user.data.industries.join(', '))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.practiceAreas")}</td>
                                                                        <td>{replaceEmptyString(user.user.data.practiceAreas.join(', '))}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>


                                                        </Row>
                                                        {/* </Col>
                                                    <Col>*/}
                                                        <Row className="text-start">
                                                            <span style={{ fontWeight: 'bold', fontSize: '1.5em', fontFamily: 'Roboto, sans-serif', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.header4")}</span>
                                                        </Row>
                                                        <Row>
                                                            <Table bordered hover style={{ marginBottom: '20px', borderCollapse: 'separate', borderSpacing: '0 15px' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', width: '30%', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.budget")}</td>
                                                                        <td>{replaceEmptyString(retrieveLabelByValue(staticData.optionsBudget, user.user.data.minBudget))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.workDuration")}</td>
                                                                        <td>{replaceEmptyString(retrieveLabelByValue(staticData.optionsDuration, user.user.data.workDuration))}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontWeight: 'bold', color: '#3498db' }}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.workStart")}</td>
                                                                        <td>{replaceEmptyString(retrieveLabelByValue(staticData.optionsWorkStart, user.user.data.workStart))}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                        </Row>
                                                        {/* </Col>*/}
                                                    </Row>
                                                </Container>
                                            </Card.Body>

                                            <Button
                                                variant={selectedUserIds.includes(user.user.id) ? 'danger' : 'outline-primary'}
                                                onClick={() => handleSelectUser(user.user.id)}
                                                size='lg'
                                                disabled={
                                                    selectedUserIds.length >= 2 &&
                                                    !selectedUserIds.includes(user.user.id)
                                                }
                                            >
                                                {selectedUserIds.includes(user.user.id) ? t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.removeButton") : t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.selectButton")}
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        ) : (

                                <Card className="d-flex align-items-center justify-content-center p-5" style={{ width: '100%' }}>
                                <Spinner animation="border" variant="primary" size="lg" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                <Card.Text className="mt-3">Loading...</Card.Text>
                            </Card>

                        )}
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col>
                            <Button size='lg' variant='outline-danger' onClick={handleShow}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.cancelButton")}</Button>
                        </Col>
                        <Col>
                            <Button size='lg' variant='outline-primary' onClick={handleConfirm}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionResult.container.confirmButton")}</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };

};

export default MySelectionResult;