import * as React from 'react';
import { Box, Card, Stepper, Step, StepLabel, Typography, CardContent, Grid } from '@mui/material';
import { Button, Row, Col } from "react-bootstrap";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'



/*function MyAdvantages() {


    return (
        <React.Fragment>
            <Typography variant="h5" className="mb-4" align="center" gutterBottom style={{ fontFamily: 'Lato', fontWeight: 'bold', color: '#333' }}>You are now a member of the Lexreach legal service providers.</Typography>
            <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={4}>
                        <Card variant="outlined" sx={{ height: '100%', textAlign: 'center', background: 'linear-gradient(to bottom right, #E3F2FD, #FFFFFF)', color: '#333' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2Fworldwide.png?alt=media&token=90e07409-9ef4-423f-b27a-b981e1f0d6c9" alt="Global Customers" width="100" height="100" style={{ marginBottom: '10px' }} />
                                <Typography variant="h6" fontWeight="bold">Elevate your profile to a wide range of customers worldwide.</Typography>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card variant="outlined" sx={{ height: '100%', textAlign: 'center', background: 'linear-gradient(to bottom right, #FFEBEE, #FFFFFF)', color: '#333' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2Fnetworking.png?alt=media&token=5e606f7e-1fdf-4e19-9aeb-1e7223e61874" alt="Unleash Potential" width="100" height="100" style={{ marginBottom: '10px' }} />
                                <Typography variant="h6" fontWeight="bold">Unleash your full potential by allowing customers to fully benefit from your knowledge and expertise.</Typography>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card variant="outlined" sx={{ height: '100%', textAlign: 'center', background: 'linear-gradient(to bottom right, #E8F5E9, #FFFFFF)', color: '#333' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2Foptimize.png?alt=media&token=23fb4178-3ebc-4e31-b58b-c2b55b6b167f" alt="Optimize Resources" width="100" height="100" style={{ marginBottom: '10px' }} />
                                <Typography variant="h6" fontWeight="bold">Increase your revenue stream and optimize your profitability.</Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}*/

export default function MyProcessSteps() {
    const { t } = useTranslation();

    const steps = [t("pages.provideAService.processSteps.steps.step1"), t("pages.provideAService.processSteps.steps.step2"), t("pages.provideAService.processSteps.steps.step3")];
    const stepsContent = [
        t("pages.provideAService.processSteps.stepsContent.step1"),
        t("pages.provideAService.processSteps.stepsContent.step2"),
        t("pages.provideAService.processSteps.stepsContent.step3")
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    //Checks if the step is optional or nor
    const isStepOptional = (step) => {
        return step === 1;
    };

    //Checks if step is contained within the set() skipped
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    //To back
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //To next
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setSkipped(new Set());
    };

    return (
        <>
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '16px',
                boxShadow: 'none',
                background: 'linear-gradient(to bottom, #E0EAFC, #FFFFFF)',
            }}>
                <div className="mt-5 mb-5 justify-content-center align-items-center d-flex">
                    <Stepper activeStep={activeStep} alternativeLabel style={{ width: '90%', margin: '0 auto' }}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption"></Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps} StepIconProps={{ style: { fontSize: '30px' } }}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div>


                    <Box sx={{ minHeight: '200px' }} className="d-flex ms-5 me-5 mb-3 ">

                        <React.Fragment>
                            <Typography sx={{ height: '100%', mt: 2, mb: 1, fontSize: '18px', textAlign: 'justify', whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{ __html: stepsContent[activeStep].replace(/\n/g, '<br />') }}
                            />
                        </React.Fragment>

                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="ms-5 mb-5 me-5" >
                        <Button
                            variant="transparent"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            <FaArrowLeft color={activeStep === 0 ? 'grey' : 'blue'} style={{ fontSize: '24px' }} />
                        </Button>
                        <Button
                            variant="transparent"
                            onClick={activeStep === steps.length - 1 ? handleReset : handleNext}
                        >
                            <FaArrowRight color="blue" style={{ fontSize: '24px' }} />
                        </Button>
                    </div>
                </div>
            </Card>

        </>

    );
}
