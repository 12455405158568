import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import MyProgressBar from './ProgressBar.js';
import MyQuestionProfile from './QuestionProfile.js';
import MyQuestionOnsiteRemote from './QuestionOnsiteRemote.js';
import MyQuestionAdditionalInformation from './QuestionAdditionalInformation.js';
import MyQuestionBusinessAddress from './QuestionBusinessAddress.js';
import MyQuestionExperienceBudget from './QuestionExperienceBudget.js';
import MyQuestionIndustries from './QuestionIndustries.js';
import MyQuestionPracticeAreas from './QuestionPracticeAreas.js';
import MyQuestionRemotePlaceOfWork from './QuestionRemotePlaceOfWork.js';
import MyQuestionTimeFrame from './QuestionTimeFrame.js';
import MyQuestionTypeLegalSupport from './QuestionTypeLegalSupport.js';
import { useTranslation } from 'react-i18next';
import MyNotFoundPage from '../../Error/NotFoundPage/NotFound.js';
import { Modal, Button } from 'react-bootstrap';

const MyFindALawyerSearch = () => {

    const { t } = useTranslation();

    const [currentProgressValue, setCurrentProgressValue] = useState(0);
    const [selectedButtonProfile, setSelectedButtonProfile] = useState('');
    const [selectedButtonOnsiteRemote, setSelectedButtonOnsiteRemote] = useState('');
    const [inputBusinessAddress, setInputBusinessAddress] = useState({ country: {}, zip: '', state: '', city: {}, address1: '', address2: '' });
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [selectedPracticeAreas, setSelectedPracticeAreas] = useState([]);
    const [selectedRemotePlaceOfWork, setSelectedRemotePlaceOfWork] = useState([]); // Not used
    const [selectedButtonTypeLegalSupport, setSelectedButtonTypeLegalSupport] = useState('');
    const [selectedExperienceBudget, setSelectedExperienceBudget] = useState({ budget: {}, experience: {} });
    const [selectedTimeFrame, setSelectedTimeFrame] = useState({ workStart: {}, workTime: {}, workHoursWeek: {} });
    const [inputAdditionalInfo, setInputAdditionalInfo] = useState('');
    const [billingEmailAddress, setBillingEmailAddress] = useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [isRemote, setIsRemote] = React.useState(false);

    const { state } = useLocation();
    const resultData = state && state.resultData ? state.resultData : null;

    useEffect(() => {
        if (resultData !== null) {
            const {
                selectedButtonProfile,
                selectedButtonOnsiteRemote,
                inputBusinessAddress,
                selectedIndustries,
                selectedPracticeAreas,
                selectedRemotePlaceOfWork,
                selectedButtonTypeLegalSupport,
                selectedExperienceBudget,
                selectedTimeFrame,
                inputAdditionalInfo,
                billingEmailAddress,
                isRemote,
                modifyStep
            } = resultData;

            setCurrentProgressValue(modifyStep);
            setActiveStep(modifyStep);
            setSelectedButtonProfile(selectedButtonProfile);
            setSelectedButtonOnsiteRemote(selectedButtonOnsiteRemote);
            setInputBusinessAddress(inputBusinessAddress);
            setSelectedIndustries(selectedIndustries);
            setSelectedPracticeAreas(selectedPracticeAreas);
            setSelectedRemotePlaceOfWork(selectedRemotePlaceOfWork);
            setSelectedButtonTypeLegalSupport(selectedButtonTypeLegalSupport);
            setSelectedExperienceBudget(selectedExperienceBudget);
            setSelectedTimeFrame(selectedTimeFrame);
            setInputAdditionalInfo(inputAdditionalInfo);
            setBillingEmailAddress(billingEmailAddress);
            setIsRemote(isRemote);
        }
    }, [resultData]);


    const navigate = useNavigate();

    const createSelectedDataObject = () => {
        const selectedData = {
            selectedButtonProfile,
            selectedButtonOnsiteRemote,
            inputBusinessAddress,
            selectedIndustries,
            selectedPracticeAreas,
            selectedRemotePlaceOfWork,
            selectedButtonTypeLegalSupport,
            selectedExperienceBudget,
            selectedTimeFrame,
            inputAdditionalInfo,
            billingEmailAddress,
            isRemote
        };

        return selectedData;
    };

    //Those handling functions aim to extract information from child components

    const handleSelectedTimeFrame = (value) => {
        setSelectedTimeFrame(value);
    }
    const handleSelectedExperienceBudget = (value) => {
        setSelectedExperienceBudget(value);
    };
    const handleSelectedButtonProfile = (value) => {
        setSelectedButtonProfile(value);
    };

    const handleSelectedRemotePlaceOfWork = (value) => {
        setSelectedRemotePlaceOfWork(value);
    };


    const handleSelectedButtonOnsiteRemote = (value) => {
        setSelectedButtonOnsiteRemote(value);
        if (value === "Remote") {
            setIsRemote(true);
        } else if (value === "Onsite") {
            setIsRemote(false);
        } else {
            setIsRemote(true)
        }
    };
    const handleInputBusinessAddress = (value) => {
        setInputBusinessAddress(value);
    };
    const handleSelectedIndustries = (value) => {
        setSelectedIndustries(value);
    };
    const handleSelectedPracticeAreas = (value) => {
        setSelectedPracticeAreas(value);
    };
    const handleSelectedButtonTypeLegalSupport = (value) => {
        setSelectedButtonTypeLegalSupport(value);
    };

    const handleInputAdditionalInfo = (value) => {
        setInputAdditionalInfo(value);
    };

    const handleInputBillingEmail = (value) => {
        setBillingEmailAddress(value);
    };

    //To render different components
    const renderSwitch = (param) => {
        switch (param) {
            case 'QuestionOnsiteRemote':
                return <MyQuestionOnsiteRemote handleSelectedButtonOnsiteRemote={handleSelectedButtonOnsiteRemote} savedSelectedButton={selectedButtonOnsiteRemote} />;
            case 'QuestionProfile':
                return <MyQuestionProfile handleSelectedButtonProfile={handleSelectedButtonProfile} savedSelectedButton={selectedButtonProfile} />;
            case 'QuestionAdditionalInformation':
                return <MyQuestionAdditionalInformation handleInputAdditionalInfo={handleInputAdditionalInfo} savedInput={inputAdditionalInfo} handleInputBillingEmail={handleInputBillingEmail} savedInput2={billingEmailAddress} />;
            case 'QuestionBusinessAddress':
                return <MyQuestionBusinessAddress handleInputBusinessAddress={handleInputBusinessAddress} savedInput={inputBusinessAddress} />;
            case 'QuestionExperienceBudget':
                return <MyQuestionExperienceBudget handleSelectedExperienceBudget={handleSelectedExperienceBudget} savedInput={selectedExperienceBudget} />;
            case 'QuestionIndustries':
                return <MyQuestionIndustries handleSelectedIndustries={handleSelectedIndustries} savedOptions={selectedIndustries} />
            case 'QuestionPracticeAreas':
                return <MyQuestionPracticeAreas handleSelectedPracticeAreas={handleSelectedPracticeAreas} savedOptions={selectedPracticeAreas} />;
            case 'QuestionRemotePlaceOfWork':
                return <MyQuestionRemotePlaceOfWork handleSelectedRemotePlaceOfWork={handleSelectedRemotePlaceOfWork} savedOptions={selectedRemotePlaceOfWork} />;
            case 'QuestionTimeFrame':
                return <MyQuestionTimeFrame handleSelectedTimeFrame={handleSelectedTimeFrame} savedInput={selectedTimeFrame} />;
            case 'QuestionTypeLegalSupport':
                return <MyQuestionTypeLegalSupport handleSelectedButtonTypeLegalSupport={handleSelectedButtonTypeLegalSupport} savedSelectedButton={selectedButtonTypeLegalSupport} />;
            default:
                return <MyNotFoundPage />;
        }
    };

    const isNextButtonDisabled = () => {
        let tempArray = isRemote ? listQuestionsRemote : listQuestionsOnsite;
        let activeStepName = tempArray[activeStep]

        switch (activeStepName.name) {
            case "QuestionOnsiteRemote":
                return selectedButtonOnsiteRemote === '';
            case "QuestionProfile":
                return selectedButtonProfile === '';
            case 'QuestionBusinessAddress':
                if (Object.keys(inputBusinessAddress.country).length !== 0 && inputBusinessAddress.city.value !== "" && inputBusinessAddress.address1.trim() !=="") {
                    return false;
                } else {
                    return true;
                }

            case "QuestionIndustries":
                return selectedIndustries.length === 0;
            case 'QuestionTypeLegalSupport':
                return selectedButtonTypeLegalSupport === '';
            case 'QuestionPracticeAreas':
                return selectedPracticeAreas.length === 0;
            case 'QuestionTimeFrame':
                if (
                    Object.keys(selectedTimeFrame.workStart).length !== 0 &&
                    Object.keys(selectedTimeFrame.workTime).length !== 0
                ) {
                    return false;
                } else {
                    return true;
                }

            case 'QuestionExperienceBudget':
                if (
                    Object.keys(selectedExperienceBudget.budget).length !== 0 &&
                    Object.keys(selectedExperienceBudget.experience).length !== 0
                ) {
                    return false;
                } else {
                    return true;
                }

            default:
                return false;
        }
    };


    const listQuestionsOnsite = [
        { name: 'QuestionOnsiteRemote', progressValue: 10 },
        { name: 'QuestionProfile', progressValue: 10 },
        { name: 'QuestionBusinessAddress', progressValue: 20 },
        { name: 'QuestionIndustries', progressValue: 10 },
        { name: 'QuestionTypeLegalSupport', progressValue: 10 },
        { name: 'QuestionPracticeAreas', progressValue: 10 },
        { name: 'QuestionTimeFrame', progressValue: 10 },
        { name: 'QuestionExperienceBudget', progressValue: 10 },
        { name: 'QuestionAdditionalInformation', progressValue: 10 },
    ];

    const listQuestionsRemote = [
        { name: 'QuestionOnsiteRemote', progressValue: 10 },
        { name: 'QuestionProfile', progressValue: 10 },
        { name: 'QuestionIndustries', progressValue: 20 },
        { name: 'QuestionTypeLegalSupport', progressValue: 10 },
        { name: 'QuestionPracticeAreas', progressValue: 10 },
        { name: 'QuestionTimeFrame', progressValue: 10 },
        { name: 'QuestionExperienceBudget', progressValue: 10 },
        { name: 'QuestionAdditionalInformation', progressValue: 10 },
    ];


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Checks if the step is optional or nor
    const isStepOptional = (step) => {
        return step === 1;
    };

    //Checks if step is contained within the set() skipped
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    useEffect(() => {
        const calculateProgress = () => {
            const completedSteps = activeStep + 1; // Adding 1 to include the current step
            const totalSteps = isRemote ? listQuestionsRemote.length : listQuestionsOnsite.length;
            const progress = Math.floor((completedSteps / totalSteps) * 100);
            setCurrentProgressValue(progress);
        };

        calculateProgress();
    }, [activeStep, isRemote]);

    //To next
    const handleNext = () => {
        if (isRemote) {
            if (activeStep < 7) {
                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            } else {
                const selectedData = createSelectedDataObject();
                navigate("/LawyerSearch/Recap", { state: { selectedData } });

            }
        } else {
            if (activeStep < 8) {
                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            } else {
                const selectedData = createSelectedDataObject();
                navigate("/LawyerSearch/Recap", { state: { selectedData } });

            }
        }

    }

    const [show, setShow] = useState(false);

    const handleClose = () => {

        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleConfirm = () => {
        setShow(false);
        navigate("/FindALawyer");
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{ backgroundColor: 'rgb(220, 53,69)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title>{t("pages.findALawyerLoggedIn.findALawyerSearch.closeModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("pages.findALawyerLoggedIn.findALawyerSearch.closeModal.text1")} <br />
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.closeModal.text2")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose} sx={{ mr: 1 }}>
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.closeModal.closeButton")}
                    </Button>
                    <Button variant="outline-danger" onClick={handleConfirm}>
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.closeModal.confirmButton")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container>
                <Row className="mt-5 mb-5" >
                    <Col>
                        <MyProgressBar currentProgressValue={currentProgressValue} />
                    </Col>
                </Row>
                <Row className="mt-5 mb-5">
                    <Col>
                        {isRemote
                            ? renderSwitch(listQuestionsRemote[activeStep].name)
                            : renderSwitch(listQuestionsOnsite[activeStep].name)}
                    </Col>
                </Row>
                <Row className="mt-5 mb-5">
                    <Col>
                        <Button
                            variant="outline-danger"
                            onClick={handleShow}
                            size="lg"
                        >
                            {t("pages.findALawyerLoggedIn.findALawyerSearch.cancelButton")}
                        </Button>{' '}
                        <Button
                            variant="outline-secondary"
                            disabled={activeStep === 0}
                            onClick={() => handleBack()}
                            size="lg"
                        >
                            {t("pages.findALawyerLoggedIn.findALawyerSearch.backButton")}
                        </Button>

                    </Col>
                    <Col>
                        <Button variant="outline-primary"
                            onClick={() => handleNext()}
                            disabled={isNextButtonDisabled()}
                            size="lg"
                        >
                            {isRemote ? (activeStep === 8 - 1 ? t("pages.findALawyerLoggedIn.findALawyerSearch.finishButton") : t("pages.findALawyerLoggedIn.findALawyerSearch.nextButton")) : (activeStep === 9 - 1 ? t("pages.findALawyerLoggedIn.findALawyerSearch.finishButton") : t("pages.findALawyerLoggedIn.findALawyerSearch.nextButton"))}
                        </Button> {' '}
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default MyFindALawyerSearch;