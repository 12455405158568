import { Card, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./HomepageDesign.css";
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'


/*Object fit =cover ; we need to change picture size ration so that they stay all the same size  */
function MyWhoDoWeHelp() {

    const { t } = useTranslation();


    return (
        <Container style={{ marginBottom: "50px", fontFamily: 'Lato' }} >
            <Row>
                <Col md={4} className="mb-3 d-flex align-items-stretch">
                    <Card className='rounded' style={{ marginLeft: "10px", marginRight: "10px" }} >
                        <Card.Img variant="top" className='rounded' src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FArabMen2.jpg?alt=media&token=234c72a4-d4f1-4346-ba78-ddc3e8380227" style={{ height: "250px", objectFit: 'cover' }} />
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Card.Title style={{ fontSize: '20px', fontWeight: 'bold' }}> {t("pages.homepage.whoDoWeHelp.card1.title")}</Card.Title>
                            <Card.Text >
                                <br />
                                {t("pages.homepage.whoDoWeHelp.card1.text")}
                            </Card.Text>
                        </Card.Body>
                        <Link to={"/WhoDoWeHelp"} className="link-discover mb-3 ms-3" style={{ textAlign: 'left' }}>
                            {t("pages.homepage.whoDoWeHelp.card1.linkText")} <FaArrowRight />
                        </Link>
                    </Card>
                </Col>
                <Col md={4} className="mb-3 d-flex align-items-stretch">
                    <Card style={{ marginLeft: "10px", marginRight: "10px" }} className='rounded'>
                        <Card.Img variant="top" className='rounded' src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FTeamWorking.jpg?alt=media&token=dd2c633d-102f-40ac-878c-a08f72aedd06" style={{ height: '250px', objectFit: 'cover' }} />
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Card.Title style={{ fontSize: '20px', fontWeight: 'bold' }}>{t("pages.homepage.whoDoWeHelp.card2.title")}</Card.Title>
                            <Card.Text>
                                <br />
                                {t("pages.homepage.whoDoWeHelp.card2.text")}
                            </Card.Text>
                        </Card.Body>
                        <Link to={"/WhoDoWeHelp"} className="link-discover mb-3 ms-3" style={{ textAlign: 'left' }}>
                            {t("pages.homepage.whoDoWeHelp.card2.linkText")} <FaArrowRight />
                        </Link>
                    </Card>
                </Col>
                <Col md={4} className="mb-3 d-flex align-items-stretch">
                    <Card style={{ marginLeft: "10px", marginRight: "10px" }} className='rounded'>
                        <Card.Img className='rounded' variant="top" src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FContent%2FPeopleWalkingBusiness.jpg?alt=media&token=a9514574-9455-4a08-bce6-c0813ec79f3b" style={{ height: "250px", objectFit: 'cover' }} />
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Card.Title style={{ fontSize: '20px', fontWeight: 'bold' }}>{t("pages.homepage.whoDoWeHelp.card3.title")}</Card.Title>
                            <Card.Text>
                                <br />
                                {t("pages.homepage.whoDoWeHelp.card3.text")}
                            </Card.Text>
                        </Card.Body>
                        <Link to={"/WhoDoWeHelp"} className="link-discover mb-3 ms-3" style={{ textAlign: 'left' }}>
                            {t("pages.homepage.whoDoWeHelp.card3.linkText")} <FaArrowRight />
                        </Link>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default MyWhoDoWeHelp;