import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Container, Modal, Form} from 'react-bootstrap';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo, signOut, sendEmailVerification, signInWithCustomToken } from "firebase/auth"; 
import { doc, setDoc, getFirestore, getDoc, deleteDoc } from "firebase/firestore";
import AppFirebase from '../FirebaseConfig';
import './Homepage/HomepageDesign.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./../Navbar.css";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'
import { getFunctions, httpsCallable } from "firebase/functions";

function LoginModal() {

    const functions = getFunctions();
    const getlinkedInUserInfo = httpsCallable(functions, 'getLinkedInUserInfo');
    const getLinkedInAccessToken = httpsCallable(functions, 'getLinkedInAccessToken');
    const createLinkedInUser = httpsCallable(functions, 'createLinkedInUser');

    const { t } = useTranslation();


    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showAttention, setShowAttention] = useState(false)

    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false);
        resetState();
    };
    const handleShow = () => setShow(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false)

    const resetState = () => {
        setEmail("");
        setPassword("");
        setError("");
    };


    let auth = getAuth(); 

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        if (id === "email") {
            setEmail(value);
        }
        if (id === "password") {
            setPassword(value);
        }

    }


    const [resendCalled, setResendCalled] = useState(false);
    const handleCloseAttention = () => {
        setShowAttention(false);
    };

    const [countdown, setCountdown] = useState(0);

    const handleResendClick = async () => {
        try {
            if (!resendCalled) {
                setResendCalled(true);
                await sendEmailVerification(auth.currentUser);
                setShowAttention(true);
                setCountdown(60); // Set countdown to 60 seconds
                const intervalId = setInterval(() => {
                    setCountdown(prevCountdown => prevCountdown - 1); // Decrement countdown every second
                }, 1000);

                setTimeout(() => {
                    setResendCalled(false);
                    setShowAttention(false);
                    clearInterval(intervalId); // Clear interval when countdown reaches 0
                }, 60000);
            }
        } catch (error) {
            console.error("Error occurred while resending email:", error);
            // Handle the error (e.g., display error message to user)
        }
    };

    // Use useEffect to reset countdown when resendCalled changes
    useEffect(() => {
        if (resendCalled) {
            setCountdown(60);
        }
    }, [resendCalled]);

    const showEmailVerificationModal = async () => {
        handleClose();
        setShowAttention(true);
        return await new Promise((resolve) => {
            setTimeout(() => {
                setShowAttention(false);
                resolve(resendCalled ? "resend" : "close");
            }, 60000); // Simulating a delay of 3 seconds before closing the modal
        });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;
            if (user && !user.emailVerified) {
                setLoading(false);
                const action = await showEmailVerificationModal(auth);
                if (action === "resend") {
                    // Handle resend action
                    // Close everything

                    // Sign out user 
                    signOut(auth).then(() => {
                        // Sign-out successful.
                    }).catch((error) => {
                        // An error happened.
                    });
                } else {
                    // User closed the modal
                    // Sign out user 
                    signOut(auth).then(() => {
                        // Sign-out successful.
                    }).catch((error) => {
                        // An error happened.
                    });

                }
            } else {
                setLoading(false);
                handleClose();
                
                window.location.reload();
            }
        } catch (error) {
            console.error("Error:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };



    const handleGoogleSignIn = async () => {
        // Scope will be used if extra data is required
        //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    
                    const additionalUserInfo = getAdditionalUserInfo(result);

                    const displayName = user.displayName;
                    const [firstName = '', lastName = ''] = displayName.split(' ');

                    // Create if non existent a document in userInfo Collection
                    // Mail verification is not necessary as google sign in is trusted
                    const db = getFirestore(AppFirebase);
                    const docRef = doc(db, "userInfo", user.uid);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        // Nothing should happen. Just Log in
                    } else {

                        // Create profile in unserInfo
                        const data = {
                            additionalInfo: "",
                            addressLine1: "",
                            addressLine2: "",
                            billingEmailAddress: "",
                            city: "",
                            country: "",
                            emailAddress: user.email,
                            firstName: firstName,
                            lastName: lastName,
                            industries: [],
                            maxHoursPerWeek: "",
                            minBudget: "",
                            phoneNumber: "",
                            practiceAreas: [],
                            provideAServiceApplication: false,
                            profileLawyer: "Lawyer",
                            state: "",
                            verified: true,
                            workDuration: "",
                            workPlace: "",
                            workStart: "",
                            yearsExp: "",
                            zip: ""
                        };

                        await setDoc(doc(db, "userInfo", user.uid), data);

                        await setDoc(doc(db, "userInfo", user.uid, "provideAServiceQuery", "initializing"), {});
                        await deleteDoc(doc(db, "userInfo", user.uid, "provideAServiceQuery", "initializing"));

                        await setDoc(doc(db, "userInfo", user.uid, "findALawyerQuery", "initializing"), {});
                        await deleteDoc(doc(db, "userInfo", user.uid, "findALawyerQuery", "initializing"));

                    }

                    handleClose();
                    navigate('/');

                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });
    };

    //const LINKEDIN_CLIENT_ID = '78jzlxil95a8tb';
    //const LINKEDIN_CALLBACK_URL = 'https://lexpertswebsite.web.app';

    const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const LINKEDIN_CALLBACK_URL = process.env.REACT_APP_LINKEDIN_CALLBACK_URL;


    const generateOAuthURL = () => { 
        const generateState = () => {
            return Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);
        };
        const state = generateState();
        const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(LINKEDIN_CALLBACK_URL)}&scope=openid%20email%20profile&state=${state}`;
        window.location.href = linkedinOAuthURL;
    }
    const handleLinkedInCallback = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        if (code) {
            //console.log("entered")
            const accessToken = await getLinkedInAccessToken({ code: code });
            //console.log(accessToken)
            const userInfo = await getlinkedInUserInfo({ token: accessToken.data });
            //console.log(userInfo)
            const user = await createLinkedInUser({ userInfo: userInfo.data });
            //console.log(user);

            const userCredential = await signInWithCustomToken(auth, user.data.customToken)
            //console.log(userCredential);
            
            const displayName = userCredential.user.displayName;
            const [firstName = '', lastName = ''] = displayName.split(' ');

            // Create if non existent a document in userInfo Collection
            // Mail verification is not necessary as google sign in is trusted
            const db = getFirestore(AppFirebase);
            const docRef = doc(db, "userInfo", userCredential.user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // Nothing should happen. Just Log in
            } else {

                // Create profile in unserInfo
                const data = {
                    additionalInfo: "",
                    addressLine1: "",
                    addressLine2: "",
                    billingEmailAddress: "",
                    city: "",
                    country: "",
                    emailAddress: userCredential.user.email,
                    firstName: firstName,
                    lastName: lastName,
                    industries: [],
                    maxHoursPerWeek: "",
                    minBudget: "",
                    phoneNumber: "",
                    practiceAreas: [],
                    provideAServiceApplication: false,
                    profileLawyer: "Lawyer",
                    state: "",
                    verified: true,
                    workDuration: "",
                    workPlace: "",
                    workStart: "",
                    yearsExp: "",
                    zip: ""
                };

                await setDoc(doc(db, "userInfo", userCredential.user.uid), data);

                await setDoc(doc(db, "userInfo", userCredential.uid, "provideAServiceQuery", "initializing"), {});
                await deleteDoc(doc(db, "userInfo", userCredential.user.uid, "provideAServiceQuery", "initializing"));

                await setDoc(doc(db, "userInfo", userCredential.user.uid, "findALawyerQuery", "initializing"), {});
                await deleteDoc(doc(db, "userInfo", userCredential.user.uid, "findALawyerQuery", "initializing"));
                
            }
            window.location.reload();
            navigate('/');
            

        }
           
    };

    useEffect(() => {
        handleLinkedInCallback();
    }, []);








    return (
        <>
            <Button className="custom-button" variant="transparent" onClick={handleShow}  >
                <p style={{ fontSize: '18px', fontWeight: 'bold', color: "white" }}>{t("pages.loginComponent.title")}</p>
            </Button>

            <Modal show={showAttention} onHide={handleCloseAttention} backdrop="static" keyboard={false}>
                <Modal.Header style={{ background: '#0d78ae',color: 'white', minHeight:'75px'  }}>
                    <Modal.Title> {t("pages.loginComponent.resendMailTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t("pages.loginComponent.resendMailContent")}
                    </p>
                    {resendCalled && (
                        <p style={{ color: 'green' }}>Email has been resent. Please check your inbox.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleResendClick} disabled={resendCalled}>
                        {t("pages.loginComponent.buttons.resendMail")} {countdown > 0 && `in ${countdown} sec`}
                    </Button>
                    <Button variant="outline-secondary" onClick={handleCloseAttention}>
                        {t("pages.loginComponent.buttons.close")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:"75px" }} closeButton>
                    <Modal.Title>{t("pages.loginComponent.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={() => handleClose()}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("pages.loginComponent.emailAddress.label")} <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                id="email"
                                type="email"
                                name='email'
                                placeholder={t("pages.loginComponent.emailAddress.placeholder")}
                                value={email}
                                onChange={(e) => handleInputChange(e)}
                                autoFocus
                            />
                        </Form.Group>


                        <div className="d-flex align-items-center"> {/* Use flexbox to align items vertically */}
                            <div className="flex-grow-1"> {/* Use flex-grow-1 to make the Form.Control take most of the space */}
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("pages.loginComponent.password.label")} <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <div className="d-flex align-items-center"> {/* Nested flexbox container to align items vertically */}
                                        <Form.Control
                                            id="password"
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder={t("pages.loginComponent.password.placeholder")}
                                            value={password}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        <Button variant="transparent" onClick={() => setShowPassword(!showPassword)}> {/* Toggle show/hide password */}
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>

                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {t("pages.loginComponent.password.feedback")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>

                        {error !== "" && (
                            <Form.Group className="mb-3">
                                <Form.Label style={{ color: 'red' }}>{error}</Form.Label>
                            </Form.Group>
                        )}
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <Link to={"/PasswordReset"} className="forgot-password-link" target="_blank">
                            <p>{t("pages.loginComponent.password.forgotPassword")}</p>
                        </Link>

                        </div>
                    </Form>
                </Modal.Body>
               
                <Modal.Footer>
                    <Container>
                        <Row className="mb-1">
                            <div className="d-flex justify-content-center align-items-center">
                                <Button className="btn-sec me-5" variant="outline-secondary" onClick={handleClose} disabled={loading} size="lg">
                                    <p>{t("pages.loginComponent.buttons.close")}</p>
                                </Button>
                                <Button className="btn-sec ms-5" variant="outline-primary" onClick={handleSubmit} disabled={loading} size="lg">
                                    {loading ? <Spinner animation="border" variant="primary" /> : <p>{t("pages.loginComponent.buttons.confirm")}</p>}
                                </Button>
                            </div>
                        </Row>
                        <hr />
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center flex-column">
                                <Button className="btn-sec w-75 mb-2" variant="outline-danger" onClick={handleGoogleSignIn} disabled={loading} size="lg">
                                    <span className="d-flex align-items-center justify-content-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FLogos%2FGoogle.png?alt=media&token=1194631a-18b5-451e-9156-e19e796648cd" alt="Google Icon" className="me-1" style={{ height: '20px', width: '20px' }} />
                                        {t("pages.loginComponent.buttons.google")}
                                    </span>
                                </Button>
                                <Button className="btn-sec w-75 mb-2" variant="outline-primary" onClick={generateOAuthURL} disabled={loading} size="lg">
                                    <span className="d-flex align-items-center justify-content-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FLogos%2FLinkedinColored.png?alt=media&token=17753492-57c3-4a7d-8442-474f08b2147f" alt="Linkedin Icon" className="me-1" style={{ height: '20px', width: '20px' }} />
                                        {t("pages.loginComponent.buttons.linkedin")}
                                    </span>
                                </Button>
                            </Col>
                        </Row>
                    </Container>


                </Modal.Footer>
            </Modal>
        </>

    );



}

export default LoginModal