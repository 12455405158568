import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const MyOurVision = () => {

    const { t } = useTranslation();

    return (
        
        <Card className="text-left w-100 border-0" style={{ '--bs-card-bg': 'rgb(144 134 134 / 0%)', fontFamily: 'Lato' }} >
            <Row >
                <Col md={2}></Col>
                <Col md={8}>
                    <blockquote className="blockquote text-right" style={{ fontSize: '25px', fontStyle: 'italic', fontWeight: 'bold' }}>
                        <p className="mb-3">
                            {t("pages.aboutUs.ourVision.text1")}
                        </p>
                        <footer className="blockquote-footer">{t("pages.aboutUs.ourVision.author1")} <cite title="Source Title">{t("pages.aboutUs.ourVision.author2")}</cite></footer>
                    </blockquote>
                </Col>
                <Col md={2}></Col>
            </Row>

        </Card>
    );
};

export default MyOurVision;