import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function MyQuestionTypeLegalSupport({ handleSelectedButtonTypeLegalSupport, savedSelectedButton }) {

    const { t } = useTranslation();

    const [selectedButton, setSelectedButton] = useState(savedSelectedButton);

    const handleButtonClick = (value) => {
        setSelectedButton(value);
        handleSelectedButtonTypeLegalSupport(value);
    };

    const buttonStyle = {
        width: '50%',
        height: '50px',
    };


    useEffect(() => {
        handleSelectedButtonTypeLegalSupport(selectedButton);
    }, [selectedButton]);

    return (
        <Container style={{marginBottom:'80px'} }>
            <Row className="mb-5">
                <Col>
                    <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.questionTypeLegalSupport.title")} <span style={{ color: 'red' }}>*</span></h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'Law Firm' ? 'btn-primary active' : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('Law Firm')}>
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.questionTypeLegalSupport.lawFirm")}
                    </button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'Lawyer' ? 'btn-primary active' : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('Lawyer')}
                    >
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.questionTypeLegalSupport.lawyer")}
                    </button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        className={`btn ${selectedButton === 'Paralegal'
                            ? 'btn-primary active'
                            : 'btn-light'
                            }`}
                        style={buttonStyle}
                        onClick={() => handleButtonClick('Paralegal')}
                    >
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.questionTypeLegalSupport.paralegal")}
                    </button>
                </Col>
            </Row>
        </Container>
    );
};

export default MyQuestionTypeLegalSupport;