import React, { useState } from 'react';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';
import { getFunctions, httpsCallable } from 'firebase/functions';
import AppFirebase from './../FirebaseConfig.js';
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';



function PasswordReset() {

    const { t } = useTranslation();

    const db = getFirestore(AppFirebase);
    const auth = getAuth()

    const functions = getFunctions();
    const sendPasswordResetMailOnly = httpsCallable(functions, 'sendPasswordResetMailOnly');


    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value); // Update email state when input changes
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            
            setIsLoading(true);
            setEmailSent(false);
            setError(false);

            await sendPasswordResetMailOnly({ mail: email });
            setEmailSent(true);
            //Success 
            setIsLoading(false);
        }
        catch (error) {
            //ERROR
            setIsLoading(false);
            setError(true);
            
        }
    };

    return (
        <div className="d-flex justify-content-center mt-5" style={{ minHeight: '100vh' }}>
            <Container style={{ maxWidth: '40%' }}>
                <Card className="shadow-sm">
                    <Card.Body>
                        <Card.Title className="text-center" style={{ fontFamily: 'Lato', fontSize: '24px', marginBottom: '20px' }}>{t("pages.passwordReset.standardForm.title")}</Card.Title>
                        <hr />
                        {emailSent ? (
                            <div>
                                <p>{t("pages.passwordReset.emailSent.text1")}{email}{t("pages.passwordReset.emailSent.text2")}</p>
                                <Button variant="primary" onClick={() => setEmailSent(false)}>{t("pages.passwordReset.emailSent.button")}</Button>
                            </div>
                        ) : error ? (
                            <div>
                                    <p>{t("pages.passwordReset.errors.error1")}</p>
                                    <Button variant="danger" onClick={() => setError(false)}>{t("pages.passwordReset.errors.button")}</Button>
                            </div>
                        ) : (
                            <div>
                                <Card.Text className="text-start" style={{ fontFamily: 'Lato', fontSize: '18px' }}>
                                    {t("pages.passwordReset.standardForm.text1")}
                                </Card.Text>
                                <Form>
                                    <Form.Group controlId="formBasicEmail" className="mb-4">
                                                <Form.Control type="email" placeholder={t("pages.passwordReset.standardForm.placeholderEmail")} style={{ fontSize: '16px' }} value={email} onChange={handleEmailChange} disabled={isLoading} />
                                    </Form.Group>
                                    <hr />
                                    <div className="d-flex justify-content-end">
                                        <Button variant="outline-primary" size="lg" onClick={handleSubmit} className="ms-2" style={{ fontSize: '16px' }} disabled={isLoading}>
                                                    {isLoading ? <Spinner animation="border" size="sm" /> : t("pages.passwordReset.standardForm.button") }
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

export default PasswordReset;
