import { Route, Routes } from "react-router-dom";
import MyFindALawyer from './FindALawyer.js';
import MyNotFoundPage from '../../Error/NotFoundPage/NotFound.js';
//import MyFindALawyerSearchRoutes from '../../LoggedIn/FindALawyer/FindALawyerSearchRoutes';

const MyFindALawyerRoutes = () => {
    return (
        <Routes>
            <Route index element={<MyFindALawyer />} />
            <Route path="*" element={<MyNotFoundPage />} />
        </Routes>
    );
};

export default MyFindALawyerRoutes;