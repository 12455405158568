import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import "./HomepageDesign.css";
import { useTranslation } from 'react-i18next'


const MyServiceSelectionHomepage = () => {

    const { t } = useTranslation();

    return (

        <Container fluid className="bg-image fluid" style={{ fontFamily: 'Lato' }}>
            <Row>
                <Col md={12} className="text-center mb-4">
                    <span className="text-center mb-4">
                        <h1 className="responsive-h1" style={{ color: 'white', fontWeight: 'bold' }}>
                            {t("pages.homepage.serviceSelectionHomepage.title")}
                            <hr />
                        </h1>
                        <h2 className="responsive-h2" style={{ color: 'white' }}>
                            {t("pages.homepage.serviceSelectionHomepage.subtitle1")}
                        </h2>
                        <h3 className="responsive-h3" style={{ color: 'white' }}>
                            {t("pages.homepage.serviceSelectionHomepage.subtitle2")}
                        </h3>
                    </span>
                </Col>
            </Row>

                <Row className="w-100">

                <Col md={6} className="mb-2">
                    <div className="vertical-rule">
                        <Link to="/FindALawyer">
                            <Button variant="outline-light" size="lg">
                                <p className="mb-2 mt-2" style={{ fontSize: '20px', fontWeight: 'bold' }}>{t("pages.homepage.serviceSelectionHomepage.secureLegalSupportButton")}</p>
                            </Button>
                        </Link>
                    </div>
                    </Col>

                <Col md={6} className="mb-2">
                    <div className="h-100">
                        <Link to="/ProvideAService">
                            <Button variant="outline-light" size="lg">
                                <p className="mb-2 mt-2" style={{ fontSize: '20px', fontWeight: 'bold' }}>{t("pages.homepage.serviceSelectionHomepage.provideLegalSupportButton")}</p>
                            </Button>
                        </Link>
                    </div>
                    </Col>
                </Row>
            </Container>
    );
};



export default MyServiceSelectionHomepage;
