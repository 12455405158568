import { useContext } from 'react';
import { UserContext } from '../../App';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getFirestore, getDocs, collection, query } from "firebase/firestore";
import AppFirebase from '../../App.js';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';


const MyUserHistory = () => {

    const { t } = useTranslation();
    const user = useContext(UserContext);
    const db = getFirestore(AppFirebase);

    const [allQueries, setAllQueries] = useState([]);


    const fetchFindALawyer = async () => {
        try {

            if (!user) {
                return;

            }

            const allQueries = query(collection(db, "userInfo", user.uid, "findALawyerQuery"));

            const querySnapshot = await getDocs(allQueries);

            let queriesData = [];

            querySnapshot.forEach((doc) => {
                let query = {
                    queryId: doc.id,
                    data: doc.data()
                }
                queriesData.push({ query });
            });


            // Assuming 'data' is your array of objects
            queriesData.sort((a, b) => {
                const dateA = a.query.data.queryCreationDate.seconds;
                const dateB = b.query.data.queryCreationDate.seconds;

                return dateB - dateA;

                // To sort in ascending order (oldest to newest), use dateA - dateB
                // To sort in descending order (newest to oldest), use dateB - dateA
            });
            //console.log(queriesData);

            setAllQueries(queriesData);
        } catch (error) {
            console.error("Error fetching document:", error);
        }
    };

    useEffect(() => {
        fetchFindALawyer();
    }, [user]);





    const rows = allQueries.map((item, index) => {
        const {
            query: {
                queryId,
                data: {
                    finished,
                    queryCreationDate,
                    profile,
                    typeLegalSupport,
                    onSiteRemote,
                    remotePlaceOfWork,
                    industries,
                    practiceAreas,
                    timeFrame: { workHoursWeek, workStart, workTime }
                }
            }
        } = item;

        const statusDisplay = finished ? <span style={{ color: 'green' }}>{t("pages.accountInformation.userHistory.completed")}</span> : <span style={{ color: 'red' }}>{t("pages.accountInformation.userHistory.inProgress")}</span>;


        return (
            <tr key={queryId}>
                <td style={{ width: '10%' }}>{statusDisplay}</td>
                <td style={{ width: '10%' }}>{new Date(queryCreationDate.seconds * 1000).toLocaleDateString()}</td>
                <td style={{ width: '10%' }}>{profile}</td>
                <td style={{ width: '10%' }}>{typeLegalSupport}</td>
                <td style={{ width: '10%' }}>{onSiteRemote}</td>
                <td style={{ width: '10%' }}>{remotePlaceOfWork.map(item => item.label).join(', ')}</td>
                <td style={{ width: '10%' }}>{industries[0].label}</td>
                <td style={{ width: '10%' }}>{practiceAreas[0].label}</td>
                <td style={{ width: '10%' }}>{workHoursWeek.label}</td>
                <td style={{ width: '10%' }}>{workStart.label}</td>
                <td style={{ width: '10%' }}>{workTime.label}</td>
            </tr>

        );
    });

    return (
        user ? (
            <Card className="user-profile">
                <>
                    <Card.Header className="text-start h2" style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:"75px" }}>
                        <Row className="d-flex align-items-center">
                            <Col md={12} className="mt-2 mb-2">
                                {t("pages.accountInformation.userHistory.title")}
                            </Col>
                        </Row>
                    </Card.Header>

                    <Card.Body className='text-center'>
                        {rows.length > 0 ? (
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>{t("pages.accountInformation.userHistory.status")}</th>
                                        <th>{t("pages.accountInformation.userHistory.dateApplication")}</th>
                                        <th>{t("pages.accountInformation.userHistory.profile")}</th>
                                        <th>{t("pages.accountInformation.userHistory.support")}</th>
                                        <th>{t("pages.accountInformation.userHistory.placeWork")}</th>
                                        <th>{t("pages.accountInformation.userHistory.country")}</th>
                                        <th>{t("pages.accountInformation.userHistory.industries")}</th>
                                        <th>{t("pages.accountInformation.userHistory.practiceAreas")}</th>
                                        <th>{t("pages.accountInformation.userHistory.hoursPerWeek")}</th>
                                        <th>{t("pages.accountInformation.userHistory.startDate")}</th>
                                        <th>{t("pages.accountInformation.userHistory.workDuration")}</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        ) : (
                                <p className="text-muted">{t("pages.accountInformation.userHistory.noApplication")}</p>
                        )}
                    </Card.Body>
                </>
            </Card>
        ) : (
            <Card className="d-flex align-items-center justify-content-center p-5">
                <Spinner animation="grow" variant="primary" size="lg" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <Card.Text className="mt-3">Loading...</Card.Text>
            </Card>

        )
    );

};

export default MyUserHistory;
