import { Row, Col, Modal, Button, Form, Card } from 'react-bootstrap';
import { UserContext } from '../../App';
import { useContext } from 'react';
import LoginModal from '../LogInModal'
import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SignupModal from '../SignUpModal';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import AppFirebase from '../../App.js';
import Select from 'react-select'; 
import { useTranslation } from 'react-i18next'

const functions = getFunctions();
const checkPersonalInfo = httpsCallable(functions, 'checkPersonalInfo');
const countApplicationsFunction = httpsCallable(functions, 'countApplications');

function MyFindALawyerButton() {
    const { t } = useTranslation();

    const [optionsCountry, setOptionsCountry] = useState([]);
    const [optionsCity, setOptionsCity] = useState([]);
    const [docSnapStatic, setDocSnapStatic] = useState(null);

    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [error, setError] = useState("");

    const user = useContext(UserContext);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    const cityDisplay = (country, docSnap) => {
        //setOptionsCity([{ label: "", value: "" }]);
        if (docSnap !== null) {
            switch (country) {
                case "United Arab Emirates":
                    setOptionsCity(docSnap.data().optionsCityUAE);
                    break;
                case "Qatar":
                    setOptionsCity(docSnap.data().optionsCityQAT);
                    break;
                case "Lebanon":
                    setOptionsCity(docSnap.data().optionsCityLBN);
                    break;
                case "Saudi Arabia":
                    setOptionsCity(docSnap.data().optionsCityKSA);
                    break;
                case "Kuwait":
                    setOptionsCity(docSnap.data().optionsCityKWT);
                    break;
                case "Jordan":
                    setOptionsCity(docSnap.data().optionsCityJOR);
                    break;
                case "Oman":
                    setOptionsCity(docSnap.data().optionsCityOMN);
                    break;
                case "Bahrain":
                    setOptionsCity(docSnap.data().optionsCityBHR);
                    break;
                case "Other...":
                    setOptionsCity([{ label: "Other...", value: "Other..." }]);
                    break;
                default:
                    setOptionsCity([{ label: "", value: "" }]);
            }
        }
    };

    useEffect(() => {
        // fetches required static data in account Info
        const fetchStaticData = async () => {
            try {
                const db = getFirestore(AppFirebase);
                const docRef = doc(db, "staticData", "staticDataV1");
                const docSnap = await getDoc(docRef);
                setDocSnapStatic(docSnap);

                const userRef = doc(db, 'userInfo', user.uid);
                const userSnapshot = await getDoc(userRef);

                const sortedOptionsCountry = docSnap.data().optionsCountry;

                // Sort the copied array alphabetically based on the label property
                sortedOptionsCountry.sort((a, b) => {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                });

                // Update state with the sorted array
                setOptionsCountry(sortedOptionsCountry);

                setCountry(userSnapshot.data().country);
                setCity(userSnapshot.data().city);
                setAddressLine1(userSnapshot.data().addressLine1);
                cityDisplay(userSnapshot.data().country, docSnap);

            } catch (error) {
                console.log(error.message);
            }

        };



        fetchStaticData();

    }, [user]);

    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    };

    const filledPersonalInfo = async () => {
        try {
            const result = await checkPersonalInfo();
            return result.data;
        } catch (error) {
        }
    };


    const countApplications = async () => {
        try {
            const result = await countApplicationsFunction();
            return result.data.numberApplications;
        } catch (error) {
        }
    };

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const filledPersonalInfoResult = await filledPersonalInfo();
        const countApplicationsResult = await countApplications();
        if (user !== null && user.emailVerified === true) {
            if (countApplicationsResult < 3) { 
                if (filledPersonalInfoResult === false) {
                    setLoading(false);
                    handleShow();
                } else {
                    setLoading(false);
                    navigate("/LawyerSearch");
    
                }
                
                setLoading(false);
            } else {
                setLoading(false);
                alert(t("pages.findALawyer.findALaywerButton.errors.error1"))
                
            }
        } else {
            setLoading(false);
            setShowOffcanvas(true);
        }
    }

    const handleContinue = async () => {
        if (user !== null) {
            if (country !== "" && city !== "" && addressLine1 !== "") {
                const db = getFirestore(AppFirebase);
                const userRef = doc(db, "userInfo", user.uid);

                await updateDoc(userRef, {
                    country: country,
                    city: city,
                    addressLine1: addressLine1

                });

                navigate("/LawyerSearch");
            } else {
                setError(t("pages.findALawyer.findALaywerButton.errors.error2"));
            }
        }
        else {
            setShowOffcanvas(true);
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton style={{ backgroundColor: 'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title>{t("pages.findALawyer.findALaywerButton.questionsModal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {t("pages.findALawyer.findALaywerButton.questionsModal.text1")}
                        <br /><br />
                        <strong>{t("pages.findALawyer.findALaywerButton.questionsModal.text2")}</strong> {t("pages.findALawyer.findALaywerButton.questionsModal.text3")}
                    </p>
                    <Form >
                        <Form.Group controlId="formCountry" className="mb-1">
                            <Form.Label>{t("pages.findALawyer.findALaywerButton.questionsModal.country")}</Form.Label>
                            <Select
                                options={optionsCountry}
                                defaultValue={optionsCountry.find(option => option.value === country)}
                                onChange={(selectedOption) => {
                                    setCountry(selectedOption.value);
                                    setCity("");
                                    cityDisplay(selectedOption.value, docSnapStatic)
                                    
                                }}
                                isSearchable={false}
                            />
                        </Form.Group>

                        <Form.Group controlId="formCity" className="mb-1">
                            <Form.Label>{t("pages.findALawyer.findALaywerButton.questionsModal.city")}</Form.Label>
                            <Select
                                options={optionsCity}
                                value={(city !== "" ? optionsCity.find(option => option.value === city) : "" )}
                                onChange={(selectedOption) => setCity(selectedOption.value)}
                                isSearchable={false}
                            />
                        </Form.Group>

                        <Form.Group controlId="formAddress" className="mb-1">
                            <Form.Label>{t("pages.findALawyer.findALaywerButton.questionsModal.address")}</Form.Label>
                            <Form.Control
                                value={addressLine1}
                                onChange={(e) => { setAddressLine1(e.target.value); }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        {t("pages.findALawyer.findALaywerButton.questionsModal.buttonCancel")}
                    </Button>
                    <Button variant="outline-primary" onClick={handleContinue} sx={{ mr: 1 }}>
                        {t("pages.findALawyer.findALaywerButton.questionsModal.buttonContinue")}
                    </Button>
                    {error && <div style={{ color: 'red', textAlign: 'center', width:'100%' }}>{error}</div>}
                </Modal.Footer>
                
            </Modal>

            <Row style={{ fontFamily: 'Lato' }}>
                <div>
                    <Button
                        variant="outline-primary"
                        style={{ fontSize: "20px" }}
                        onClick={handleSubmit}
                        disabled={loading} // Disable the button while loading
                        size="lg"
                    >
                        {loading ? <Spinner animation="border" variant="primary" /> : <p className="mb-2 mt-2">{t("pages.findALawyer.findALaywerButton.buttonTitle")}</p>}
                    </Button>
                </div>
            </Row>
            {user ? null : (
                <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="top" backdrop={true} style={{ fontFamily: 'Lato' }}>
                    <Offcanvas.Header closeButton style={{ backgroundColor: 'rgb(13,110,253)' }}>
                        <Offcanvas.Title className="d-flex justify-content-center align-items-center w-100"><p style={{ color: "white", fontSize: '18px', fontWeight: "bold" }}>{t("pages.findALawyer.findALaywerButton.offCanvas.title")}</p></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{ backgroundColor: 'rgb(224 233 240 / 66%)' }}>
                        <p className="text-center mb-4" style={{fontSize:'16px'}}>
                            {t("pages.findALawyer.findALaywerButton.offCanvas.text")}
                        </p>

                        <Row className="text-center">
                            <Col md={1} xs={1}></Col>
                            <Col md={4} xs={4} className="mb-3">
                                <Card className="mb-3 mt-4 border-0" style={{ backgroundColor: 'rgb(13,110,253)' }}>
                                    <SignupModal />
                                </Card>
                            </Col>
                            <Col md={2} xs={2}></Col>
                            <Col md={4} xs={4} className="mb-3">
                                <Card className="mb-3 mt-4 border-0" style={{ backgroundColor: 'rgb(13,110,253)' }}>
                                    <LoginModal />
                                </Card>
                            </Col>
                            <Col md={1} xs={1}></Col>
                        </Row>
                    </Offcanvas.Body>
                </Offcanvas>
            )}
        </>
    );
}

export default MyFindALawyerButton;
