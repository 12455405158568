import React, { useState, useEffect, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Row, Col, Card, Modal } from 'react-bootstrap';
import AppFirebase from '../../App.js';
import { getFirestore, doc, addDoc, serverTimestamp, collection, getDoc, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { UserContext } from '../../App';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';


function MySelectionRecap() {

    const { t } = useTranslation();

    const functions = getFunctions();
    const sendFLSASMail = httpsCallable(functions, 'sendFLSASMail');
    const sendFilterData = httpsCallable(functions, 'sendFilterData');

    const [show, setShow] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [staticData, setStaticData] = useState({});

    const user = useContext(UserContext);
    const db = getFirestore(AppFirebase);
    const storage = getStorage();

    const { selectedData } = useLocation().state;

    const selectedButtonProfile = selectedData.selectedButtonProfile;
    const selectedButtonOnsiteRemote = selectedData.selectedButtonOnsiteRemote;
    const inputBusinessAddress = selectedData.inputBusinessAddress;
    const selectedIndustries = selectedData.selectedIndustries;
    const selectedPracticeAreas = selectedData.selectedPracticeAreas;
    const selectedRemotePlaceOfWork = selectedData.selectedRemotePlaceOfWork;
    const selectedButtonTypeLegalSupport = selectedData.selectedButtonTypeLegalSupport;
    const selectedExperienceBudget = selectedData.selectedExperienceBudget;
    const selectedTimeFrame = selectedData.selectedTimeFrame;
    const inputAdditionalInfo = selectedData.inputAdditionalInfo;
    const billingEmailAddress = selectedData.billingEmailAddress;
    const isRemote = selectedData.isRemote;

    const [experienceDisplay, setExperienceDisplay] = useState("");
    const [budgetDisplay, setBudgetDisplay] = useState("");
    const [workStartDisplay, setWorkStartDisplay] = useState("");
    const [workDurationDisplay, setWorkDurationDisplay] = useState("");
    const [workHoursDisplay, setWorkHoursDisplay] = useState("");


    function retrieveLabelByValue(dictionaryArray, value) {
        for (const dictionary of dictionaryArray) {
            if (dictionary.value === value) {
                return dictionary.label;
            }
        }
        return null; // Return null if value is not found
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, "staticData", "staticDataV1");
                const docSnap = await getDoc(docRef);
                setStaticData(docSnap.data());
                //console.log(docSnap.data().optionsYearsExp);
                setExperienceDisplay(retrieveLabelByValue(docSnap.data().optionsYearsExp, selectedExperienceBudget.experience.value))
                setBudgetDisplay(retrieveLabelByValue(docSnap.data().optionsBudget, selectedExperienceBudget.budget.value));
                setWorkStartDisplay(retrieveLabelByValue(docSnap.data().optionsWorkStart, selectedTimeFrame.workStart.value));
                setWorkDurationDisplay(retrieveLabelByValue(docSnap.data().optionsDuration, selectedTimeFrame.workTime.value));
                setWorkHoursDisplay(retrieveLabelByValue(docSnap.data().optionsWorkHoursWeek, selectedTimeFrame.workHoursWeek.value));

            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        fetchData();
    }, []);


    let modifyStep;

    let [resultData, setResultData] = useState({});

    const createResultDataObject = () => {
        const newData = {
            selectedButtonProfile,
            selectedButtonOnsiteRemote,
            inputBusinessAddress,
            selectedIndustries,
            selectedPracticeAreas,
            selectedRemotePlaceOfWork,
            selectedButtonTypeLegalSupport,
            selectedExperienceBudget,
            selectedTimeFrame,
            inputAdditionalInfo,
            billingEmailAddress,
            isRemote,
            modifyStep
        };

        return newData;
    };

    const navigate = useNavigate();


    const handleModify = (field) => {
        if (isRemote) {
            switch (field) {
                case 'selectedButtonOnsiteRemote':
                    modifyStep = 0;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedButtonProfile':
                    modifyStep = 1;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedIndustries':
                    modifyStep = 2;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedButtonTypeLegalSupport':
                    modifyStep = 3;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedPracticeAreas':
                    modifyStep = 4;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedTimeFrame':
                    modifyStep = 5;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedExperienceBudget':
                    modifyStep = 6;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'inputAdditionalInfo':
                    modifyStep = 7;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                default:
                    break;
            }
        } else {
            switch (field) {
                case 'selectedButtonOnsiteRemote':
                    modifyStep = 0;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedButtonProfile':
                    modifyStep = 1;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedRemotePlaceOfWork':
                    modifyStep = 2;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'inputBusinessAddress':
                    modifyStep = 2;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedIndustries':
                    modifyStep = 3;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedButtonTypeLegalSupport':
                    modifyStep = 4;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedPracticeAreas':
                    modifyStep = 5;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedTimeFrame':
                    modifyStep = 6;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'selectedExperienceBudget':
                    modifyStep = 7;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                case 'inputAdditionalInfo':
                    modifyStep = 8;
                    resultData = createResultDataObject();
                    navigate('/LawyerSearch', { state: { resultData } });
                    break;
                default:
                    break;
            }
        }
    };

    const handleClose = () => {
        setShow(false);
        setShowValidation(false);
    };
    const handleShow = () => setShow(true);

    const handleConfirm = () => {
        setShowValidation(true);

    };

    const handleCancel = () => {
        setShow(false)
        setShowValidation(false);
        navigate("/FindALawyer");
    };

    const [loading, setLoading] = useState(false);

    const handleSend = async () => {

        try {
            setLoading(true);
            const docData = {
                //personal Information
                profileResult: [],
                profilePreference: [],
                queryCreationDate: serverTimestamp(),
                finished: false,
                profile: selectedButtonProfile,
                onSiteRemote: selectedButtonOnsiteRemote,
                businessAddress: inputBusinessAddress,
                industries: selectedIndustries,
                practiceAreas: selectedPracticeAreas,
                remotePlaceOfWork: selectedRemotePlaceOfWork,
                typeLegalSupport: selectedButtonTypeLegalSupport,
                experienceBudget: selectedExperienceBudget,
                timeFrame: selectedTimeFrame,
                additionalInfo: inputAdditionalInfo,
            }

            const findALawyerQueryRef = await addDoc(collection(db, "userInfo", user.uid, "findALawyerQuery"), docData);

            const findALawyerQueryRefId = findALawyerQueryRef.id;

            const userRef = doc(db, "userInfo", user.uid);
            await updateDoc(userRef, {
                billingEmailAddress: billingEmailAddress
            });


            setShowValidation(false);
            setLoading(false);
            navigate("/LawyerSearch/Result", { state: { findALawyerQueryRefId } });




        } catch (error) {
            console.log('Error adding document:', error)
            setLoading(false);
            setShowValidation(false);
        }
    };



    return (
        <>

            <Modal show={showValidation} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header style={{ backgroundColor:'rgb(13, 110, 253)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal1.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal1.text1")}<br />
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal1.text2")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose} sx={{ mr: 1 }} disabled={loading}>
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal1.cancelButton")}
                    </Button>
                    <Button variant="outline-primary" onClick={handleSend} disabled={loading}>
                        {loading ? <Spinner animation="border" variant="primary" /> : t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal1.confirmButton")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header style={{ backgroundColor: 'rgb(220,53,69)', color: 'white', minHeight:'75px' }}>
                    <Modal.Title>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal2.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal2.text1")}<br />
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal2.text2")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outile-secondary" onClick={handleClose} sx={{ mr: 1 }}>
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal2.cancelButton")}
                    </Button>
                    <Button variant="outline-primary" onClick={handleCancel}>
                        {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.modal2.confirmButton")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Container>
                <Row style={{ marginTop: "30px" }} className="mb-5">
                    <Col >
                        <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.title")}</h1>
                    </Col>
                </Row>

                {isRemote ? (
                    <Row className="mb-1">
                        <Col className="mb-3 mb-sm-3">
                            <Card style={{ minWidth: '100px', minHeight: '200px' }}>
                                <Card.Header as="h4">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.placeOfWork.title")}</div>
                                        <Button variant="outline-primary" onClick={() => handleModify("selectedButtonOnsiteRemote")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.placeOfWork.button")}</Button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="flex-grow-1 pr-3 border-right d-flex align-items-center justify-content-center">
                                    <h5>{selectedButtonOnsiteRemote}</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="mb-3 mb-sm-3">
                            <Card style={{ minWidth: '100px', minHeight: '200px' }}>
                                <Card.Header as="h4">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.organisationType.title")}</div>
                                        <Button variant="outline-primary" onClick={() => handleModify("selectedButtonProfile")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.organisationType.button")}</Button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="flex-grow-1 pr-3 border-right d-flex align-items-center justify-content-center">
                                    <h5>{selectedButtonProfile}</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mb-1">
                        <Col className="mb-3 mb-sm-3">
                            <Card style={{ minWidth: '100px', minHeight: '200px' }}>
                                <Card.Header as="h4">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.placeOfWork.title")}</div>
                                            <Button variant="outline-primary" onClick={() => handleModify("selectedButtonOnsiteRemote")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.placeOfWork.button")}</Button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="flex-grow-1 pr-3 border-right d-flex align-items-center justify-content-center">
                                    <h5>{selectedButtonOnsiteRemote}</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="mb-3 mb-sm-3">
                            <Card style={{ minWidth: '100px', minHeight: '200px' }}>
                                <Card.Header as="h4">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.organisationType.title")}</div>
                                            <Button variant="outline-primary" onClick={() => handleModify("selectedButtonProfile")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.organisationType.button")}</Button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="flex-grow-1 pr-3 border-right d-flex align-items-center justify-content-center">
                                    <h5>{selectedButtonProfile}</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="mb-3 mb-sm-3">
                            <Card style={{ minWidth: '100px', minHeight: '200px' }}>
                                <Card.Header as="h4">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.title")}</div>
                                            <Button variant="outline-primary" onClick={() => handleModify("inputBusinessAddress")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.button")}</Button>
                                    </div>
                                </Card.Header>
                                    <Card.Body className="text-start">
                                    <div className="flex-grow-1 pl-3">
                                        <p>
                                            {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.country")} <b>{inputBusinessAddress.country.value}</b>
                                            <br />
                                                {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.zip")} <b>{inputBusinessAddress.zip}</b>
                                            <br />
                                                {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.city")} <b>{inputBusinessAddress.city.value}</b>
                                            <br />
                                                {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.state")} <b>{inputBusinessAddress.state.value}</b>
                                            <br />
                                                {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.address1")} <b>{inputBusinessAddress.address1}</b>
                                            <br />
                                                {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.businessAddress.address2")} <b>{inputBusinessAddress.address2}</b>
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}



                <Row className="mb-1">
                    <Col className="mb-3 mb-sm-3">
                        <Card className="text-start" style={{ minWidth: '100px', minHeight: '100px' }}>
                            <Card.Header as="h4">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.industries.title")}</div>
                                    <Button variant="outline-primary"
                                        onClick={() => handleModify("selectedIndustries")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.industries.button")}</Button>
                                </div>
                            </Card.Header>
                            <Card.Body className="flex-grow-1 pr-3 border-right d-flex text-start justify-content-center">
                                <h5>
                                    {selectedIndustries.map((industry, index) => (
                                        <React.Fragment key={index}>
                                            {industry.value}
                                            {index !== selectedIndustries.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </h5>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="mb-3 mb-sm-3">
                        <Card style={{ minWidth: '100px', minHeight: '100px' }}>
                            <Card.Header as="h4">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.legalSupport.title")}</div>
                                    <Button variant="outline-primary"
                                        onClick={() => handleModify("selectedButtonTypeLegalSupport")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.legalSupport.button")}</Button>
                                </div>
                            </Card.Header>
                            <Card.Body className="flex-grow-1 pr-3 border-right d-flex align-items-center justify-content-center">
                                <h5>{selectedButtonTypeLegalSupport}</h5>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className="mb-3 mb-sm-3">
                        <Card style={{ minWidth: '100px', minHeight: '100px' }}>
                            <Card.Header as="h4">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.practiceAreas.title")}</div>
                                    <Button variant="outline-primary"
                                        onClick={() => handleModify("selectedPracticeAreas")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.practiceAreas.button")}</Button>
                                </div>
                            </Card.Header>
                            <Card.Body className="flex-grow-1 pr-3 border-right d-flex align-items-center justify-content-center">
                                <h5>
                                    {selectedPracticeAreas.map((pArea, index) => (
                                        <React.Fragment key={index}>
                                            {pArea.value}
                                            {index !== selectedPracticeAreas.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </h5>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-1">
                    <Col className="mb-3 mb-sm-3">
                        <Card style={{ minWidth: '300px', minHeight: '150px' }}>
                            <Card.Header as="h4">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.experienceBudget.title")}</div>
                                    <Button variant="outline-primary"
                                        onClick={() => handleModify("selectedExperienceBudget")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.experienceBudget.button")}</Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="text-start mb-2">
                                    {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.experienceBudget.experience")} <b>{experienceDisplay}</b>
                                </div>
                                <div className="text-start mb-2">
                                    {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.experienceBudget.budget")} <b>{budgetDisplay}</b>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="mb-3 mb-sm-3">
                        <Card style={{ minWidth: '300px', minHeight: '150px' }}>
                            <Card.Header as="h4">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.timeframe.title")}</div>
                                    <Button variant="outline-primary"
                                        onClick={() => handleModify("selectedTimeFrame")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.timeframe.button")}</Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="text-start mb-2">
                                    {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.timeframe.workStart")} <b>{workStartDisplay}</b>
                                </div>
                                <div className="text-start mb-2">
                                    {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.timeframe.workDuration")} <b>{workDurationDisplay}</b>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-1">
                    <Col className="mb-3 mb-sm-3">
                        <Card style={{ minWidth: '100px', minHeight: '100px' }}>
                            <Card.Header as="h4">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.billingInfo.title")}</div>
                                    <Button variant="outline-primary"
                                        onClick={() => handleModify("inputAdditionalInfo")}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.billingInfo.button")}</Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="text-start mb-2">
                                    {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.billingInfo.billingEmail")} <b>{billingEmailAddress}</b>
                                </div>
                                <div className="text-start mb-2">
                                    {t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.formOverview.sections.billingInfo.additionalInfo")} <b>{inputAdditionalInfo}</b>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col>
                        <Button variant="outline-secondary" size="lg" onClick={handleShow}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.cancelButton")}</Button>
                    </Col>
                    <Col>
                        <Button variant="outline-primary" size="lg" onClick={handleConfirm}>{t("pages.findALawyerLoggedIn.findALawyerSearch.selectionRecap.confirmButton")}</Button>
                    </Col>
                </Row>
            </Container>

        </>

    );
};

export default MySelectionRecap;