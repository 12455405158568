import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AppFirebase from '../../App.js';
import { getFirestore, doc, getDoc} from "firebase/firestore";
import { useTranslation } from 'react-i18next';

function MyQuestionIndustries({ handleSelectedIndustries, savedOptions }) {
    const { t } = useTranslation();

    const animatedComponents = makeAnimated();

    const [options, setOptions] = useState([]);

    const db = getFirestore(AppFirebase);

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);
            setOptions(docSnap.data().optionsIndustries);
        };

        fetchData();
    }, []);

    const [selectedOptions, setSelectedOptions] = React.useState(savedOptions);

    const handleChange = (selected) => {
        setSelectedOptions(selected);
        handleSelectedIndustries(selected)
    };

    useEffect(() => {
        handleSelectedIndustries(selectedOptions);
    }, [selectedOptions]);
    return (

        <Container style={{ marginBottom: '100px' }}>
            <Row className="mb-5">
                <Col>
                    <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.questionIndustries.title")} <span style={{ color: 'red' }}>*</span></h1>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                </Col>
                <Col sm={10}>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        onChange={handleChange}
                        defaultValue={selectedOptions}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                minHeight: '75px',
                            })
                        }}
                    />
                </Col>
                <Col></Col>
            </Row>
        </Container>

    );
};

export default MyQuestionIndustries;