import React, { useContext } from 'react';
import styled from 'styled-components';
import { Col, Row, Image, Container, Spinner } from 'react-bootstrap';
import AdminMenu from './AdminMenu';

import { useNavigate } from "react-router-dom";

import { UserContext } from './../App';

const StyledCard = styled.div`
  width: 200px;
  height: 100px;
  margin: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

function AdminWelcome() {
    const user = useContext(UserContext);
    const navigate = useNavigate();

    if (!user) {
        // If user is not available yet, show a loading spinner
        return (
            <Container className="text-center mt-5">
                <Spinner animation="border" variant="primary" />
                <p>Loading...</p>
            </Container>
        );
    }

    const navigateToAdminPage = () => {
        navigate("/Admin");

    };

    const navigateToAdminOperations = () => {
        navigate('/AdminOperations');
    };

    const navigateToAdminInvoiceSender = () => {
        navigate('/AdminPageInvoice');
    };

    return (
        <Container>
            <Row style={{ marginBottom: '80px', marginTop: '40px' }}>
                <Col md={1}>
                    <AdminMenu/>
                </Col>
                <Col>
                <h1>Admin Center</h1>
                    <h3>Welcome!</h3>
                </Col>
            </Row>
            <hr/>
            <Row style={{ marginBottom: '100px', marginTop: '150px' }}>
                <Col className="d-flex justify-content-center">
                    <StyledCard onClick={navigateToAdminPage}>
                        <div>
                            <Image
                                roundedCircle
  
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FIcons%2FprovideAService_admin.png?alt=media&token=b232a379-ca79-40cb-ad4e-1c48ec7e5c6f"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            <h3>PaS Manager</h3>
                        </div>
                    </StyledCard>
                </Col>
                <Col className="d-flex justify-content-center">
                    <StyledCard onClick={navigateToAdminOperations}>
                        <div>
                            <Image
                                roundedCircle
                  
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FIcons%2FfindALawyer_admin.png?alt=media&token=11361bb2-5cd5-4d2c-9033-1c5927f83fbf"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            <h3>FaL Manager</h3>
                        </div>
                    </StyledCard>
                </Col>
                <Col className="d-flex justify-content-center">
                    <StyledCard onClick={navigateToAdminInvoiceSender}>
                        <div>
                            <Image
                                roundedCircle
                           
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FIcons%2Finvoice_admin.png?alt=media&token=33abae59-a5ff-457d-abfb-8b47c7617ac0"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            <h3>Invoice Sender</h3>
                        </div>
                    </StyledCard>
                </Col>
            </Row>
        </Container>
    );
}

export default AdminWelcome;
