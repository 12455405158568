import React, { useState } from 'react';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import i18n from './i18n/index.js'

const languages = [
    { code: 'en', name: 'English', letter: 'En'}, // Emoji flag for English
    { code: 'ar', name: 'العربية', letter: 'عر'} // Emoji flag for Arabic
    // Add more languages as needed
];



const LanguageSelection = () => {
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (code) => {
        i18n.changeLanguage(code);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setLanguage(code);
        localStorage.setItem("lang", code);
    };


    return (
        <SpeedDial
            ariaLabel="SpeedDial language selector"
            sx={{
                position: 'fixed',
                bottom: 16,
                left: 16,
                '& .MuiSpeedDial-fab': {
                    backgroundColor: '#35cce6',
                },
            }}
            icon={<SpeedDialIcon icon={<TranslateIcon />}/>}
        >
            {languages.map((lang) => (
                <SpeedDialAction
                    key={lang.code}
                    icon={lang.letter}
                    tooltipTitle={lang.name}
                    onClick={() => handleLanguageChange(lang.code)}
                />
            ))}
        </SpeedDial>
    );
};

export default LanguageSelection;
