import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import Select from 'react-select';
import AppFirebase from '../../App.js';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

function MyQuestionExperienceBudget({ handleSelectedExperienceBudget, savedInput }) {

    const { t } = useTranslation();

    const [optionsYearsExp, setOptionsYearsExp] = useState([]);
    const [optionsBudget, setOptionsBudget] = useState([]);

    const db = getFirestore(AppFirebase);

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);

            setOptionsYearsExp(docSnap.data().optionsYearsExp);
            setOptionsBudget(docSnap.data().optionsBudget);

        };

        fetchData();
    }, []);


    const [selectedOption, setSelectedOption] = useState(savedInput)
    

    const handleChange = (input, field) => {

        if (field === "budget") {
            const tempObject = { ...selectedOption };
            tempObject.budget = input;
            setSelectedOption(tempObject);
        } else if (field === "experience") {
            const tempObject = { ...selectedOption };
            tempObject.experience = input;
            setSelectedOption(tempObject);
        } else {
            //Nothing
        }

        handleSelectedExperienceBudget(selectedOption);
    };

    useEffect(() => {
        handleSelectedExperienceBudget(selectedOption); 
    }, [selectedOption]);


    return (
        <Container>
            <Row className="mb-5">
                <Col>
                    <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.questionExperienceBudget.title")} <span style={{ color: 'red' }}>*</span></h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col></Col>
                <Col sm={10}>
                <Stack gap={1}>
                        <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionExperienceBudget.experience")} <span style={{ color: 'red' }}>*</span></h5>
                    <div className="flex-grow-1">
                        <Select
                            defaultValue={selectedOption.experience}
                            onChange={(input) => handleChange(input, 'experience')}
                            options={optionsYearsExp}
                        />
                    </div>
                    </Stack>
                </Col>
                <Col></Col>
            </Row>
            <Row className="mb-3">
                <Col></Col>
                <Col sm={10}>
                <Stack gap={1}>
                        <h5 className="text-start">{t("pages.findALawyerLoggedIn.findALawyerSearch.questionExperienceBudget.budget")} <span style={{ color: 'red' }}>*</span></h5>
                    <div className="flex-grow-1">
                        <Select
                                defaultValue={selectedOption.budget}
                                onChange={(input) => handleChange(input, 'budget')}
                            options={optionsBudget}
                        />
                    </div>
                </Stack>
            </Col>
            <Col></Col>
            </Row>
        </Container>
    );
};

export default MyQuestionExperienceBudget;