import * as React from 'react';
import { Box, Card, Stepper, Step, StepLabel, Typography} from '@mui/material';
import { Button,} from "react-bootstrap";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'





function MyProcessSteps() {

    const { t } = useTranslation();

    const steps = [t("pages.findALawyer.processSteps.steps.step1"), t("pages.findALawyer.processSteps.steps.step2"), t("pages.findALawyer.processSteps.steps.step3"), t("pages.findALawyer.processSteps.steps.step4")]; // Added 'Result' step
    const stepsContent = [
        t("pages.findALawyer.processSteps.stepsContent.step1"),
        t("pages.findALawyer.processSteps.stepsContent.step2"),
        t("pages.findALawyer.processSteps.stepsContent.step3"),
        t("pages.findALawyer.processSteps.stepsContent.step4"),
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    //Checks if the step is optional or nor
    const isStepOptional = (step) => {
        return step === 1;
    };

    //Checks if step is contained within the set() skipped
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    //To back
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //To next
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        setSkipped(new Set());
    };

    return (
        <>

            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '16px',
                background: 'linear-gradient(to bottom, #E0EAFC, #FFFFFF)',
                boxShadow: 'none',
            }}>
                <div className="mt-5 mb-5 justify-content-center align-items-center d-flex">
                    <Stepper activeStep={activeStep} alternativeLabel style={{ width: '90%' }} >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption"></Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps} StepIconProps={{ style: { fontSize: '30px' } }}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div>


                    <Box sx={{ minHeight: '200px' }} className="d-flex ms-5 me-5 mb-3 ">

                        <React.Fragment>
                            <Typography sx={{ height: '100%', mt: 2, mb: 1, fontSize: '18px', textAlign: 'justify', whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{ __html: stepsContent[activeStep].replace(/\n/g, '<br />') }}
                            />
                        </React.Fragment>

                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="ms-5 mb-5 me-5" >
                        <Button
                            variant="transparent"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            <FaArrowLeft color={activeStep === 0 ? 'grey' : 'blue'} style={{ fontSize: '24px' }} />
                        </Button>
                        <Button
                            variant="transparent"
                            onClick={activeStep === steps.length - 1 ? handleReset : handleNext}
                        >
                            <FaArrowRight color="blue" style={{ fontSize: '24px' }} />
                        </Button>
                    </div>
                </div>
            </Card>
        </>

    );
}
export default MyProcessSteps;
