import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';



function MyConfirmationApplication() {

    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            navigate('/');
            event.preventDefault();
       
        };

        const handlePopState = () => {
            const confirmLeave = true
            if (confirmLeave) {
                window.location.reload();
              
            } else {
                // Stay on the current page
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);
    return (

     
        <Container style={{marginTop:"100px"} }>
            <h1>{t("pages.findALawyerLoggedIn.findALawyerSearch.confirmationApplication.title")}</h1>

            <p>{t("pages.findALawyerLoggedIn.findALawyerSearch.confirmationApplication.text1")}</p>

            <p>{t("pages.findALawyerLoggedIn.findALawyerSearch.confirmationApplication.text2")}</p>

            <Link to="/"><Button size='lg' variant="outline-primary">{t("pages.findALawyerLoggedIn.findALawyerSearch.confirmationApplication.backButton")}</Button></Link> 
        </Container>
    );
}

export default MyConfirmationApplication;
