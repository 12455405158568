import React, { useContext, useState, useEffect } from 'react';
import { Container, Col, Row, Button, Stack, Form, Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { collection, addDoc, getFirestore, query, where, getDocs, getDoc, doc } from "firebase/firestore";
import AppFirebase from '../App.js';
import AdminMenu from './AdminMenu.js';
import { onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Spinner from 'react-bootstrap/Spinner';

// Dependencies for callable functions.

const functions = getFunctions();
const generateInvoiceFunction = httpsCallable(functions, 'generateInvoice');


function AdminPageInvoice() {

    const db = getFirestore(AppFirebase);

    const [selectedBillingAddress, setSelectedBillingAddress] = useState("");
    const [billingAddresses, setBillingAddresses] = useState([]);

    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");

    const [mailResponse, setMailResponse] = useState("");

    //let mailResponse = "";

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalError, setIsModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isFirstTimeSubmission, setIsFirstTimeSubmission] = useState(true);

    const handleCloseError = () => {
        setIsModalError(false);
    }
    const handleOpenError = (error) => {
        setIsModalError(true);
    }


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    // Function to open the modal
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };




    const handleBillingAddressChange = async (selectedOption) => {

        // Update the selectedBillingAddress state
        setSelectedBillingAddress(selectedOption);
        //console.log(selectedBillingAddress);
        //console.log(selectedOption);
        // Call the fetchUserDetails function when a new element is selected
        let test = coloringErrors();

        await fetchUserDetails(selectedOption);
    };

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [isEmptyBillingAddress, setIsEmptyBillingAddress] = useState(false);
    const [isEmptyCurrency, setIsEmptyCurrency] = useState(false);
    const [isEmptyInvoiceDate, setIsEmptyInvoiceDate] = useState(false);
    const [isEmptyDueDate, setIsEmptyDueDate] = useState(false);
    const [isEmptyItems, setIsEmptyItems] = useState(false);
    const customStylesBilling = {
        control: (provided) => ({
            ...provided,
            borderColor: isEmptyBillingAddress ? 'red' : 'white',
        }),
    };

    const customStylesCurrency = {
        control: (provided) => ({
            ...provided,
            borderColor: isEmptyCurrency ? 'red' : 'white',
        }),
    }

    const customStylesInvoiceDate = {
        borderColor: isEmptyInvoiceDate ? 'red' : 'white',
    }

    const customStylesDueDate = {
        borderColor: isEmptyDueDate ? 'red' : 'white',
    }

    const handleConfirmSendInvoice = () => {
        setShowConfirmationModal(false);
        sendInvoice(); // Call your sendInvoice function here
    };

    const customStylesLineItems = {
        borderColor: isEmptyItems ? 'red' : 'white',
    }

    const handleCancelSendInvoice = () => {
        setShowConfirmationModal(false);
        // Optionally, you can perform some action or reset state when canceling
    };

    useEffect(() => {

        let test = coloringErrors();

    }, [isFirstTimeSubmission]);
    const coloringErrors = () => {
        let isFilled = false;
        if (isFirstTimeSubmission === false) {
            isFilled = true;
            if (selectedBillingAddress === "") {
                setIsEmptyBillingAddress(true);
                isFilled = false;
            } else {
                setIsEmptyBillingAddress(false);
            }
            if (currency === "") {
                setIsEmptyCurrency(true);
                isFilled = false;
            } else {
                setIsEmptyCurrency(false);
            }
            if (invoiceDate === "") {
                setIsEmptyInvoiceDate(true);
                isFilled = false;
            } else {
                setIsEmptyInvoiceDate(false);
            }
            if (dueDate === "") {
                setIsEmptyDueDate(true);
                isFilled = false;
            } else {
                setIsEmptyDueDate(false);
            }

            if (lineItems[0].description === '' || lineItems[0].amount === 0) {

                setIsEmptyItems(true);
                isFilled = false;
            } else {
                setIsEmptyItems(false);
            }
        }
        return isFilled;
    }

    const handleSendInvoice = () => {
        let isFilled = coloringErrors();

        setIsFirstTimeSubmission(false);
        if (isFilled === true) {
            setShowConfirmationModal(true);
        }

    }


    useEffect(() => {
        // Fetch billing addresses from the "user" collection in Firestore
        const fetchBillingAddresses = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "userInfo"));
                const newBillingAddresses = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data().emailAddress && doc.data().emailAddress !== "") {
                        if (doc.data().billingEmailAddress && doc.data().billingEmailAddress !== "") {
                            const tempElement = { label: doc.data().billingEmailAddress, value: doc.id };
                            newBillingAddresses.push(tempElement);

                        } else {
                            const tempElement = { label: doc.data().emailAddress, value: doc.id };
                            newBillingAddresses.push(tempElement);
                        }
                       
                        //console.log(doc.id);
                    }
                });

                setBillingAddresses(newBillingAddresses);
                //console.log(newBillingAddresses);
            } catch (error) {
                console.error('Error fetching billing addresses:', error);
            }
        };

        fetchBillingAddresses();
    }, [db]); // Run this effect only once on component mount


    const fetchUserDetails = async (selectedBillingAddress) => {
        try {
            if (selectedBillingAddress) {
                // Use the selectedBillingAddress.value to fetch user details
                const docRef = doc(db, "userInfo", selectedBillingAddress.value);
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists()) {
                    let userData = docSnapshot.data();
                    setCountry(userData.country);
                    setState(userData.state);
                    setCity(userData.city);
                    setZip(userData.zip);
                    setAddress1(userData.addressLine1);
                    setAddress2(userData.addressLine2);
                    setEmailAddress(userData.emailAddress);
                    setPhoneNumber(userData.phoneNumber);
                    if (userData.profileLawyer === "Law Firm") {
                        setUserName(userData.lawFirmName)
                    } else {
                        setUserName(userData.firstName + " " + userData.lastName)
                    }
                    //console.log(userData.country)
                }
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const [invoiceDate, setInvoiceDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [currency, setCurrency] = useState("");
    const [lineItems, setLineItems] = useState([
        { description: '', amount: 0 },
    ]);


    // Function to reset the selected value
    const handleReset = () => {
        setSelectedBillingAddress("");
        setCountry("");
        setState("");
        setCity("");
        setZip("");
        setAddress1("");
        setAddress2("");
        setEmailAddress("");
        setPhoneNumber("");
        setUserName("");

        setCurrency("");
        setInvoiceDate("");
        setDueDate("");
        setCurrency("");
        setLineItems([
            { description: '', amount: 0 },
        ]);

        //mailResponse = "";
    };

    useEffect(() => {

    }, [mailResponse])

    const [optionsCurrency, setOptionCurrency] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const docRef = doc(db, "staticData", "staticDataV1");
            const docSnap = await getDoc(docRef);

            setOptionCurrency(docSnap.data().currency);


        };

        fetchData();
    }, []);

    useEffect(() => {
        coloringErrors();
    }, [isEmptyBillingAddress, selectedBillingAddress]);

    useEffect(() => {
        coloringErrors();
    }, [isEmptyCurrency, currency]);

    useEffect(() => {
        coloringErrors();
    }, [isEmptyInvoiceDate, invoiceDate]);

    useEffect(() => {
        coloringErrors();
    }, [isEmptyDueDate, dueDate]);

    useEffect(() => {
        coloringErrors();
    }, [isEmptyItems, lineItems]);



    const addLineItem = () => {
        setLineItems([...lineItems, { description: '', amount: 0 }]);
    };

    const handleLineItemChange = (index, field, value) => {
        const updatedLineItems = [...lineItems];
        updatedLineItems[index][field] = value;
        setLineItems(updatedLineItems);
    };

    const removeLastLineItem = () => {
        if (lineItems.length > 1) {
            const updatedLineItems = [...lineItems];
            updatedLineItems.pop(); // Remove the last item
            setLineItems(updatedLineItems);
        }
    };

    const calculateTotalAmount = () => {
        const totalAmount = lineItems.reduce((total, item) => {
            let value = item.amount;
            const floatValue = parseFloat(String(value).replace(',', '.'));

            return total + parseFloat(floatValue) || 0;
        }, 0);
        const amount = isNaN(totalAmount) ? 0 : totalAmount;
        const formattedTotal = amount.toFixed(2).replace('.', ',');
        return formattedTotal;
    };



    const sendInvoice = async () => {
        try {
            setMailResponse("");

            handleOpenModal();


            const requestData = {
                userName: userName,
                selectedBillingAddress: selectedBillingAddress.label,
                city: city,
                country: country,
                address1: address1,
                address2: address2,
                zip: zip,
                state: state,
                phoneNumber: phoneNumber,
                dueDate: dueDate, // Replace with your due date
                invoiceDate: invoiceDate,
                currency: currency.value, // Replace with your currency
                lineItems: lineItems,
            };


            generateInvoiceFunction(requestData)
                .then(async (result) => {
                    const infoData = result.data;
                    //setMailResponse(infoData.mailResp);
                    setMailResponse(infoData.mailResp);
                    //console.log(infoData);

                    if (infoData.mailResp.delivery.state === "SUCCESS") {
                        handleReset();
                        setIsFirstTimeSubmission(true);
                    }

                    if (infoData.mailResp.delivery.state === "ERROR") {
                        console.log("Error")
                        setIsFirstTimeSubmission(true);
                    }

                })
                .catch((error) => {

                    const code = error.code;
                    const message = error.message;
                    const details = error.details;

                });

        } catch (error) {
            //console.error("Error sending invoice:", error);
            handleOpenError(error);
        }
    };

    const getAmountPlaceholder = (currency) => {
        switch (currency) {
            case 'usd':
                return "0,50 USD";
            case 'aed':
                return '2,00 AED';
            case 'qar':
                return '2,00 QAR';
            case 'sar':
                return '2,00 SAR';
            case 'kwd':
                return '0,20 KWD';
            case 'jod':
                return "0,40 JOD";
            case 'bhd':
                return '0,20 BHD';
            case 'omr':
                return '0,30 OMR';

            default:
                return "Amount";
        }
    };

    //Min accepted value is 0.5 USD
    const getMinValue = (currency) => {
        switch (currency) {
            case 'usd':
                return 0.50;
            case 'aed':
                return 2.00;
            case 'qar':
                return 2.00;
            case 'sar':
                return 2.00;
            case 'kwd':
                return 0.20;
            case 'jod':
                return 0.40;
            case 'bhd':
                return 0.20;
            case 'omr':
                return 0.30;
        }
    };

    const transformAmountToSmallestUnit = (amount, currency) => {
        switch (currency) {
            case 'usd':
                // Assuming USD uses cents as the smallest unit
                return parseFloat(amount) * 100;
            case 'aed':
                // Assuming AED uses fils as the smallest unit
                return parseFloat(amount) * 100;
            case 'qar':
                // Assuming QAR uses dirhams as the smallest unit
                return parseFloat(amount) * 100;
            case 'sar':
                // Assuming SAR uses halalas as the smallest unit
                return parseFloat(amount) * 100;
            case 'kwd':
                // Assuming KWD uses fils as the smallest unit
                return parseFloat(amount) * 100;
            case 'jod':
                // Assuming JOD uses fils as the smallest unit
                return parseFloat(amount) * 100;
            case 'bhd':
                // Assuming BHD uses fils as the smallest unit
                return parseFloat(amount) * 100;
            case 'omr':
                // Assuming OMR uses baisas as the smallest unit
                return parseFloat(amount) * 1000;
            default:
                // If the currency is not recognized, return the original amount
                return amount;
        }
    };

    const validateNumericInput = (input) => {
        // Use a regular expression to allow only numbers and a decimal point
        const regex = /^[0-9]+(?:,[0-9]{0,2})?$/;

        // Check if the input matches the allowed pattern
        if (regex.test(input)) {
            return input;
        } else {
            // If not, remove non-numeric characters and excess commas
            const cleanedInput = input.replace(/[^0-9,]/g, '');

            // Remove excess commas
            const commaCount = cleanedInput.split(',').length - 1;
            if (commaCount > 1) {
                const lastCommaIndex = cleanedInput.lastIndexOf(',');
                return cleanedInput.substring(0, lastCommaIndex) + cleanedInput.substring(lastCommaIndex + 1);
            }

            // Remove leading comma
            if (cleanedInput.startsWith(',')) {
                return cleanedInput.substring(1);
            }

            // Limit to two digits after the comma
            const [integerPart, decimalPart] = cleanedInput.split(',');
            const limitedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : '';
            return `${integerPart || ''}${limitedDecimalPart ? `,${limitedDecimalPart}` : ''}`;
        }
    };


    useEffect(() => {
        renderModalContent();
        //console.log(mailResponse)

    }, [mailResponse])


    const renderModalContent = (mailResponse) => {
        //console.log("This is the mail reps : ", mailResponse)

        if (mailResponse && mailResponse.delivery) {

            if (mailResponse.delivery.state === "SUCCESS") {
                return (
                    <Row>
                        <Col md={4} className="d-flex justify-content-center align-items-center">
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FIcons%2Fsuccess_icon.png?alt=media&token=7ad311d5-9aed-433a-995e-1d67ec4bacd7"
                                alt="Image Alt Text"
                                className="img-fluid"
                                style={{ width: '80px', height: '80px' }}
                            />
                        </Col>
                        <Col md={8} className="d-flex align-items-center">
                            <p style={{ fontSize: '20px' }}>Email Sent Successfully</p>
                        </Col>
                    </Row>
                );
            } else if (mailResponse.delivery.state === "ERROR") {
                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <div>Oops :/ An error has occurred, and the invoice was not delivered</div>
                    </div>
                );
            } else {
                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                );
            }

        } else {
            return (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            );
        }

    };




    return (
        <>
            <Modal show={isModalError} onHide={handleCloseError} centered>
                <Modal.Header style={{ background: '#ff6347', color: 'aliceblue' }} closeButton>
                    <Modal.Title>
                        <Row>
                            <Col md={4} className="d-flex justify-content-center align-items-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FIcons%2Finformation_icon.png?alt=media&token=c0c6ce0e-a8c8-44e1-8271-0488dd2ad022"
                                    alt="Image Alt Text"
                                    className="img-fluid"
                                    style={{ width: '40px', height: '40px' }} />
                            </Col>
                            <Col md={8}>
                                Information
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4} className="d-flex justify-content-center align-items-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FIcons%2FError_icon.png?alt=media&token=f3f3d565-94fd-4575-bd30-f11cfe251fc4"
                                alt="Image Alt Text"
                                className="img-fluid"
                                style={{ width: '80px', height: '80px' }} />
                        </Col>
                        <Col md={8} className="d-flex align-items-center" >
                            <p style={{ fontSize: '20px' }}>Oops, it looks like an error has occured !
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="outline-primary" onClick={handleCloseError}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isModalOpen} onHide={handleCloseModal} centered>
                <Modal.Header style={{ background: '#0d78ae', color: 'aliceblue' }} closeButton>
                    <Modal.Title>
                        <Row>
                            <Col md={4} className="d-flex justify-content-center align-items-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/lexpertswebsite.appspot.com/o/images%2FIcons%2Finformation_icon.png?alt=media&token=c0c6ce0e-a8c8-44e1-8271-0488dd2ad022"
                                    alt="Image Alt Text"
                                    className="img-fluid"
                                    style={{ width: '40px', height: '40px' }} />
                            </Col>
                            <Col md={8}>
                                Information
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <>{renderModalContent(mailResponse)}</>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="outline-primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            < Modal show={showConfirmationModal} onHide={handleCancelSendInvoice} >
                <Modal.Header style={{ background: '#0d78ae',color: 'aliceblue'  }}  closeButton>
                    <Modal.Title>Confirm Send Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to send the invoice to {selectedBillingAddress.label}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleCancelSendInvoice}>
                        Cancel
                    </Button>
                    <Button variant="outline-primary" onClick={handleConfirmSendInvoice}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal >

            <Container>

                <Row style={{ marginTop: '50px' }}>
                </Row>


                <Row className="mt-5 mb-5">
                    <Col md={1}>
                        <AdminMenu />
                    </Col>
                    <h1 style={{ fontSize: '30px' }}>
                        Invoice Sender
                    </h1>
                </Row>
                <Row className="mt-5 mb-5 text-start">
                    <p style={{ fontSize: '24px', color: 'grey' }}>Email an invoice by searching a billing address. If not found, please ensure it has been added in the user's profile.</p>
                    <Col className="mb-2 mt-2" md={10}>
                        <Select

                            value={selectedBillingAddress}
                            onChange={handleBillingAddressChange}
                            options={billingAddresses}
                            styles={customStylesBilling}
                        />
                    </Col>
                    <Col className="border-start border-2 border-gray mb-2 mt-2" md={2}>
                        <Button variant="outline-danger" className="h-100 w-100" onClick={handleReset}>
                            Reset
                        </Button>
                    </Col>
                </Row>
                <Container className="mt-5 mb-5">
                    <Row className="text-start mt-5">
                        <p style={{ fontSize: '24px', color: 'black' }} >You are sending this to:</p>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4} className="mb-2 mt-2">
                            <Stack>
                                <Form.Label>
                                    <p style={{ fontSize: '16px' }}>User Name : </p>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={userName}
                                    disabled
                                />
                            </Stack>
                        </Col>
                        <Col md={4} className="mb-2 mt-2">
                            <Stack>
                                <Form.Label><p style={{ fontSize: '16px' }}>Phone Number : </p></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={phoneNumber}
                                    disabled
                                />
                            </Stack>
                        </Col>
                        <Col md={4} className="mb-4 mt-2">
                            <Stack>
                                <Form.Label><p style={{ fontSize: '16px' }}>Email Address :</p></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={emailAddress}
                                    disabled
                                />
                            </Stack>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={3} className="mb-2">
                            <Stack>
                                <Form.Label><p style={{ fontSize: '16px' }}>Country : </p></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={country}
                                    disabled
                                />
                            </Stack>
                        </Col>
                        <Col md={3} className="mb-2 mt-2">
                            <Stack>
                                <Form.Label><p style={{ fontSize: '16px' }}>State : </p></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={state}
                                    disabled
                                />
                            </Stack>
                        </Col>
                        <Col md={3} className="mb-2 mt-2">
                            <Stack>
                                <Form.Label><p style={{ fontSize: '16px' }}>City : </p></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={city}
                                    disabled
                                />
                            </Stack>
                        </Col>
                        <Col md={3} className="mb-2 mt-2">
                            <Form.Label ><p style={{ fontSize: '16px' }}>Zip : </p></Form.Label>
                            <Form.Control
                                type="text"
                                value={zip}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-3">
                        <Col md={6} className="mb-2 mt-2">
                            <Stack>
                                <Form.Label><p style={{ fontSize: '16px' }}>Address Line 1 : </p></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address1}
                                    disabled
                                />
                            </Stack>
                        </Col>
                        <Col md={6} className="mb-2 mt-2">
                            <Stack>
                                <Form.Label><p style={{ fontSize: '16px' }}>Address Line 2 : </p></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address2}
                                    disabled
                                />
                            </Stack>
                        </Col>
                    </Row>
                </Container>
                <hr />
                <Container className="mt-5 mb-5">
                    <Row className="text-start mt-5">
                        <p style={{ fontSize: '24px', color: 'grey' }}>
                            Please fill in the following information to create the invoice:
                        </p>
                    </Row>
                    <Form className="mt-3">
                        <Row className="mb-3">
                            <Col>
                                <Form.Label><p style={{ fontSize: '16px' }}>Currency:</p></Form.Label>
                                <Select
                                    options={optionsCurrency}
                                    value={currency} // Set the selected value
                                    onChange={(selectedOption) => {
                                        setCurrency(selectedOption);
                                        coloringErrors();
                                    }} // Update the state on change    
                                    styles={customStylesCurrency}
                                />
                            </Col>
                            <Col>
                                <Form.Label><p style={{ fontSize: '16px' }}>Invoice Date:</p></Form.Label>
                                <Form.Control
                                    type="date"
                                    value={invoiceDate}
                                    onChange={(event) => {
                                        setInvoiceDate(event.target.value);
                                        coloringErrors();
                                    }}
                                    style={customStylesInvoiceDate}
                                // Add necessary props and state for handling the invoice date
                                />

                            </Col>
                            <Col>
                                <Form.Label><p style={{ fontSize: '16px' }}>Due Date:</p></Form.Label>
                                <Form.Control

                                    type="date"
                                    value={dueDate}
                                    onChange={(event) => {
                                        setDueDate(event.target.value);
                                        coloringErrors();
                                    }}
                                    style={customStylesDueDate}
                                />
                            </Col>
                        </Row>

                        {lineItems.map((item, index) => (
                            <Row key={index} className="mb-3">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Product name"
                                        value={item.description}
                                        onChange={(e) => handleLineItemChange(index, 'description', e.target.value)}
                                        style={customStylesLineItems}
                                        disabled={currency === ""}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder={getAmountPlaceholder(currency.value)}
                                        value={item.amount}
                                        onChange={(e) => handleLineItemChange(index, 'amount', validateNumericInput(e.target.value))}
                                        style={customStylesLineItems}
                                        disabled={currency === ""}
                                        min={getMinValue(currency.value)}
                                        step="0.01"
                                    />
                                </Col>
                            </Row>
                        ))}

                        <Row className="mb-3">
                            <Col md={4}>
                            </Col>
                            <Col mb={2}>
                                <Button className="w-100" variant="outline-danger" onClick={removeLastLineItem}>
                                    Remove Line Item
                                </Button>
                            </Col>
                            <Col mb={2}>
                                <Button className="w-100" variant="outline-primary" onClick={addLineItem}>
                                    Add Line Item
                                </Button>
                            </Col>
                            <Col md={4}>
                            </Col>
                        </Row>

                        <Row className="mt-3 justify-content-end">
                            <Col xs="auto">
                                <p style={{ fontSize: '16px' }}>Total Amount: {calculateTotalAmount()} {currency.label}</p>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <Row className="mt-5 mb-5">
                    <Col md={3}></Col>
                    <Col md={6}><Button className="w-100" variant="outline-primary" onClick={handleSendInvoice}>Send Invoice</Button></Col>
                    <Col md={3}></Col>
                </Row>
            </Container >
        </>
    );
}

export default AdminPageInvoice;